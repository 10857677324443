/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import { allSettings, allSettingUpdate } from '../services/auth.service';
import { ISetting } from '../types/setting.type';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface SettingPageProps {
    title: string;
}


const SettingPage: FC<SettingPageProps> = ({ title }) => {

    const { t } = useTranslation("translate");

    const [settingLists, setSettingList] = useState<any[]>([]);
    const [message, setMessage] = useState<string>("");
    const [successful, setSuccessful] = useState<boolean>(false);

    const initialValues: ISetting = {
        value: ""
    }
    const settingListsData = async () => {
        const settingView = await allSettings();
        setSettingList(settingView.data.settings.rows);
    }

    useEffect(() => {
        settingListsData();
    }, [])

    const handleSettingUpdate = (settingRef: any, value: any) => {
        // debugger
        allSettingUpdate(settingRef, value).then((response) => {
            setSuccessful(true);
            toast.success("Setting details are updated successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });

            // navigate('/dashboard');

        },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMessage(resMessage);
                setSuccessful(false);
            }
        )

    }
    const handleonChange = (index: any, e: any) => {
        let data = [...settingLists];
        data[index]['value'] = e.target.value
        setSettingList(data)
    }

    return (
        <>
            <main>
                <div className="container-fluid px-lg-4">
                    <h3 className="mt-3 mb-4">{t("dashboardPage.lablSettings")}</h3>
                    <div className="card mb-4 card-border">
                        <div className="container px-lg-4 py-4">
                            <form>
                                {settingLists.map((settingList, index) => {
                                    return (
                                        <div key={index}>

                                            <div className="row">
                                                <div className="col-md-9 mb-2 mb-md-3">
                                                    <div className="form-floating">
                                                        <input className="form-control" name={settingList.key} value={settingList.value} type="text" onChange={(e: any) => { handleonChange(index, e) }} />
                                                        <label className='mb-1'>{settingList.label}</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 d-flex justify-content-left align-items-center mb-3">
                                                    <button className='btn btn-primary' type="button" onClick={() => handleSettingUpdate(settingList.settingRef, settingList.value)}>{t("settingPage.btnClickUpdate")}</button>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </form>
                            <hr></hr><br />
                            <p><strong>{t("settingPage.lblQRCodeInbound")}</strong> {t("settingPage.textQRCodeInbound")} &#123;{t("settingPage.organizationQRCodeInbound")}&#125; - &#123;{t("settingPage.nameQRCodeInbound")}&#125;</p>
                            <p><strong>{t("settingPage.lblMMSTextReply")}</strong>{t("settingPage.textMMSTextReply")} &#123;{t("settingPage.organizationMMSTextReply")}&#125; - &#123;{t("settingPage.nameMMSTextReply")}&#125;</p>
                            <p><strong>{t("settingPage.lblLeadGenerationText")}</strong>{t("settingPage.contactNumberLeadGeneration")} &#123;{t("settingPage.vCardLeadGeneration")}&#125; {t("settingPage.textLeadGeneration")}</p>
                            <p><strong>{t("settingPage.lblAfterMinuteFollowUpText")}</strong>{t("settingPage.textAfterMinuteFollowUp")} &#123;{t("settingPage.organizationAfterMinuteFollowUp")}&#125; - &#123;{t("settingPage.nameAfterMinuteFollowUp")}&#125; {t("settingPage.visitCardAfterMinuteFollowUp")}</p>
                        </div>
                    </div>

                </div>

            </main>

        </>
    )
}

export default SettingPage;