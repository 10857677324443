import { Formik, Form, Field, setIn, ErrorMessage } from "formik";
import React, { FC, useEffect, useState } from "react";
import { currentData, customizeQr, iconLink, saveQrData } from "../services/qr.service";
import { IQr } from "../types/qr.type";
import {
  QR25D,
  QRBubble,
  QRDsj,
  QRFunc,
  QRImage,
  QRLine,
  QRNormal
} from "react-qrbtf";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ImageEditor } from "../components/imageEditor";
import DeleteDialogBox from "../common/DeleteDialogBox";
import { useParams } from "react-router-dom";
interface QRCustomizePageProps {
  title: string;
}
let initialValues: IQr = {
  type: "",
  size: "",
  opacity: "",
  posType: "",
  otherColor: "",
  posColor: "",
  scale: "",
  crossWidth: "",
  height: "",
  posHeight: "",
  topColor: "",
  posWidth: "",
  leftColor: "",
  rightColor: "",
  image: "",
  darkColor: "",
  lightColor: "",
  circleColor: "",
  funcType: "",
  otherColor1: "",
  direction: "",
  lineWidth: "",
  lineOpacity: "",
  lineColor: "",
  note: "",
  logoInMiddle: "",
  icon: "",
  iconUrl: ""
};

const QrCustomizePage: FC<QRCustomizePageProps> = ({ title }) => {
  const [selectDropdown, setSelectDropDown] = useState("");
  const [sizeError, setSizeError] = useState<string | null>(null);
  const [posTypeError, setPosTypeError] = useState<string | null>(null);
  const [opacityError, setOpacityError] = useState<string | null>(null);
  const [scaleError, setScaleError] = useState<string | null>(null);
  const [crossWidthError, setCrossWidthError] = useState<string | null>(null);
  const [heightError, setHeightError] = useState<string | null>(null)
  const [posHeightError, setPosHeightError] = useState<string | null>(null);
  const [lineOpacityError, setLineOpacityError] = useState<string | null>(null)
  const [lineWidthError, setLineWidthError] = useState<string | null>(null);
  const [qrData, setQrData] = useState<any>([]);
  const [initialData, setInitialData] = useState<IQr>(initialValues);
  const [typeDropdown, setTypeDropDown] = useState([]);
  const [posTypeDropDown, setPosTypeDropDown] = useState([]);
  const [funcTypeDropDown, setFuncTypeDropDown] = useState([]);
  const [directionDropDown, setDirectionDropDown] = useState([]);
  const [qrImage, setQrImage] = useState<any>()
  const [updatedData, setUpdatedData] = useState<any>([]);
  const [checked, setChecked] = useState(false);
  const [titleIcon, setTitleIcon] = useState<any>();
  const [showDelete, setShowDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [iconData, setIconData] = useState<any>();
  let param = useParams();
  let { businessRef } = useParams();
  let businessId = businessRef;
  const { t } = useTranslation("translate");

  const getQrData = async () => {
    const qrDataList = await customizeQr();
    setSelectDropDown("QRNormal");
    setQrData(qrDataList.data.data);
  };

  const setSingleUpdatedQr = async (type: any) => {
    try {
      const qrDataList = await currentData(businessId, type);
      const data = qrDataList.data.data;
      setUpdatedData(data);
      setSelectDropDown(data.qrType);
      setInitialData(data?.properties);
      setIconData(data?.properties?.icon);
      setTitleIcon(data?.properties?.iconBase64);
      setChecked(data?.properties?.logoInMiddle);
    } catch (e) {
      console.log(e);
    }
  };

  const setOrgUpdatedQr = async () => {
    const getUpdatedQr: any = await currentData("", "");
    const data = getUpdatedQr.data.data;
    setUpdatedData(data);
    setSelectDropDown(data.qrType);
    setInitialData(data?.properties);
    setIconData(data?.properties?.icon);
    setTitleIcon(data?.properties?.iconBase64);
    setChecked(data?.properties?.logoInMiddle);
    setQrImage(data?.properties?.image);
  }

  useEffect(() => {
    setSelectDropDown(updatedData?.qrType);
    setInitialData(updatedData?.properties);
    setIconData(updatedData?.properties?.icon);
    setTitleIcon(updatedData?.properties?.iconBase64);
    setChecked(updatedData?.properties?.logoInMiddle);
    setQrImage(updatedData?.properties?.image);
  }, [updatedData]);

  const handleSelectChange = (e: any) => {
    let data = e.target.value;
    setSelectDropDown(data);
  };

  const validateFormData = () => {
    const sizeValue = parseInt(initialData.size, 10);
    const opacityValue = parseInt(initialData.opacity, 10);
    const posTypeValue = parseInt(initialData.posWidth, 10);
    const scaleValue = parseInt(initialData.scale, 10);
    const crossWidthValue = parseInt(initialData.crossWidth, 10);
    const heightValue = parseFloat(initialData.height);
    const posHeightValue = parseFloat(initialData.posHeight);
    const lineOpacityValue = parseInt(initialData.lineOpacity, 10);
    const lineWidthValue = parseInt(initialData.lineWidth, 10);

    if (sizeValue < 50 || sizeValue > 100) {
      return t('qrCustomizePage.sizeError');
    }

    if (opacityValue < 50 || opacityValue > 100) {
      return t('qrCustomizePage.opacityError');
    }

    if (posTypeValue < 50 || posTypeValue > 100) {
      return t('qrCustomizePage.posWidthError');
    }

    if (scaleValue < 50 || scaleValue > 100) {
      return t('qrCustomizePage.scaleError');
    }

    if (crossWidthValue < 50 || crossWidthValue > 100) {
      return t('qrCustomizePage.crossWidthError');
    }

    if (heightValue < 0.5 || heightValue > 3) {
      return t('qrCustomizePage.heightError');
    }

    if (posHeightValue < 0.5 || posHeightValue > 3) {
      return t('qrCustomizePage.posHeightError');
    }

    if (lineOpacityValue < 50 || lineOpacityValue > 100) {
      return t('qrCustomizePage.lineOpacityError');
    }

    if (lineWidthValue < 50 || lineWidthValue > 100) {
      return t('qrCustomizePage.lineWidthError');
    }

    return null; // If no validation errors
  };


  const handleSubmit = async () => {

    const validationError = validateFormData();
    if (validationError) {
      toast.error(`${t('qrCustomizePage.validationErrorMessage')} ${validationError}.${t('qrCustomizePage.validationInputError')}`);
      return;
    }

    initialData.image = qrImage;
    initialData.logoInMiddle = checked;
    initialData.icon = iconData;
    initialData.iconUrl = titleIcon;
    function stringify(obj: any) {
      const replacer = [];
      for (const key in obj) { replacer.push(key); }
      return JSON.stringify(obj, replacer);
    }
    const json = stringify(initialData);
    let payload: any;
    if (param.id == "org") {
      payload = {
        qrType: selectDropdown,
        properties: json
      }
    } else {
      payload = {
        businessRfid: businessRef,
        key: param.id,
        selectedQRType: selectDropdown,
        selectedQRProperties: json
      }
    }
    await saveQrData(payload).then(
      (response) => {
        toast.success("Changes saved sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    );
  };

  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > maxFileSize) {
      setErrorMessage('File size exceeds the 5MB limit.');
      return;
    }
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
      try {
        const formData = new FormData();
        formData.append('icon', file);
        const response = await iconLink(formData);
        const iconUrl = response.data.iconUrl;
        setIconData(response.data.icon);
        setTitleIcon(iconUrl);
        setErrorMessage("");
      } catch (error) {
        console.log(error);
      }
    } else {
      setErrorMessage('This file format is not accepted. Please choose an image file (JPG, JPEG, or PNG).');
    }
  };

  useEffect(() => {
    // Do not remove this useEffect it's useful for the icon to be effective on QR code middle.
  }, [titleIcon]);

  const handleChecked = (e: any) => {
    setChecked(e.target.checked);
    if (false == e.target.checked) {
      setIconData("");
      setTitleIcon("");
    }
  };
  const handleInputText = (e: any) => {

    const { name, value } = e.target;

    // Update state with new input value
    setInitialData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate size field
    if (name === 'size') {
      const sizeValue = parseInt(value, 10);
      if (sizeValue < 50 || sizeValue > 100) {
        setSizeError(t('qrCustomizePage.sizeError'));
      } else {
        setSizeError('');
      }
    }

    // Validate opacity field
    if (name === 'opacity') {
      const opacityValue = parseInt(value, 10);
      if (opacityValue < 50 || opacityValue > 100) {
        setOpacityError(t('qrCustomizePage.opacityError'));
      } else {
        setOpacityError('');
      }
    }

    // Validate fields for scale, crossWidth, and posType
    if (name === 'scale' || name === 'crossWidth' || name === 'posWidth') {
      const numericValue = parseInt(value, 10);
      if (numericValue < 50 || numericValue > 100) {
        // Display an error message specific to the field
        if (name === 'scale') {
          setScaleError(t('qrCustomizePage.scaleError'));
        } else if (name === 'crossWidth') {
          setCrossWidthError(t('qrCustomizePage.crossWidthError'));
        } else if (name === 'posWidth') {
          setPosTypeError(t('qrCustomizePage.posWidthError'));
        }
      } else {
        // Clear error message if value is within the valid range
        if (name === 'scale') {
          setScaleError('');
        } else if (name === 'crossWidth') {
          setCrossWidthError('');
        } else if (name === 'posWidth') {
          setPosTypeError('');
        }
      }
    }
    // Validate fields for height and posHeight
    if (name === 'height' || name === 'posHeight') {
      const numericValue = parseFloat(value);

      if (numericValue < 0.5 || numericValue > 3) {
        // Display an error message specific to the field
        if (name === 'height') {
          setHeightError(t('qrCustomizePage.heightError'));
        } else if (name === 'posHeight') {
          setPosHeightError(t('qrCustomizePage.posHeightError'));
        }
      } else {
        // Clear error message if value is within the valid range
        if (name === 'height') {
          setHeightError('');
        } else if (name === 'posHeight') {
          setPosHeightError('');
        }
      }
    }
    // Validate lineOpacity field
    if (name === 'lineOpacity') {
      const opacityValue = parseInt(value, 10);
      if (opacityValue < 50 || opacityValue > 100) {
        setLineOpacityError(t('qrCustomizePage.lineOpacityError'));
      } else {
        setLineOpacityError('');
      }
    }

    // Validate lineWidth field
    if (name === 'lineWidth') {
      const widthValue = parseInt(value, 10);
      if (widthValue < 50 || widthValue > 100) {
        setLineWidthError(t('qrCustomizePage.lineWidthError'));
      } else {
        setLineWidthError('');
      }
    }
  };

  const handleDropDown = (e: any) => {
    const dropDowns = { ...initialData, [e.target.name]: e.target.value };
    setInitialData(dropDowns);
  };

  const handelColor = (e: any) => {
    const colors = { ...initialData, [e.target.name]: e.target.value };
    setInitialData(colors);
  };

  useEffect(() => {
    const getSelectedQrData = qrData.find((data: any, index: any) => {
      return data.types == selectDropdown;
    });
    const temp = getSelectedQrData;
    var updatedValues: any = {};
    const modifyValue = temp?.properties?.map((data: any, index: any) => {
      const key: any = Object.keys(data)[0];
      const value: any = Object.values(data);
      if (key === "type") {
        setTypeDropDown(value[0].options);
      }
      if (key === "posType") {
        setPosTypeDropDown(value[0].options);
      }
      if (key === "funcType") {
        setFuncTypeDropDown(value[0].options);
      }
      if (key === "direction") {
        setDirectionDropDown(value[0].options);
      }
      updatedValues[key] = value[0].default;
    });
    const qrValue = { ...initialValues, ...updatedValues };
    setInitialData(qrValue);
  }, [selectDropdown]);

  useEffect(() => {
    getQrData();
  }, []);

  useEffect(() => {
    if (param.id !== "org") {
      setSingleUpdatedQr(param.id);
    } else {
      setOrgUpdatedQr();
    }
  }, [qrData]);

  function getImageHandler(data: any) {
    if (data?.status != "removed") {
      setQrImage(data?.thumbUrl);
    } else {
      setQrImage(undefined);
    }
  }
  return (
    <>
      <main>
        <div className="container-fluid px-lg-4">
          <h3 className="mt-3 mb-3">{t("dashboardPage.lablQrCustomize")}</h3>
          <div className="row">
            <div className="col-md-12">
              <div className="form-floating">
                <div className="card mb-3 card-border">
                  <div className="container px-lg-4 py-4">
                    <Formik
                      initialValues={initialValues}
                      onSubmit={() => { }}
                    >
                      <Form>
                        <div className="col-md-6 mb-4">
                          <select
                            className="form-select py-3"
                            aria-label="Default select example"
                            name="isIndividual"
                            value={selectDropdown}
                            onChange={handleSelectChange}
                          >
                            <>
                              <option value="QRNormal">
                                {t("qrCustomizePage.lblQrNormal")}
                              </option>
                              <option value="QRDsj">
                                {t("qrCustomizePage.lblQrDsj")}
                              </option>
                              <option value="QR25D">
                                {t("qrCustomizePage.lblQr25D")}
                              </option>
                              <option value="QRImage">
                                {t("qrCustomizePage.lblQrImage")}
                              </option>
                              <option value="QRBubble">
                                {t("qrCustomizePage.lblQrBubble")}
                              </option>
                              <option value="QRFunc">
                                {t("qrCustomizePage.lblQrFunc")}
                              </option>
                              <option value="QRLine">
                                {t("qrCustomizePage.lblQrLine")}
                              </option>
                            </>
                          </select>

                        </div>
                        <div className="d-flex row">
                          <div>
                            {selectDropdown === "QRNormal" ? (
                              <>
                                <div className="row">
                                  <div className="col-md-6" style={{ paddingRight: 0 }}>
                                    <div className="row ">
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblType")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          value={initialData?.type}
                                          onChange={handleDropDown}
                                          name="type"
                                        >
                                          <>
                                            {typeDropdown.map(
                                              (type: any, index: any) => (
                                                <option
                                                  value={type}
                                                  key={index}
                                                >
                                                  {type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    type
                                                      .slice(1)
                                                      .replace(
                                                        /([A-Z])/g,
                                                        " $1"
                                                      )}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosType")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          value={initialData.posType}
                                          onChange={handleDropDown}
                                          name="posType"
                                        >
                                          <>
                                            {posTypeDropDown.map(
                                              (posType: any, index: any) => (
                                                <option
                                                  value={posType}
                                                  key={index}
                                                >
                                                  { }
                                                  {posType
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    posType
                                                      .slice(1)
                                                      .replace(
                                                        /([A-Z])/g,
                                                        " $1"
                                                      )}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblSize")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="size"
                                            type="text"
                                            value={initialData?.size}
                                            onChange={handleInputText}
                                          />
                                          {sizeError && <p className="text-danger">{sizeError}</p>}
                                        </div>
                                      </div>
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblOpacity")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="opacity"
                                            type="text"
                                            value={initialData?.opacity}
                                            onChange={handleInputText}
                                          />
                                          {opacityError && <p className="text-danger">{opacityError}</p>}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col mb-3  col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblOtherColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control "
                                            name="otherColor"
                                            type="color"
                                            value={initialData.otherColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="posColor"
                                            type="color"
                                            value={initialData.posColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                                      <div className="col-md-6">
                                        <div className="form-check ">
                                          <label className="form-check-label" ><input className="form-check-input" name="logoInMiddle" type="checkbox" checked={checked} value={initialData.logoInMiddle} id="flexCheckDefault" onChange={handleChecked} />
                                            {t("qrCustomizePage.lblLogoInTheMiddle")}
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    {checked && (
                                      <div className="mb-4">
                                        <input type="file" name="icon" onChange={(e) => handleFileUpload(e)} accept="image/png, image/gif, image/jpeg" />
                                        {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}

                                      </div>
                                    )}
                                    <div className="row">
                                      <label className="text-danger">{t("qrCustomizePage.lblNote")}</label>
                                      <p className="text-danger">{initialData.note}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 d-flex justify-content-center">
                                    <div className="qrBoxDiv">
                                      <QRNormal
                                        level="H"
                                        value="https://truvideo.com"
                                        type={initialData.type}
                                        size={initialData.size}
                                        opacity={initialData.opacity}
                                        posType={initialData.posType}
                                        otherColor={initialData.otherColor}
                                        posColor={initialData.posColor}
                                        icon={titleIcon}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : selectDropdown === "QRDsj" ? (
                              <>
                                <div className="row">
                                  <div className="col-md-6" style={{ paddingRight: 0 }}>
                                    <div className="row">
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosType")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          value={initialData.posType}
                                          onChange={handleDropDown}
                                          name="posType"
                                        >
                                          <>
                                            {posTypeDropDown.map(
                                              (posType: any, index: any) => (
                                                <option
                                                  value={posType}
                                                  key={index}
                                                >
                                                  {posType
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    posType
                                                      .slice(1)
                                                      .replace(
                                                        /([A-Z])/g,
                                                        " $1"
                                                      )}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="row mb-3 col-md-6">
                                      <label className="mb-1">
                                        {t("qrCustomizePage.lblScale")}
                                      </label>
                                      <div>
                                        <Field
                                          className="form-control"
                                          name="scale"
                                          type="text"
                                          value={initialData.scale}
                                          onChange={handleInputText}
                                        />
                                        {scaleError && <p className="text-danger">{scaleError}</p>}
                                      </div>
                                    </div>

                                    <div className="row mb-3  col-md-6">
                                      <label className="mb-1">
                                        {t("qrCustomizePage.lblCrossWidth")}
                                      </label>
                                      <div>
                                        <Field
                                          className="form-control "
                                          name="crossWidth"
                                          type="text"
                                          value={initialData.crossWidth}
                                          onChange={handleInputText}
                                        />
                                        {crossWidthError && <p className="text-danger">{crossWidthError}</p>}
                                      </div>
                                    </div>
                                    <div className="row mb-3 col-md-6">
                                      <label className="mb-1">
                                        {t("qrCustomizePage.lblPosWidth")}
                                      </label>
                                      <div>
                                        <Field
                                          className="form-control"
                                          name="posWidth"
                                          type="text"
                                          value={initialData.posWidth}
                                          onChange={handleInputText}
                                        />
                                        {posTypeError && <p className="text-danger">{posTypeError}</p>}
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                                      <div className="col-md-6">
                                        <div className="form-check ">
                                          <label className="form-check-label" ><input className="form-check-input" name="logoInMiddle" type="checkbox" checked={checked} value={initialData.logoInMiddle} id="flexCheckDefault" onChange={handleChecked} />
                                            {t("qrCustomizePage.lblLogoInTheMiddle")}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {checked && (
                                      <div className="mb-4">
                                        <input type="file" name="icon" onChange={(e) => handleFileUpload(e)} accept="image/png, image/gif, image/jpeg" />
                                        {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}

                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-6 d-flex justify-content-center">
                                    <div className="qrBoxDiv">
                                      <QRDsj
                                        level="H"
                                        value="https://truvideo.com"
                                        posType={initialData.posType}
                                        scale={initialData.scale}
                                        crossWidth={initialData.crossWidth}
                                        posWidth={initialData.posWidth}
                                        icon={titleIcon}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : selectDropdown === "QR25D" ? (
                              <>
                                <div className="row">
                                  <div className="col-md-6" style={{ paddingRight: 0 }}>
                                    <div className="row">
                                      <div className="col mb-3 col-md-6 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblHeight")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="height"
                                            type="text"
                                            value={initialData.height}
                                            onChange={handleInputText}
                                          />
                                          {heightError && <p className="text-danger">{heightError}</p>}
                                        </div>
                                      </div>

                                      <div className="col mb-3  col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosHeight")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control "
                                            name="posHeight"
                                            type="text"
                                            value={initialData.posHeight}
                                            onChange={handleInputText}
                                          />
                                          {posHeightError && <p className="text-danger">{posHeightError}</p>}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblLeftColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="leftColor"
                                            type="color"
                                            value={initialData.leftColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblRightColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="rightColor"
                                            type="color"
                                            value={initialData.rightColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblTopColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="topColor"
                                            type="color"
                                            value={initialData.topColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <label className="text-danger"> {t("qrCustomizePage.lblNote")}</label>
                                      <p className="text-danger">{initialData.note}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 d-flex justify-content-center">
                                    <div className="qrBoxDiv">
                                      <QR25D
                                        level="H"
                                        value="https://truvideo.com"
                                        height={initialData.height}
                                        posHeight={initialData.posHeight}
                                        leftColor={initialData.leftColor}
                                        rightColor={initialData.rightColor}
                                        topColor={initialData.topColor}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : selectDropdown === "QRImage" ? (
                              <>
                                <div className="row">
                                  <div className="col-md-6" style={{ paddingRight: 0 }}>
                                    <div className="row">
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblType")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          value={initialData.type}
                                          onChange={handleDropDown}
                                          name="type"
                                        >
                                          <>
                                            {typeDropdown.map(
                                              (type: any, index: any) => (
                                                <option
                                                  value={type}
                                                  key={index}
                                                >
                                                  {type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    type
                                                      .slice(1)
                                                      .replace(
                                                        /([A-Z])/g,
                                                        " $1"
                                                      )}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosType")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          defaultValue={"0"}
                                          value={initialData.posType}
                                          onChange={handleDropDown}
                                          name="posType"
                                        >
                                          <>
                                            {posTypeDropDown.map(
                                              (posType: any, index: any) => (
                                                <option
                                                  value={posType}
                                                  key={index}
                                                >
                                                  {posType
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    posType
                                                      .slice(1)
                                                      .replace(
                                                        /([A-Z])/g,
                                                        " $1"
                                                      )}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblSize")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="size"
                                            type="text"
                                            value={initialData.size}
                                            onChange={handleInputText}
                                          />
                                          {sizeError && <p className="text-danger">{sizeError}</p>}
                                        </div>
                                      </div>
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblOpacity")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="opacity"
                                            type="text"
                                            value={initialData.opacity}
                                            onChange={handleInputText}
                                          />
                                          {opacityError && <p className="text-danger">{opacityError}</p>}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col mb-3  col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblDarkColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control "
                                            name="darkColor"
                                            type="color"
                                            value={initialData.darkColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                      <div className="col mb-3  col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblLightColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control "
                                            name="lightColor"
                                            type="color"
                                            value={initialData.lightColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="posColor"
                                            type="color"
                                            value={initialData.posColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblImage")}
                                        </label>
                                        <div>
                                          <ImageEditor getImage={getImageHandler} />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <label className="text-danger">{t("qrCustomizePage.lblNote")}</label>
                                      <p className="text-danger">{initialData.note}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 d-flex justify-content-center">
                                    <div className="qrBoxDiv">
                                      <QRImage
                                        level="H"
                                        value="https://truvideo.com"
                                        type={initialData.type}
                                        posType={initialData.posType}
                                        size={initialData.size}
                                        opacity={initialData.opacity}
                                        darkColor={initialData.darkColor}
                                        lightColor={initialData.lightColor}
                                        posColor={initialData.posColor}
                                        image={qrImage}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : selectDropdown === "QRBubble" ? (
                              <>
                                <div className="row">
                                  <div className="col-md-6" style={{ paddingRight: 0 }}>
                                    <div className="row mb-3">
                                      <label className="mb-1">
                                        {t("qrCustomizePage.lblCircleColor")}
                                      </label>
                                      <div>
                                        <Field
                                          className="form-control"
                                          name="circleColor"
                                          type="color"
                                          value={initialData.circleColor}
                                          onChange={handelColor}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-3">
                                      <label className="mb-1">
                                        {t("qrCustomizePage.lblPosColor")}
                                      </label>
                                      <div>
                                        <Field
                                          className="form-control"
                                          name="posColor"
                                          type="color"
                                          value={initialData.posColor}
                                          onChange={handelColor}
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <label className="text-danger">{t("qrCustomizePage.lblNote")}</label>
                                      <p className="text-danger">{initialData.note}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 d-flex justify-content-center">
                                    <div className="qrBoxDiv">
                                      <QRBubble
                                        level="H"
                                        value="https://truvideo.com"
                                        circleColor={initialData.circleColor}
                                        posColor={initialData.posColor}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : selectDropdown === "QRFunc" ? (
                              <>
                                <div className="row">
                                  <div className="col-md-6" style={{ paddingRight: 0 }}>
                                    <div className="row col-md-6">
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblFuncType")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          defaultValue={"0"}
                                          value={initialData.funcType}
                                          onChange={handleDropDown}
                                          name="funcType"
                                        >
                                          <>
                                            {funcTypeDropDown.map(
                                              (funcType: any, index: any) => (
                                                <option
                                                  value={funcType}
                                                  key={index}
                                                >
                                                  {funcType}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="row ">
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblType")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          value={initialData.type}
                                          onChange={handleDropDown}
                                          name="type"
                                        >
                                          <>
                                            {typeDropdown.map(
                                              (type: any, index: any) => (
                                                <option
                                                  value={type}
                                                  key={index}
                                                >
                                                  {type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    type
                                                      .slice(1)
                                                      .replace(
                                                        /([A-Z])/g,
                                                        " $1"
                                                      )}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosType")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          defaultValue={"0"}
                                          value={initialData.posType}
                                          onChange={handleDropDown}
                                          name="posType"
                                        >
                                          <>
                                            {posTypeDropDown.map(
                                              (posType: any, index: any) => (
                                                <option
                                                  value={posType}
                                                  key={index}
                                                >
                                                  {posType
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    posType
                                                      .slice(1)
                                                      .replace(
                                                        /([A-Z])/g,
                                                        " $1"
                                                      )}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col mb-3  col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblOtherColor1")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control "
                                            name="otherColor1"
                                            type="color"
                                            value={initialData.otherColor1}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="posColor"
                                            type="color"
                                            value={initialData.posColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <label className="text-danger">{t("qrCustomizePage.lblNote")}</label>
                                      <p className="text-danger">{initialData.note}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 d-flex justify-content-center">
                                    <div className="qrBoxDiv">
                                      <QRFunc
                                        level="H"
                                        value="https://truvideo.com"
                                        funcType={initialData.funcType}
                                        type={initialData.type}
                                        posType={initialData.posType}
                                        otherColor1={initialData.otherColor1}
                                        posColor={initialData.posColor}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row">
                                  <div className="col-md-6" style={{ paddingRight: 0 }}>
                                    <div className="row ">
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblDirection")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          value={initialData.direction}
                                          name="direction"
                                          onChange={handleDropDown}
                                        >
                                          <>
                                            {directionDropDown.map(
                                              (direction: any, index: any) => (
                                                <option
                                                  value={direction}
                                                  key={index}
                                                >
                                                  {direction
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    direction
                                                      .slice(1)
                                                      .replace(
                                                        /([A-Z])/g,
                                                        " $1"
                                                      )}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                      <div className="col mb-3 ">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosType")}
                                        </label>
                                        <select
                                          className="form-select form-select-mm py-3"
                                          aria-label="Default select example"
                                          value={initialData.posType}
                                          onChange={handleDropDown}
                                          name="posType"
                                        >
                                          <>
                                            {posTypeDropDown.map(
                                              (posType: any, index: any) => (
                                                <option
                                                  value={posType}
                                                  key={index}
                                                >
                                                  {posType
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    posType
                                                      .slice(1)
                                                      .replace(
                                                        /([A-Z])/g,
                                                        " $1"
                                                      )}
                                                </option>
                                              )
                                            )}
                                          </>
                                        </select>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col mb-3  col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblLineColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control "
                                            name="lineColor"
                                            type="color"
                                            value={initialData.lineColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblPosColor")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="posColor"
                                            type="color"
                                            value={initialData.posColor}
                                            onChange={handelColor}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col mb-3 col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblLineOpacity")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control"
                                            name="lineOpacity"
                                            type="text"
                                            value={initialData.lineOpacity}
                                            onChange={handleInputText}
                                          />
                                          {lineOpacityError && <p className="text-danger">{lineOpacityError}</p>}
                                        </div>
                                      </div>
                                      <div className="col mb-3  col-md-6">
                                        <label className="mb-1">
                                          {t("qrCustomizePage.lblLineWidth")}
                                        </label>
                                        <div>
                                          <Field
                                            className="form-control "
                                            name="lineWidth"
                                            type="text"
                                            value={initialData.lineWidth}
                                            onChange={handleInputText}
                                          />
                                          {lineWidthError && <p className="text-danger">{lineWidthError}</p>}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                                      <div className="col-md-6">
                                        <div className="form-check ">
                                          <label className="form-check-label" ><input className="form-check-input" name="logoInMiddle" type="checkbox" checked={checked} value={initialData.logoInMiddle} id="flexCheckDefault" onChange={handleChecked} />
                                            {t("qrCustomizePage.lblLogoInTheMiddle")}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {checked && (
                                      <div className="mb-4">
                                        <input type="file" name="icon" onChange={(e) => handleFileUpload(e)} accept="image/png, image/gif, image/jpeg" />
                                        {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
                                      </div>
                                    )}
                                    <div className="row">
                                      <label className="text-danger">{t("qrCustomizePage.lblNote")}</label>
                                      <p className="text-danger">{initialData.note}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 d-flex justify-content-center">
                                    <div className="qrBoxDiv">
                                      <QRLine
                                        level="H"
                                        value="https://truvideo.com"
                                        direction={initialData.direction}
                                        posType={initialData.posType}
                                        posColor={initialData.posColor}
                                        lineColor={initialData.lineColor}
                                        lineOpacity={initialData.lineOpacity}
                                        lineWidth={initialData.lineWidth}
                                        icon={titleIcon}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            <div>
                              <button onClick={() => setShowDelete(true)} className="btn btn-primary">
                                {t("qrCustomizePage.btnSave")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <DeleteDialogBox
        show={showDelete}
        clickOk={() => {
          // deleteBusinessHandler(businessDeleteID);
          setShowDelete(false)
          handleSubmit()
        }}
        clickCancel={() => {
          setShowDelete(false);
        }}
        color={"btn-success"}
        btnyes={"Yes"}
        btncancel={"No"}
        question={"Have you tested right side example QR code before saving customised QR code?"}
      />
    </>
  );
};
export default QrCustomizePage;
