/* eslint-disable no-mixed-operators */
import React, { FC, useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IBusiness } from "../types/business.type";
import {
  addBusinessData,
  addTypeLabel,
  getMobileLabelData,
  getEmailLabelData,
  getAddressLabelData,
  getWebsiteLabelData,
  businessList,
} from "../services/business.service";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import * as bootstrap from "bootstrap";
import { ILabel } from "../types/label.type";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { ImageEditor } from "../components/imageEditor";

interface BusinessRegisterPageProps {
  title: string;
}

// eslint-disable-next-line no-empty-pattern
const BusinessRegisterPage: FC<BusinessRegisterPageProps> = ({ }) => {
  const { t } = useTranslation("translate");
  const itemNameMobileNumber = { mobileNumber: "", numberTitle: "" };
  const [elemNamesMobileNumbers, setElemNamesMobileNumbers] = useState<any>([
    itemNameMobileNumber,
  ]);
  const itemNameEmail = { email: "", emailLabel: "" };
  const [elemNamesEmails, setElemNamesEmails] = useState([itemNameEmail]);
  const itemNameWebsite = { website: "", websiteLabel: "" };
  const [elemNamesWebsites, setElemNamesWebsites] = useState([itemNameWebsite]);
  const itemNameAddress = {
    address: "",
    addressLabel: "",
    street: "",
    city: "",
    stateProvince: "",
    postalCode: "",
    countryRegion: "",
  };
  const [elementNameAddress, setElementNameAddress] = useState([
    itemNameAddress,
  ]);
  const [isIndividual, setIsIndividual] = useState("0");
  const [followUpBusinessId, setFollowUpBusinessId] = useState("0");
  const [busnessList, setBusinessList] = useState<any[]>([]);
  const [disabled, setDisable] = useState<boolean>(false);
  const [mobilesLastLabel, setMobilesLastLabel] = useState<any>([]);
  const [emailLastLabel, setEmailLastLabel] = useState<any>([]);
  const [addressLastLabel, setAddressLastLabel] = useState<any>([]);
  const [image, setImage] = useState<any>();

  const [websiteLastLabel, setWebsiteLastLabel] = useState<any>([]);

  const initialValuesBusinessRegister: IBusiness = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    title: "",
    organization: "",
    websites: [],
    mobileNumbers: [],
    emails: [],
    addresses: [],
    note: "",
    qrCodeText: "",
  };

  const initialValues: ILabel = {
    labelType: "",
    labelText: "",
  };
  let navigate = useNavigate();

  const [nextId, setNextId] = useState(0);
  const [nextIdEmail, setNextIdEmail] = useState(0);
  const [nextIdWebsite, setNextIdWebsite] = useState(0);
  const [nextIdAddress, setNextIdAddress] = useState(0);
  const [emailmessage, setEmailMessage] = useState("");
  const [mobileMessage, setMobileMessage] = useState("");
  const [websiteMessage, setWebsiteMessage] = useState("");
  const [labelMessage, setLabelMessage] = useState("");
  const [successful, setSuccessful] = useState<boolean>(false);
  const [brandFiles, setBrandFiles] = useState<any[]>([]);
  const [emailLabel, setEmailLabel] = useState<any[]>([]);
  const [mobileLabel, setMobileLabel] = useState<any>([]);
  const [addressLabel, setAddressLabel] = useState<any[]>([]);
  const [websiteLabel, setWebsiteLabel] = useState<any[]>([]);
  const [selectDropdown, setSelectDropDown] = useState("0");
  const [noteData, setNoteData] = useState<string>("");
  const [totalChar, setTotalChar] = useState<number>(512);
  const [currentPhoneIndex, setCurrentPhoneIndex] = useState<any>();
  const [currentEmailIndex, setCurrentEmailIndex] = useState<any>();
  const [currentAddressIndex, setCurrentAddressIndex] = useState<any>();
  const [imgUrl, setImgUrl] = useState("")
  const [currentWebsiteIndex, setCurrentWebsiteIndex] = useState<any>();

  function noteDataHandler(e: any) {
    setNoteData(e.target.value);
  }
  useEffect(() => {
    // do not delete this it is useful for label not added as last index
  }, [elemNamesMobileNumbers, elemNamesEmails, elemNamesWebsites]);

  useEffect(() => {
    setTotalChar(512 - noteData.length);
  }, [noteData]);
  const validationSchemaBusinessRegister = Yup.object().shape({
    firstName: Yup.string().nullable(true),
    lastName: Yup.string().nullable(true),
    title: Yup.string().nullable(true),
    organization: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    note: Yup.string().nullable(true),
    qrCodeText: Yup.string().nullable(true),
  });
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    lastName: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    mobileNumbers: Yup.array().required("This field is required!"),
    title: Yup.string()
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    organization: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    note: Yup.string().nullable(true),
    qrCodeText: Yup.string().nullable(true),
  });
  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = async () => {
    window.scrollTo(0, 0)
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }
  const handleBusinessRegister = async (formValue: IBusiness) => {
    let { firstName, lastName, title, organization } = formValue;
    firstName = firstName.trim();
    lastName = lastName.trim();
    title = title.trim();
    organization = organization.trim();

    // converting base64 image to binary //

    let base64Response;
    let imageFile: any;
    if (image) {
      base64Response = await fetch(image);
      imageFile = await base64Response.blob();
      setBrandFiles(imageFile);
    }

    if (
      elemNamesMobileNumbers[0].mobileNumber === "" ||
      elemNamesMobileNumbers[0].mobileNumber === undefined
    ) {
      toast.error("Please add at least one mobile number", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (elemNamesEmails[0].email === "") {
      toast.error("Please add at least one email", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
    if (
      !!elemNamesMobileNumbers[0].mobileNumber &&
      elemNamesMobileNumbers[0].mobileNumber !== "" &&
      !!elemNamesEmails[0].email &&
      elemNamesEmails[0].email !== "" &&
      emailmessage === "" &&
      websiteMessage === "" &&
      mobileMessage === ""
    ) {
      await addBusinessData(
        { ...formValue, firstName, lastName, organization, title },
        imageFile,
        elemNamesMobileNumbers,
        elemNamesEmails,
        elemNamesWebsites,
        elementNameAddress,
        isIndividual,
        followUpBusinessId,
        noteData
      ).then(
        (response) => {
          setDisable(true);
          toast.success("Business added sucessfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate("/");
          }, 100);
          setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              // eslint-disable-next-line no-mixed-operators
              error.response.data.error &&
              error.response.data.error.email) ||
            error.response.data.error.mobileNumber ||
            error.message ||
            error.toString();

          setSuccessful(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.email
          ) {
            setEmailMessage(resMessage);
          }
          if (
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.mobileNumber
          ) {
            setMobileMessage(resMessage);
          }

          if (
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.errors[0] &&
            error.response.data.error.errors[0].messages[0]
          ) {
            let field = error.response.data.error.errors[0].field[0];
            let originPlace = error.response.data.error.errors[0].field[1];
            let place = originPlace + 1;
            let errorMsg = error.response.data.error.errors[0].messages[0];
            let errorMessage = errorMsg.replace('"' + originPlace + '"', place);
            let fieldFormat = field
              .replace(/([a-z])([A-Z])/g, "$1 $2")
              .replace(/\b\w+/g, function (word: any, index: any) {
                if (index === 0) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                } else {
                  return word.charAt(0).toLowerCase() + word.slice(1);
                }
              });
            let message = fieldFormat + " " + errorMessage;
            setWebsiteMessage(message);
          }
          setDisable(false);
        }
      );
    }
  };
  const handleaddmobilenumberlabel = (formValue: ILabel) => {
    addTypeLabel("mobile", formValue.labelText.trim()).then(
      (response) => {
        const res = [...mobileLabel, response.data.data.newLabel];
        setMobileLabel(res);
        var myModal = bootstrap.Modal.getOrCreateInstance(
          document.getElementById("custom-field-mobile") as HTMLElement
        );
        myModal.hide();
        formValue.labelText = "";
        toast.success("Label added sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });

        setLabelMessage("");
        setWebsiteMessage("");
        setTimeout(function () {
          getData();
        }, 500);
        mobilesLastLabel[currentPhoneIndex] =
          response.data.data.newLabel.labelText;
        setMobilesLastLabel(mobilesLastLabel);
        let data = [...elemNamesMobileNumbers];
        data[currentPhoneIndex]["numberTitle"] =
          response.data.data.newLabel.labelText;
        setElemNamesMobileNumbers(data);
      },
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        if (error.response.data.code === 400) {
          setLabelMessage("This is required field!");
        }
        else {
          setLabelMessage(error.response.data.errorMessage);
        }
        formValue.labelText = "";
      }
    );
  };

  const handladdemaillabel = (formValue: ILabel) => {
    addTypeLabel("email", formValue.labelText.trim()).then(
      (response) => {
        const res = [...emailLabel, response.data.data.newLabel];
        setEmailLabel(res);
        var myModal = bootstrap.Modal.getOrCreateInstance(
          document.getElementById("custom-field-email") as HTMLElement
        );
        myModal.hide();
        formValue.labelText = "";
        toast.success("Label added sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLabelMessage("");
        setWebsiteMessage("");
        setTimeout(function () {
          getData();
        }, 500);
        emailLastLabel[currentEmailIndex] =
          response.data.data.newLabel.labelText;
        setEmailLastLabel(emailLastLabel);
        let data = [...elemNamesEmails];
        data[currentEmailIndex]["emailLabel"] =
          response.data.data.newLabel.labelText;
        setElemNamesEmails(data);
      },
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        if (error.response.data.code === 400) {
          setLabelMessage("This is required field!");
          console.log(error, "error");
        } else {
          setLabelMessage(error.response.data.errorMessage);
        }
        formValue.labelText = "";
      }
    );
  };
  const handleaddAddresslabel = (formValue: ILabel) => {
    addTypeLabel("address", formValue.labelText.trim()).then(
      (response) => {
        const res = [...addressLabel, response.data.data.newLabel];
        setAddressLabel(res);
        var myModal = bootstrap.Modal.getOrCreateInstance(
          document.getElementById("custom-field-address") as HTMLElement
        );
        myModal.hide();
        formValue.labelText = "";
        toast.success("Label added sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLabelMessage("");
        setWebsiteMessage("");
        setTimeout(function () {
          getData();
        }, 500);
        addressLastLabel[currentAddressIndex] =
          response.data.data.newLabel.labelText;
        setAddressLastLabel(addressLastLabel);
        let data = [...elementNameAddress];
        data[currentAddressIndex]["addressLabel"] =
          response.data.data.newLabel.labelText;
        setElementNameAddress(data);
      },
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        if (error.response.data.code === 400) {
          setLabelMessage("This is required field!");
          console.log(error, "error");
        } else {
          setLabelMessage(error.response.data.errorMessage);
        }
        formValue.labelText = "";
      }
    );
  };
  const handleaddWebsitelabel = (formValue: ILabel) => {
    addTypeLabel("website", formValue.labelText.trim()).then(
      (response) => {
        const res = [...websiteLabel, response.data.data.newLabel];
        setWebsiteLabel(res);
        var myModal = bootstrap.Modal.getOrCreateInstance(
          document.getElementById("custom-field-website") as HTMLElement
        );
        myModal.hide();
        formValue.labelText = "";
        toast.success("Label added sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });

        setLabelMessage("");
        setWebsiteMessage("");
        setTimeout(function () {
          getData();
        }, 500);
        websiteLastLabel[currentWebsiteIndex] =
          response.data.data.newLabel.labelText;
        setWebsiteLastLabel(websiteLastLabel);
        let data = [...elemNamesWebsites];
        data[currentWebsiteIndex]["websiteLabel"] =
          response.data.data.newLabel.labelText;
        setElemNamesWebsites(data);
      },
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        if (error.response.data.code === 400) {
          setLabelMessage("This is required field!");
        }
        else {
          setLabelMessage(error.response.data.errorMessage);
        }
      }
    );
  };
  const handleFormChange = (index: any, event: any) => {
    let data = [...elemNamesMobileNumbers];
    if (event != undefined) {
      data[index]["mobileNumber"] = event;
      setElemNamesMobileNumbers(data);
      setMobileMessage("");
      setWebsiteMessage("");
    } else {
      data[index]["mobileNumber"] = event;
      setElemNamesMobileNumbers(data);
    }
  };
  const handleElemRemove = (elemName: any) => {
    setMobileMessage("");
    const remaningItems = [...elemNamesMobileNumbers].filter(
      (_elemName) => _elemName !== elemName
    );
    remaningItems.map((remainingMobile: any, index: any) => {
      mobilesLastLabel[index] = remainingMobile.numberTitle;
      setMobilesLastLabel(mobilesLastLabel)
    })
    setElemNamesMobileNumbers(remaningItems);
    setWebsiteMessage("");
  };

  const handleElemAdd = (e: any) => {
    setElemNamesMobileNumbers([
      ...elemNamesMobileNumbers,
      itemNameMobileNumber,
    ]);
    setNextId(nextId + 1);
    e.preventDefault();
  };

  const handleFormChangeEmail = (index: any, event: any) => {
    let data = [...elemNamesEmails];
    if (event != undefined) {
      data[index]["email"] = event.target.value.trim();
      setElemNamesEmails(data);
      setEmailMessage("");
      setWebsiteMessage("");

    } else {
      data[index]["email"] = event;
      setElemNamesEmails(data);
    }
  };
  const handleElemRemoveEmail = (elemName: any) => {
    const remaningItemsEmail = [...elemNamesEmails].filter(
      (_elemName) => _elemName !== elemName
    );
    remaningItemsEmail.map((remainingEmail: any, index: any) => {
      emailLastLabel[index] = remainingEmail.emailLabel;
      setEmailLastLabel(emailLastLabel)
    })
    setElemNamesEmails(remaningItemsEmail);
    setWebsiteMessage("");
  };
  const handleElemAddEmail = (e: any) => {
    setElemNamesEmails([...elemNamesEmails, itemNameEmail]);
    setNextIdEmail(nextIdEmail + 1);
    e.preventDefault();
  };

  const handleFormChangeCountry = (index: any, event: any) => {
    let data = [...elementNameAddress];
    let regix = /[a-zA-Z]/;
    if (event.target.value != "") {
      if (regix.test(event.target.value)) {
        data[index]["countryRegion"] = event.target.value;
        setElementNameAddress(data);
        setWebsiteMessage("");

        return;
      }
    } else {
      data[index]["countryRegion"] = "";
      setElementNameAddress(data);
      setWebsiteMessage("");
      return;
    }
    setWebsiteMessage(
      "Country can not accept only space and special characters"
    );
  };
  const handleFormChangeSteet = (index: any, event: any) => {
    let data = [...elementNameAddress];
    let regix = /[a-zA-Z0-9]/;
    if (event.target.value != "") {
      if (regix.test(event.target.value)) {
        setWebsiteMessage("");
        data[index]["street"] = event.target.value;
        setElementNameAddress(data);
        return;
      }
    } else {
      data[index]["street"] = "";
      setElementNameAddress(data);
      setWebsiteMessage("");
      return;
    }
    setWebsiteMessage(
      "Street can not accept only space and special characters"
    );
  };
  const handleFormChangeCity = (index: any, event: any) => {
    let data = [...elementNameAddress];
    let regix = /[a-zA-Z]/;
    if (event.target.value != "") {
      if (regix.test(event.target.value)) {
        data[index]["city"] = event.target.value;
        setElementNameAddress(data);
        setWebsiteMessage("");

        return;
      }
    } else {
      data[index]["city"] = "";
      setElementNameAddress(data);
      setWebsiteMessage("");
      return;
    }
    setWebsiteMessage("City can not accept only space and special characters");
  };
  const handleFormChangeState = (index: any, event: any) => {
    let data = [...elementNameAddress];
    let regix = /[a-zA-Z]/;
    if (event.target.value != "") {
      if (regix.test(event.target.value)) {
        data[index]["stateProvince"] = event.target.value;
        setElementNameAddress(data);
        setWebsiteMessage("");

        return;
      }
    } else {
      data[index]["stateProvince"] = "";
      setElementNameAddress(data);
      setWebsiteMessage("");
      return;
    }
    setWebsiteMessage("State can not accept only space and special characters");
  };
  const handleFormChangePostalCode = (index: any, event: any) => {
    let data = [...elementNameAddress];
    if (event.target.value != " " && event.target.value != "") {
      if (/^[a-zA-Z0-9 ]+$/i.test(event.target.value)) {
        data[index]["postalCode"] = event.target.value;
        setElementNameAddress(data);
        setWebsiteMessage("");
        return;
      }
    } else {
      if (data[index]["postalCode"].length > 0) {
        data[index]["postalCode"] = "";
        setElementNameAddress(data);
        setWebsiteMessage("");
        return;
      } else {
        setWebsiteMessage("Postal code not accepted only spaces");
        return
      }
    }
    setWebsiteMessage("Postal code not accepted special characters");
  };
  const handleFormChangeWebsites = (index: any, event: any) => {
    let data = [...elemNamesWebsites];
    if (event != undefined) {
      data[index]["website"] = event.target.value.trim();
      setElemNamesWebsites(data);
      setWebsiteMessage("");
    } else {
      data[index]["website"] = event;
      setElemNamesWebsites(data);
    }
  };
  const handleElemRemoveWebsites = (elemName: any) => {
    const remaningItemsWebsite = [...elemNamesWebsites].filter(
      (_elemName) => _elemName !== elemName
    );
    remaningItemsWebsite.map((remainingWebsite: any, index: any) => {
      websiteLastLabel[index] = remainingWebsite.websiteLabel;
      setWebsiteLastLabel(websiteLastLabel)
    })
    setElemNamesWebsites(remaningItemsWebsite);
  };
  const handleElemAddWebsites = (e: any) => {
    setElemNamesWebsites([...elemNamesWebsites, itemNameWebsite]);
    setNextIdWebsite(nextIdWebsite + 1);
    e.preventDefault();
  };
  const handleElemRemoveAddress = (elemName: any) => {
    const remaningItemsAddress = [...elementNameAddress].filter(
      (_elemName) => _elemName !== elemName
    );
    remaningItemsAddress.map((remainingAddress: any, index: any) => {
      addressLastLabel[index] = remainingAddress.addressLabel;
      setAddressLastLabel(addressLastLabel)
    })
    setElementNameAddress(remaningItemsAddress);
  };
  const handleElemAddAddress = (e: any) => {
    setElementNameAddress([...elementNameAddress, itemNameAddress]);
    setNextIdAddress(nextIdAddress + 1);
    e.preventDefault();
  };
  const handleClick = (index: any, e: any) => {
    setCurrentPhoneIndex(index);
    let data = [...elemNamesMobileNumbers];
    if (e.target.value === "Add custom Label") {
      new bootstrap.Modal(
        document.getElementById("custom-field-mobile") as HTMLElement
      ).show();
      mobilesLastLabel[index] = "";
      let number: any = document.getElementById("mobilename");
      number.value = "";
    } else {
      data[index]["numberTitle"] = e.target.value;
      setElemNamesMobileNumbers(data);
      setWebsiteMessage("");
      mobilesLastLabel[index] = e.target.value;
      setMobilesLastLabel(mobilesLastLabel);
    }
  };

  const handleemailclick = (index: any, e: any) => {
    setCurrentEmailIndex(index);
    let data = [...elemNamesEmails];
    if (e.target.value === "Add custom Label") {
      new bootstrap.Modal(
        document.getElementById("custom-field-email") as HTMLElement
      ).show();
      emailLastLabel[index] = "";
      let number: any = document.getElementById("emailname");
      number.value = "";
    } else {
      data[index]["emailLabel"] = e.target.value;
      setElemNamesEmails(data);
      setWebsiteMessage("");
      emailLastLabel[index] = e.target.value;
      setEmailLastLabel(emailLastLabel);
    }
  };
  const handleAddressClick = (index: any, e: any) => {
    setCurrentAddressIndex(index);
    let data = [...elementNameAddress];
    if (e.target.value === "Add custom Label") {
      new bootstrap.Modal(
        document.getElementById("custom-field-address") as HTMLElement
      ).show();
      addressLastLabel[index] = "";
      let address: any = document.getElementById("addressname");
      address.value = "";
    } else {
      data[index]["addressLabel"] = e.target.value;
      setElementNameAddress(data);
      setWebsiteMessage("");
      addressLastLabel[index] = e.target.value;
      setAddressLastLabel(addressLastLabel);
    }
  };
  const handleeWebsiteclick = (index: any, e: any) => {
    setCurrentWebsiteIndex(index);
    let data = [...elemNamesWebsites];
    if (e.target.value === "Add custom Label") {
      new bootstrap.Modal(
        document.getElementById("custom-field-website") as HTMLElement
      ).show();
      websiteLastLabel[index] = "";
      let website: any = document.getElementById("websiteName");
      website.value = "";
    } else {
      data[index]["websiteLabel"] = e.target.value;
      setElemNamesWebsites(data);
      setWebsiteMessage("");
      websiteLastLabel[index] = e.target.value;
      setWebsiteLastLabel(websiteLastLabel);
    }
  };

  const handledropdownclick = (e: any) => {
    let data = e.target.value;
    setIsIndividual(data);
    setSelectDropDown(data);
  };
  const handleBusinessInsert = (e: any) => {
    let data = e.target.value;
    setFollowUpBusinessId(data);
  };
  const getData = async () => {
    const labelEmailData = await getEmailLabelData();
    const labelMobileData = await getMobileLabelData();
    const labeladdressData = await getAddressLabelData();
    const labelWebsiteData = await getWebsiteLabelData();
    const businessData = await businessList();

    setEmailLabel(labelEmailData.data.data.labelsData);
    setMobileLabel(labelMobileData.data.data.labelsData);
    setAddressLabel(labeladdressData.data.data.labelsData);
    setWebsiteLabel(labelWebsiteData.data.data.labelsData);
    setBusinessList(businessData.data.data.businesses.rows);
  };
  useEffect(() => {
    getData();
  }, []);

  // image editior handler

  function getImageHandler(data: any) {
    if (data?.status != "removed") {
      setImage(data?.thumbUrl);
    } else {
      setImage(undefined);
    }
  }

  // get user country code by IP Address //

  const [countryCode, setCountryCode] = useState<any>("");

  const handleClose = () => {
    setLabelMessage("");
  };
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryCode(data.country_code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <main>
        <div className="container-fluid px-lg-4">
          <h3 className="mt-3 mb-4">
            {t("businessRegisterPage.lblBusinessRegister")}
          </h3>

          <div className="row">
            <div className="col-md-12">
              <div className="form-floating">
                <div className="card mb-4 card-border">
                  <div className="container px-lg-4 py-4">
                    <Formik
                      ref={formRef}
                      initialValues={initialValuesBusinessRegister}
                      validationSchema={
                        selectDropdown === "0"
                          ? validationSchemaBusinessRegister
                          : validationSchema
                      }
                      onSubmit={handleBusinessRegister}
                      enableReinitialize={true}
                    >
                      <Form>
                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <select
                              className="form-select py-3"
                              aria-label="Default select example"
                              name="isIndividual"
                              onChange={(e: any) => {
                                handledropdownclick(e);
                              }}
                              defaultValue={"0"}
                            >
                              <>
                                <option value="1">
                                  {t("businessRegisterPage.lblIndividual")}
                                </option>
                                <option value="0">
                                  {t("businessRegisterPage.lblBusiness")}
                                </option>
                              </>
                            </select>
                          </div>
                        </div>
                        {selectDropdown === "0" ? (
                          <></>
                        ) : (
                          <>
                            <div className="row">
                              <div className="col-md-6 mb-4">
                                <select
                                  className="form-select py-3"
                                  aria-label="Default select example"
                                  onChange={(e: any) => {
                                    handleBusinessInsert(e);
                                  }}
                                >
                                  <option>
                                    {t(
                                      "businessRegisterPage.lblNoFollowUpDealerCard"
                                    )}
                                  </option>
                                  <>
                                    {!!busnessList &&
                                      busnessList.length > 0 &&
                                      busnessList.map((busnessList, index) => (
                                        <option
                                          value={busnessList.businessRef}
                                          key={index}
                                        >
                                          {busnessList.organization}
                                        </option>
                                      ))}
                                  </>
                                </select>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="form-floating">
                                  <Field
                                    className="form-control"
                                    id="inputFirstName"
                                    name="firstName"
                                    type="text"
                                    placeholder="Enter your first name"
                                  />
                                  <label htmlFor="inputFirstName">
                                    {t("businessRegisterPage.lblFirstName")}
                                  </label>
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="text-danger txt-error"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="form-floating">
                                  <Field
                                    className="form-control"
                                    id="inputLastName"
                                    name="lastName"
                                    type="text"
                                    placeholder="Enter your last name"
                                  />
                                  <label htmlFor="inputLastName">
                                    {t("businessRegisterPage.lblLastName")}
                                  </label>
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="text-danger txt-error"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="form-floating">
                                  <Field
                                    className="form-control"
                                    id="inputTitle"
                                    name="title"
                                    type="text"
                                    placeholder="Enter your last name"
                                  />
                                  <label htmlFor="inputTitle">
                                    {t("businessRegisterPage.lblTitle")}
                                  </label>
                                  <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="text-danger txt-error"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <div className="form-floating ">
                              <Field
                                className="form-control"
                                id="inputOrganization"
                                name="organization"
                                type="text"
                                placeholder="Enter your organization name"
                              />
                              <label htmlFor="inputOrganization">
                                {t("businessRegisterPage.lblOrganization")}
                              </label>
                              <ErrorMessage
                                name="organization"
                                component="div"
                                className="text-danger txt-error"
                              />
                            </div>
                          </div>
                        </div>
                        {!!elemNamesMobileNumbers &&
                          elemNamesMobileNumbers.length > 0 &&
                          elemNamesMobileNumbers.map(
                            (elemNamesMobileNumber: any, index: any) => (
                              <div className="row" key={index}>
                                <div className="col-md-6 mb-2 mb-md-4">
                                  <div className="lh-sm border rounded">
                                    <label
                                      className="ps-2 pt-1"
                                      htmlFor="inputMobileNumber"
                                    >
                                      {t(
                                        "businessRegisterPage.lblMobileNumber"
                                      )}{" "}
                                      {index + 1}
                                    </label>
                                    <PhoneInput
                                      defaultCountry={countryCode}
                                      value={elemNamesMobileNumber.mobileNumber}
                                      onChange={(e: any) =>
                                        handleFormChange(index, e)
                                      }
                                      className="border-0 pt-0 form-control PhoneInputInput"
                                      placeholder="Enter phone number"
                                      name="mobileNumber"
                                    />

                                    {/* <input className="form-control" id="inputMobileNumber" name="mobileNumber[]" type="text" value={elemNamesMobileNumber.mobileNumber} placeholder={`Mobile number ${index + 1}`} onChange={(e: any) => handleFormChange(index, e)} /> */}
                                  </div>
                                </div>
                                <div className="col-md-3 mb-2 mb-md-4">
                                  <Field
                                    className="form-control py-3"
                                    value={mobilesLastLabel[index]}
                                    as="select"
                                    name="mobileLabels"
                                    onChange={(e: any) => {
                                      handleClick(index, e);
                                    }}
                                  >
                                    <option value="">
                                      {t("businessRegisterPage.selectLabel")}
                                    </option>
                                    {!!mobileLabel &&
                                      mobileLabel.length > 0 &&
                                      mobileLabel.map(
                                        (label: any, index: any) => (
                                          <>
                                            {label?.defaultLabels?.count ==
                                              0 ? (
                                              " "
                                            ) : (
                                              <optgroup label="Default labels">
                                                {label?.defaultLabels?.rows.map(
                                                  (
                                                    defaultLabel: any,
                                                    defaultIndex: any
                                                  ) => {
                                                    return (
                                                      <option
                                                        value={
                                                          defaultLabel.labelText
                                                        }
                                                        key={defaultIndex}
                                                      >
                                                        {defaultLabel.labelText}{" "}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </optgroup>
                                            )}
                                            {label.customLabels?.count == 0 ? (
                                              " "
                                            ) : (
                                              <optgroup label="Custom labels">
                                                {label?.customLabels?.rows.map(
                                                  (
                                                    customLabel: any,
                                                    customIndex: any
                                                  ) => {
                                                    return (
                                                      <option
                                                        value={
                                                          customLabel.labelText
                                                        }
                                                        key={customIndex}
                                                      >
                                                        {customLabel.labelText}{" "}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </optgroup>
                                            )}
                                          </>
                                        )
                                      )}
                                    <option value="Add custom Label">
                                      {t("businessRegisterPage.addCustomLabel")}
                                    </option>
                                  </Field>
                                </div>
                                <div className="col-md-3 mb-4 d-flex align-items-center gap-2">
                                  {index + 1 ===
                                    elemNamesMobileNumbers.length ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-primary"
                                        type="button"
                                        disabled={
                                          elemNamesMobileNumbers.length >= 10
                                        }
                                        onClick={handleElemAdd}
                                      >
                                        <i className="fas fa-add"></i>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {elemNamesMobileNumbers.length >= 2 ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-primary"
                                        type="button"
                                        disabled={
                                          elemNamesMobileNumbers.length === 1
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setTimeout(() => {
                                            handleElemRemove(
                                              elemNamesMobileNumber
                                            );
                                          }, 0);
                                        }}
                                      >
                                        <i className="fas fa-minus"></i>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            )
                          )}

                        {mobileMessage && (
                          <div className="form-group">
                            <div
                              className={
                                successful
                                  ? "alert alert-success"
                                  : "alert alert-danger p-2 rounded-0 border-0"
                              }
                              role="alert"
                            >
                              {mobileMessage}
                            </div>
                          </div>
                        )}
                        {!!elemNamesEmails &&
                          elemNamesEmails.length > 0 &&
                          elemNamesEmails.map((elemNamesEmail, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-6 mb-2 mb-md-4">
                                <div className="form-floating">
                                  <input
                                    className="form-control"
                                    id="inputEmail"
                                    name="email[]"
                                    type="text"
                                    value={elemNamesEmail.email}
                                    placeholder={`Email address ${index + 1}`}
                                    onChange={(e: any) =>
                                      handleFormChangeEmail(index, e)
                                    }
                                  />
                                  <label htmlFor="inputEmail">
                                    {t("businessRegisterPage.lblEmailAddress")}{" "}
                                    {index + 1}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3 mb-2 mb-md-4">
                                <Field
                                  className="form-control py-3"
                                  value={emailLastLabel[index]}
                                  as="select"
                                  name="emailLabel"
                                  onChange={(e: any) => {
                                    handleemailclick(index, e);
                                  }}
                                >
                                  <option value="">Select Label</option>

                                  {!!emailLabel &&
                                    emailLabel.length > 0 &&
                                    emailLabel.map((label: any, index: any) => (
                                      <>
                                        {label.defaultLabels?.count == 0 ? (
                                          " "
                                        ) : (
                                          <optgroup label="Default labels">
                                            {label?.defaultLabels?.rows.map(
                                              (
                                                defaultLabel: any,
                                                defaultIndex: any
                                              ) => {
                                                return (
                                                  <option
                                                    value={
                                                      defaultLabel.labelText
                                                    }
                                                    key={defaultIndex}
                                                  >
                                                    {defaultLabel.labelText}{" "}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </optgroup>
                                        )}
                                        {label.customLabels?.count == 0 ? (
                                          " "
                                        ) : (
                                          <optgroup label="Custom labels">
                                            {label?.customLabels?.rows.map(
                                              (
                                                customLabel: any,
                                                customIndex: any
                                              ) => {
                                                return (
                                                  <option
                                                    value={
                                                      customLabel.labelText
                                                    }
                                                    key={customIndex}
                                                  >
                                                    {customLabel.labelText}{" "}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </optgroup>
                                        )}
                                      </>
                                    ))}
                                  <option value="Add custom Label">
                                    {t("businessRegisterPage.addCustomLabel")}
                                  </option>
                                </Field>
                              </div>
                              <div className="col-md-3 mb-4 d-flex align-items-center gap-2">
                                {index + 1 === elemNamesEmails.length ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      type="button"
                                      disabled={elemNamesEmails.length >= 10}
                                      onClick={handleElemAddEmail} // remove a friend from the list
                                    >
                                      <i className="fas fa-add"></i>
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {elemNamesEmails.length >= 2 ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      type="button"
                                      disabled={elemNamesEmails.length === 1}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setTimeout(() => {
                                          handleElemRemoveEmail(elemNamesEmail);
                                        }, 0);
                                      }} // remove a friend from the list
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          ))}

                        {emailmessage && (
                          <div className="form-group">
                            <div
                              className={
                                successful
                                  ? "alert alert-success"
                                  : "alert alert-danger p-2 rounded-0 border-0"
                              }
                              role="alert"
                            >
                              {emailmessage}
                            </div>
                          </div>
                        )}

                        {!!elemNamesWebsites &&
                          elemNamesWebsites.length > 0 &&
                          elemNamesWebsites.map((elemNamesWebsite, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-6 mb-2 mb-md-4">
                                <div className="form-floating">
                                  <input
                                    className="form-control"
                                    id="inputWebsite"
                                    name="website[]"
                                    type="text"
                                    value={elemNamesWebsite.website}
                                    placeholder={`Website ${index + 1}`}
                                    onChange={(e: any) => {
                                      const value = e.target.value;
                                      if (value === '' || value.startsWith('https://')) {
                                        handleFormChangeWebsites(index, e);
                                      } else {
                                        const withoutHostValue = value.replace('https:/', '');
                                        const prefixedValue = `https://${withoutHostValue}`;
                                        handleFormChangeWebsites(index, {
                                          ...e,
                                          target: { ...e.target, value: prefixedValue },
                                        });
                                      }
                                    }}
                                  />
                                  <label htmlFor="inputWebsite">
                                    {t("businessRegisterPage.lblWebsite")}{" "}
                                    {index + 1}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3 mb-2 mb-md-4">
                                <Field
                                  className="form-control py-3"
                                  value={websiteLastLabel[index]}
                                  as="select"
                                  name="websiteLabel"
                                  onChange={(e: any) => {
                                    handleeWebsiteclick(index, e);
                                  }}
                                >
                                  <option value="">
                                    {t("businessRegisterPage.selectLabel")}
                                  </option>
                                  {!!websiteLabel &&
                                    websiteLabel.length > 0 &&
                                    websiteLabel.map(
                                      (label: any, index: any) => (
                                        <>
                                          {label.defaultLabels?.count == 0 ? (
                                            " "
                                          ) : (
                                            <optgroup label="Default labels">
                                              {label?.defaultLabels?.rows.map(
                                                (
                                                  defaultLabel: any,
                                                  defaultIndex: any
                                                ) => {
                                                  return (
                                                    <option
                                                      value={
                                                        defaultLabel.labelText
                                                      }
                                                      key={defaultIndex}
                                                    >
                                                      {defaultLabel.labelText}{" "}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </optgroup>
                                          )}
                                          {label.customLabels?.count == 0 ? (
                                            " "
                                          ) : (
                                            <optgroup label="Custom labels">
                                              {label?.customLabels?.rows.map(
                                                (
                                                  customLabel: any,
                                                  customIndex: any
                                                ) => {
                                                  return (
                                                    <option
                                                      value={
                                                        customLabel.labelText
                                                      }
                                                      key={customIndex}
                                                    >
                                                      {customLabel.labelText}{" "}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </optgroup>
                                          )}
                                        </>
                                      )
                                    )}
                                  <option value="Add custom Label">
                                    {t("businessRegisterPage.addCustomLabel")}
                                  </option>
                                </Field>
                              </div>
                              <div className="col-md-3 mb-4 d-flex align-items-center gap-2">
                                {index + 1 === elemNamesWebsites.length ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      type="button"
                                      disabled={elemNamesWebsites.length >= 10}
                                      onClick={handleElemAddWebsites} // remove a friend from the list
                                    >
                                      <i className="fas fa-add"></i>
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {elemNamesWebsites.length >= 2 ? (
                                  <>
                                    {" "}
                                    <button
                                      className="btn btn-sm btn-primary"
                                      type="button"
                                      disabled={elemNamesWebsites.length === 1}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setTimeout(() => {
                                          handleElemRemoveWebsites(
                                            elemNamesWebsite
                                          );
                                        }, 0);
                                      }} // remove a friend from the list
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          ))}
                        {websiteMessage && (
                          <div className="w-50">
                            <div
                              className={
                                successful
                                  ? "alert alert-success"
                                  : "alert alert-danger"
                              }
                              role="alert"
                              style={{ padding: 10, marginRight: 10 }}
                            >
                              {websiteMessage}
                            </div>
                          </div>
                        )}
                        {!!elementNameAddress &&
                          elementNameAddress.length > 0 &&
                          elementNameAddress.map(
                            (elementNameAddresses: any, index) => (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-6 mb-3">
                                    <div className="border p-3 rounded position-relative pt-4">
                                      <fieldset>
                                        <legend className="custom-legend">
                                          {t("businessRegisterPage.lblAddress")}{" "}
                                          {index + 1}
                                        </legend>
                                        <div className="row ">
                                          <div className="col-12">
                                            <div className="mb-4">
                                              <div className="form-floating">
                                                <Field
                                                  className="form-control"
                                                  id="Street"
                                                  name="Street"
                                                  type="text"
                                                  placeholder={`Street ${index + 1
                                                    }`}
                                                  value={
                                                    elementNameAddresses.street
                                                  }
                                                  onChange={(e: any) => {
                                                    handleFormChangeSteet(
                                                      index,
                                                      e
                                                    );
                                                  }}
                                                />
                                                <label htmlFor="inputaddress">
                                                  {t(
                                                    "businessRegisterPage.lblStreet"
                                                  )}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-4">
                                              <div className="form-floating">
                                                <Field
                                                  className="form-control"
                                                  id="city"
                                                  name="city"
                                                  type="text"
                                                  placeholder={`city ${index + 1
                                                    }`}
                                                  value={
                                                    elementNameAddresses.city
                                                  }
                                                  onChange={(e: any) => {
                                                    handleFormChangeCity(
                                                      index,
                                                      e
                                                    );
                                                  }}
                                                />
                                                <label htmlFor="inputaddress">
                                                  {t(
                                                    "businessRegisterPage.lblCity"
                                                  )}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-4">
                                              <div className="form-floating">
                                                <Field
                                                  className="form-control"
                                                  id="StateProvince"
                                                  name="StateProvince"
                                                  type="text"
                                                  placeholder={`StateProvince ${index + 1
                                                    }`}
                                                  value={
                                                    elementNameAddresses.stateProvince
                                                  }
                                                  onChange={(e: any) => {
                                                    handleFormChangeState(
                                                      index,
                                                      e
                                                    );
                                                  }}
                                                />
                                                <label htmlFor="inputaddress">
                                                  {t(
                                                    "businessRegisterPage.lblStateProvince"
                                                  )}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-4">
                                              <div className="form-floating">
                                                <Field
                                                  className="form-control"
                                                  id="CountryRegion"
                                                  name="CountryRegion"
                                                  type="text"
                                                  placeholder={`countryRegion ${index + 1
                                                    }`}
                                                  value={
                                                    elementNameAddresses.countryRegion
                                                  }
                                                  onChange={(e: any) => {
                                                    handleFormChangeCountry(
                                                      index,
                                                      e
                                                    );
                                                  }}
                                                />
                                                <label htmlFor="">
                                                  {t(
                                                    "businessRegisterPage.lblCountryRegion"
                                                  )}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="">
                                              <div className="form-floating">
                                                <Field
                                                  className="form-control"
                                                  id="PostalCode"
                                                  name="PostalCode"
                                                  type="text"
                                                  placeholder={`PostalCode ${index + 1
                                                    }`}
                                                  value={
                                                    elementNameAddresses.postalCode
                                                  }
                                                  onChange={(e: any) => {
                                                    handleFormChangePostalCode(
                                                      index,
                                                      e
                                                    );
                                                  }}
                                                />
                                                <label htmlFor="inputaddress">
                                                  {t(
                                                    "businessRegisterPage.lblPostalCode"
                                                  )}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </fieldset>
                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-2 mt-md-4">
                                    <Field
                                      className="form-control py-3"
                                      value={addressLastLabel[index]}
                                      as="select"
                                      name="color"
                                      onChange={(e: any) => {
                                        handleAddressClick(index, e);
                                      }}
                                    >
                                      <option value="">
                                        {t("businessRegisterPage.selectLabel")}
                                      </option>
                                      {!!addressLabel &&
                                        addressLabel.length > 0 &&
                                        addressLabel.map(
                                          (label: any, index: any) => (
                                            <>
                                              {label?.defaultLabels?.count ==
                                                0 ? (
                                                " "
                                              ) : (
                                                <optgroup label="Default labels">
                                                  {label?.defaultLabels?.rows.map(
                                                    (
                                                      defaultLabel: any,
                                                      defaultIndex: any
                                                    ) => {
                                                      return (
                                                        <option
                                                          value={
                                                            defaultLabel.labelText
                                                          }
                                                          key={defaultIndex}
                                                        >
                                                          {
                                                            defaultLabel.labelText
                                                          }{" "}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </optgroup>
                                              )}
                                              {label?.customLabels?.count ==
                                                0 ? (
                                                " "
                                              ) : (
                                                <optgroup label="Custom labels">
                                                  {label?.customLabels?.rows.map(
                                                    (
                                                      customLabel: any,
                                                      customIndex: any
                                                    ) => {
                                                      return (
                                                        <option
                                                          value={
                                                            customLabel.labelText
                                                          }
                                                          key={customIndex}
                                                        >
                                                          {
                                                            customLabel.labelText
                                                          }{" "}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </optgroup>
                                              )}
                                            </>
                                          )
                                        )}
                                      <option value="Add custom Label">
                                        {t(
                                          "businessRegisterPage.addCustomLabel"
                                        )}
                                      </option>
                                    </Field>
                                  </div>
                                  <div className="col-md-3 mb-4 d-flex align-items-start gap-2 ">
                                    {index + 1 === elementNameAddress.length ? (
                                      <>
                                        <button
                                          className="btn btn-sm btn-primary"
                                          type="button"
                                          disabled={
                                            elementNameAddress.length >= 3
                                          }
                                          onClick={handleElemAddAddress}
                                        >
                                          <i className="fas fa-add"></i>
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {elementNameAddress.length >= 2 ? (
                                      <>
                                        <button
                                          className="btn btn-sm btn-primary"
                                          type="button"
                                          disabled={
                                            elementNameAddress.length === 1
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setTimeout(() => {
                                              handleElemRemoveAddress(
                                                elementNameAddresses
                                              );
                                            }, 0);
                                          }} // remove a friend from the list
                                        >
                                          <i className="fas fa-minus"></i>
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </>
                            )
                          )}
                        <div className="form-floating notefield mb-4">
                          <Field
                            className="form-control  h-100"
                            maxLength={512}
                            rows={4}
                            id="inputNote"
                            as="textarea"
                            value={noteData}
                            onChange={(e: any) => noteDataHandler(e)}
                            name="note"
                            placeholder="Note"
                          />
                          <label htmlFor="inputNote">
                            {t("businessRegisterPage.lblNote")}
                          </label>
                          <p className="mt-2">
                            <p className="mt-2">
                              {" "}
                              {t("businessUpdatePage.noteText1")}{" "}
                              {noteData.length}{" "}
                              {t("businessUpdatePage.noteText2")} {totalChar}{" "}
                              {t("businessUpdatePage.noteText3")}{" "}
                            </p>
                          </p>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="lh-sm ">
                            <div className="d-flex ">
                              <label className="">
                                {t("businessRegisterPage.selectFile")}
                              </label>
                            </div>

                            <div className="mt-4 mousePointer">
                              <ImageEditor
                                imgUrl={imgUrl}
                                getImage={getImageHandler}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-floating mb-4 d-none">
                          <Field
                            className="form-control"
                            id="inputQrCodeText"
                            type="text"
                            name="qrCodeText"
                            placeholder="Please add here some text"
                          />
                          <label htmlFor="inputQrCodeText">
                            {t("businessRegisterPage.lblQrCode")}
                          </label>
                          <ErrorMessage
                            name="qrCodeText"
                            component="div"
                            className="text-danger txt-error"
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-between justify-content-md-start gap-3">
                          <Link className="btn btn-light" to="/dashboard">
                            {t("businessRegisterPage.btnClickBack")}
                          </Link>
                          <button
                            className="btn btn-primary btn-block me-1"
                            onClick={submitForm}
                            disabled={disabled}
                          >
                            {t("businessRegisterPage.btnClickSave")}
                          </button>
                        </div>
                      </Form>
                    </Formik>
                    <div
                      className="modal fade"
                      id="custom-field-mobile"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      aria-labelledby="password-join-meetingLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header border-bottom-0 px-2 px-md-4">
                            <h5 className="mb-0">
                              {t("businessRegisterPage.addCustomLabel")}
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-muted f-14"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleClose}
                            ></button>
                          </div>
                          <div className="modal-body px-2 px-md-4">
                            <Formik
                              initialValues={initialValues}
                              onSubmit={handleaddmobilenumberlabel}
                            >
                              <Form id="myform">
                                <div>
                                  <Field
                                    className="form-control mb-2"
                                    id="mobilename"
                                    name="labelText"
                                    type="text"
                                    placeholder="Enter custom Label"
                                  />
                                  <ErrorMessage
                                    name="labelText"
                                    component="div"
                                    className="text-danger txt-error"
                                  />
                                  {labelMessage && (
                                    <div className="form-group">
                                      <div
                                        className={
                                          successful
                                            ? "alert alert-success"
                                            : "alert alert-danger py-2 border-0"
                                        }
                                        role="alert"
                                      >
                                        {labelMessage}
                                      </div>
                                    </div>
                                  )}
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      {t("businessRegisterPage.btnClickAdd")}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal fade"
                      id="custom-field-email"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      aria-labelledby="password-join-meetingLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header border-bottom-0 px-2 px-md-4">
                            <h5 className="mb-0">
                              {t("businessRegisterPage.addCustomLabel")}
                            </h5>

                            <button
                              type="button"
                              className="btn-close text-muted f-14"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleClose}
                            ></button>
                          </div>
                          <div className="modal-body px-2 px-md-4">
                            <Formik
                              initialValues={initialValues}
                              onSubmit={handladdemaillabel}
                            >
                              <Form>
                                <div>
                                  <Field
                                    className="form-control mb-2"
                                    id="emailname"
                                    name="labelText"
                                    type="text"
                                    placeholder="Enter custom Label"
                                  />
                                  <ErrorMessage
                                    name="labelText"
                                    component="div"
                                    className="text-danger txt-error"
                                  />
                                  {labelMessage && (
                                    <div className="form-group">
                                      <div
                                        className={
                                          successful
                                            ? "alert alert-success"
                                            : "alert alert-danger py-2 border-0"
                                        }
                                        role="alert"
                                      >
                                        {labelMessage}
                                      </div>
                                    </div>
                                  )}
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      {t("businessRegisterPage.btnClickAdd")}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal fade"
                      id="custom-field-address"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      aria-labelledby="password-join-meetingLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header border-bottom-0 px-2 px-md-4">
                            <h5 className="mb-0">
                              {t("businessRegisterPage.addCustomLabel")}
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-muted f-14"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleClose}
                            ></button>
                          </div>
                          <div className="modal-body px-2 px-md-4">
                            <Formik
                              initialValues={initialValues}
                              onSubmit={handleaddAddresslabel}
                            >
                              <Form id="myform">
                                <div>
                                  <Field
                                    className="form-control mb-2"
                                    id="addressname"
                                    name="labelText"
                                    type="text"
                                    placeholder="Enter custom Label"
                                  />
                                  <ErrorMessage
                                    name="labelText"
                                    component="div"
                                    className="text-danger txt-error"
                                  />
                                  {labelMessage && (
                                    <div className="form-group">
                                      <div
                                        className={
                                          successful
                                            ? "alert alert-success"
                                            : "alert alert-danger py-2 border-0"
                                        }
                                        role="alert"
                                      >
                                        {labelMessage}
                                      </div>
                                    </div>
                                  )}
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      {t("businessRegisterPage.btnClickAdd")}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal fade"
                      id="custom-field-website"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      aria-labelledby="password-join-meetingLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header border-bottom-0 px-2 px-md-4">
                            <h5 className="mb-0">
                              {t("businessRegisterPage.addCustomLabel")}
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-muted f-14"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleClose}
                            ></button>
                          </div>
                          <div className="modal-body px-2 px-md-4">
                            <Formik
                              initialValues={initialValues}
                              onSubmit={handleaddWebsitelabel}
                            >
                              <Form id="myform">
                                <div>
                                  <Field
                                    className="form-control mb-2"
                                    id="websiteName"
                                    name="labelText"
                                    type="text"
                                    placeholder="Enter custom Label"
                                  />
                                  <ErrorMessage
                                    name="labelText"
                                    component="div"
                                    className="text-danger txt-error"
                                  />
                                  {labelMessage && (
                                    <div className="form-group">
                                      <div
                                        className={
                                          successful
                                            ? "alert alert-success"
                                            : "alert alert-danger py-2 border-0"
                                        }
                                        role="alert"
                                      >
                                        {labelMessage}
                                      </div>
                                    </div>
                                  )}
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      {t("businessRegisterPage.btnClickAdd")}
                                    </button>
                                  </div>
                                </div>

                              </Form>
                            </Formik>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default BusinessRegisterPage;
