import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { businessData } from "../../common/interface";
import { getBusinessData } from "../../services/business.service";
import * as actions from "../actions/index";

const initialState: businessData = {
  businessData: [],
  businessDataCount: "",
  loading: false,
};

export const businessListHandler: any = createAsyncThunk(
  actions.BUSINESSLIST,
  (data: any) => getBusinessData(data)
);

const businessSlice = createSlice({
  name: "businessSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [businessListHandler.pending]: (state: any) => {
      state.loading = true;
    },
    [businessListHandler.fulfilled]: (state: any, action) => {
      state.businessData = action.payload.data.businesses.rows;
      state.businessDataCount = action.payload.data.businesses.count;
      state.loading = false;
    },
    [businessListHandler.rejected]: (state: any) => {
      state.isLoading = false;
    },
  },
});

export default businessSlice.reducer;