import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL

export const getBusinessData = async ({pageSize, perPage, searchVal}:any) => {
    let newHeader = {
        ...authHeader(),
    }
    let config = {
        headers: newHeader,
        params: {
            page: pageSize,
            perPage: perPage,
            searchVal: searchVal
        },
    }
    return axios.get(API_URL + "businesses", config)
        .then((response) => {
            return response.data;
        });
};
export const getAllBusinessData = async () => {
    let newHeader = {
        ...authHeader(),
    }
    let config = {
        headers: newHeader,
    }
    return axios.get(API_URL + "allbusinesses", config)
        .then((response) => {
            return response.data;
        });
};

export const getEmailLabelData = async () => {
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "custom/labels?labelType=email",
        method: "get",
        headers: newHeader
    })
}

export const getMobileLabelData = async () => {
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "custom/labels?labelType=mobile",
        method: "get",
        headers: newHeader
    })
}
export const getAddressLabelData = async () => {
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "custom/labels?labelType=address",
        method: "get",
        headers: newHeader
    })
}
export const getWebsiteLabelData = async () => {
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "custom/labels?labelType=website",
        method: "get",
        headers: newHeader
    })
}
export const addBusinessData = async (bodyData: any ,imageFile: any, mobileNumbers: any[], emails: any[], websites: any[], addresses:any[], isIndividual: string, followUpBusinessId: string,noteData:any) => {
    
    const formData = new FormData()
    formData.append('firstName', bodyData.firstName)
    formData.append('lastName', bodyData.lastName)
    if (!!mobileNumbers && mobileNumbers.length > 0) {
        for (const mobileNumberObj of mobileNumbers) {
            formData.append('mobileNumber[]', JSON.stringify({ phone: mobileNumberObj.mobileNumber, label: mobileNumberObj.numberTitle }))
        }

    }
    if (!!emails && emails.length > 0) {
        for (const emailObj of emails) {
            formData.append('email[]', JSON.stringify({ email: emailObj.email, label: emailObj.emailLabel }))

        }
    }
    if (!!websites && websites.length > 0) {
        for (const websiteObj of websites) {
            formData.append('website[]', JSON.stringify({website:websiteObj.website, label:websiteObj.websiteLabel}))
        }
    }
    if (!!addresses && addresses.length > 0) {
        for (const addressObj of addresses) {
            formData.append('address[]', JSON.stringify({ label: addressObj.addressLabel, street : addressObj.street, city: addressObj.city, stateProvince :addressObj.stateProvince, postalCode: addressObj.postalCode, countryRegion:addressObj.countryRegion}))
        }
    }
    formData.append('isIndividual', isIndividual)
    formData.append('followUpBusinessId', followUpBusinessId)
    formData.append('title', bodyData.title)
    formData.append('organization', bodyData.organization)
    formData.append('note', noteData)
    formData.append('qrCodeText', bodyData.qrCodeText)
    formData.append('brandPic', imageFile)
    let newHeader = {
        ...authHeader(),
    }
    let config = {
        headers: newHeader,
    }

    return axios.post(API_URL + "business/register", formData, config)
        .then((response) => {
            return response.data;
        });
};
export const addTypeLabel = async (labelType: any, labelText: any) => {
    let data = {
        labelType,
        labelText
    }
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "custom/label/add",
        method: "post",
        data: data,
        headers: newHeader
    })
}
export const deleteBusinessData = async (businessRfid: any) => {
    let data = {
        businessRfid
    }
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "business/delete",
        method: "Delete",
        data: data,
        headers: newHeader
    }).then((response) => {
        return (response.data.data);
    })

}

export const businessGetBybusinessRef = async (businessRfid: string) => {
    let data = {
        businessRfid
    }
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "business/details?businessRfid=" + businessRfid,
        method: "Get",
        data: data,
        headers: newHeader
    })
};

export const businessList = async () => {
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "business/base",
        method: "Get",
        headers: newHeader
    })
}

export const getDashboardAnalyticsData = async () => {
    let newHeader = {
        ...authHeader(),
    }
    let config = { headers: newHeader }
    return axios.get(API_URL + "dashboard", config)
        .then((response) => {
            return (response.data.data);
        });
};

export const businessUpdate = async (bodyData: any, imageFile: any, isImageChanged: any, mobileNumbers: any[], emails: any[], websites: any[],address:any[], isIndividual: string, followUpBusinessId: string,noteData:any) => {
    let formData = new FormData();
    formData.append('businessRfid', bodyData.businessRef)
    formData.append('firstName', bodyData.firstName)
    formData.append('lastName', bodyData.lastName)
    formData.append('title', bodyData.title)
    formData.append('isImageChanged', isImageChanged);
    if (!!mobileNumbers && mobileNumbers.length > 0) {
        for (const mobileNumberObj of mobileNumbers) {
            formData.append('mobileNumber[]', JSON.stringify({ phone: mobileNumberObj.userGivenMobileNumber, label: mobileNumberObj.mobileNumberLabel }))
        }
    }
    if (!!emails && emails.length > 0) {
        for (const emailObj of emails) {
            formData.append('email[]', JSON.stringify({ email: emailObj.userGivenEmailAddress, label: emailObj.emailAddressLabel }))
        }
    }
    if (!!websites && websites.length > 0) {
        for (const websiteObj of websites) {
            formData.append('website[]', JSON.stringify({website: websiteObj.websiteAddress,label:websiteObj.websiteLabel }))
        }
    }
    if (!!address && address.length > 0) {
        for (const addressObj of address) {
            formData.append('address[]', JSON.stringify({ label: addressObj.label, street : addressObj.street, city: addressObj.city, stateProvince :addressObj.stateProvince, postalCode: addressObj.postalCode, countryRegion:addressObj.countryRegion}))
        }
    }
    formData.append('organization', bodyData.organization)
    formData.append('brandPic', imageFile)
    formData.append('note', noteData)
    formData.append('qrCodeText', bodyData.qrCodeText)
    formData.append('isIndividual', isIndividual)
    formData.append('followUpBusinessId', followUpBusinessId)

    let newHeader = {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
    }

    return axios({
        url: API_URL + "business/update",
        method: "put",
        headers: newHeader,
        data: formData
    })
};

export const deleteImage = async (id:any) => {
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "business/brandpic/delete",
        method: "delete",
        headers: newHeader,
        data: id
    })
};

