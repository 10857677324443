/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
import React, { FC, useEffect, useState } from "react";
import {
  DatatableWrapper,
  TableColumnType,
  TableBody,
  TableHeader,
  PaginationOptions,
} from "react-bs-datatable";
import { Table } from "react-bootstrap";
import {
  deleteBusinessData,
  getDashboardAnalyticsData,
  businessGetBybusinessRef
} from "../services/business.service";
import DeleteDialogBox from "../common/DeleteDialogBox";
import PaginationBox from "../components/pagination";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { businessListHandler } from '../store/slicers/businessSlice';
import { useSelector } from 'react-redux';
import * as bootstrap from 'bootstrap';

interface DashboardPageProps {
  title: string;
}

const DashboardPage: FC<DashboardPageProps> = ({ }) => {
  const { t } = useTranslation("translate");
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let businessData = useSelector((state: any) => state?.business?.businessData)
  let businessDataCount = useSelector((state: any) => state?.business?.businessDataCount)

  const [businessesList, setBusinessesData] = useState<any>([]);
  const [dashboardAnalytics, setDashboardAnalyticsData] = useState<any>([]);
  const [pageSize, setpageSize] = useState(1); // Page number
  const [perPage, setperPage] = useState(10); // per page Limit
  const [totalRecord, setTotalRecord] = useState<any>(); // Total Record
  const [filterValue, setFilterValue] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [businessDeleteID, setBusinessDeleteID] = useState();
  const pageLimitOptions = [10, 15, 20];
  const [endItem, setendItem] = useState(0);
  const [startItem, setStartItem] = useState(1);
  const [totalPage, setTotalPage] = useState<any>();
  const [businessAddress, setBusinessAddress] = useState<any>([]);

  let body = businessesList;

  let analytics = dashboardAnalytics;
  type ArrayElementType = typeof body[number] & {
    button: any;
  };

  useEffect(() => {
  }, [businessesList])
  const header: TableColumnType<ArrayElementType>[] = [
    {

      title: "Organization",
      prop: "Organization",
      cell: (row) => {
        return (
          <div className="text-nowrap">
            <div className="">{row.organization}</div>
          </div>
        );
      },
    },
    {
      title: "First Name",
      prop: "firstName",
      cell: (row) => {
        return (
          <>
            <div>
              <span className="me-2">
                {!row.firstName ? "-" : row.firstName}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Last Name",
      prop: "lastName",
      isFilterable: true,
      cell: (row) => {
        return (
          <>
            <div>
              <span className="me-2">
                {!row.lastName ? "-" : row.lastName}
              </span>
            </div>
          </>
        );
      },
    },

    {
      title: "Title",
      prop: "title",
      cell: (row) => {
        return (
          <div className="text-nowrap">
            <div className="">{!row.title ? "-" : row.title}</div>
          </div>
        );
      },
    },
    {
      title: "Address",
      prop: "address",
      cell: (row) => {
        return (
          <div className="text-nowrap">
            <>
              {
                (row.BusinessAddresses?.length < 1 ?
                  "-"
                  :
                  <>
                    {
                      (row.BusinessAddresses?.length == 1 ?
                        <>
                          {`${row.BusinessAddresses[0]?.street}, ${row.BusinessAddresses[0]?.postalCode}, ${row.BusinessAddresses[0]?.city}, ${row.BusinessAddresses[0]?.stateProvince}, ${row.BusinessAddresses[0]?.countryRegion} `}
                        </>
                        :
                        <>
                          {`${row.BusinessAddresses[0]?.street}, ${row.BusinessAddresses[0]?.postalCode}, ${row.BusinessAddresses[0]?.city}, ${row.BusinessAddresses[0]?.stateProvince},  ${row.BusinessAddresses[0]?.countryRegion} `}
                          <Link onClick={() => addressData(row.businessRef)} to="#">{t("dashboardPage.lblReadMore")}</Link>
                        </>
                      )
                    }
                  </>
                )
              }
            </>
          </div>
        );
      },
    },
    {
      title: "Actions",
      prop: "",
      cell: (row) => {
        return (
          <>
            <div className="d-flex gap-4">
              <button
                onClick={() => viewHandler(row.businessRef)}
                className="btn btn-primary-light p-0 size-38"
              >
                <i className="fa-solid fa-eye text-primary"></i>
              </button>
              <button
                onClick={(e) => {
                  businessEdit(row.businessRef);
                }}
                className="btn btn-primary-light p-0 size-38"
              >
                <i className="fa-solid fa-pen text-success"></i>
              </button>
              <button
                onClick={() => deleteConfirmHandler(row.businessRef)}
                className="btn btn-primary-light p-0 size-38"
              >
                <i className="fa-solid fa-trash text-danger"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];
  const getDashboardAnalytics = async () => {
    const dashboardAnalytics = await getDashboardAnalyticsData();

    setDashboardAnalyticsData(dashboardAnalytics);
  };

  const getBusiness = async (pageLimit: number, perPage: number, searchVal: string) => {
    const payload = {
      pageSize: pageLimit,
      perPage: perPage,
      searchVal: searchVal
    };
    dispatch(businessListHandler(payload))
  };

  const deleteConfirmHandler = (businessRef: any) => {
    setShowDelete(true);
    setBusinessDeleteID(businessRef);
  };

  const viewHandler = (businessRef: any) => {
    navigate("/business-view/" + businessRef);
  };
  const addressData = async (businessRef: string) => {
    const businessesDataList = await businessGetBybusinessRef(businessRef)
    setBusinessAddress(businessesDataList.data.data.business)
    new bootstrap.Modal(document.getElementById('addressModal') as HTMLElement).show();

  };

  const businessEdit = (businessRef: any) => {
    navigate("/business-update/" + businessRef);
  };

  const deleteBusinessHandler = async (businessRef: any) => {
    await deleteBusinessData(businessRef).then(
      (response) => {
        getBusiness(pageSize, perPage, filterValue);
        toast.success("Business deleted sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        if(totalRecord - perPage === 1){
          setpageSize(pageSize-1);
          setStartItem(1);
          if(0 == totalRecord) {
              setStartItem(0);
          }
      }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    );
  };

  const handlePrevPage = (e: number) => {
    setpageSize((e));
    setStartItem((startItem - perPage))
    totalrecordHandle()
  };

  const handleNextPage = (e: number) => {
    setpageSize((e));
    setStartItem(endItem + 1)
    totalrecordHandle()
  };
  const handlePageClick = (e: any) => {
    setpageSize(e);
    setStartItem(((e * perPage) + 1) - perPage)
    totalrecordHandle()
  }

  useEffect(() => {
    getBusiness(pageSize, perPage, filterValue);
    getDashboardAnalytics();

  }, [perPage, pageSize, filterValue]);

  const totalrecordHandle = () => {
    var totalRecordTemp = startItem - 1 + perPage;
    if (totalRecordTemp > totalRecord) {
      totalRecordTemp = totalRecord;
    }
    setendItem(totalRecordTemp);
  };

  useEffect(() => {
    totalrecordHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startItem, endItem, totalRecord, perPage]);
  useEffect(() => {
    setpageSize(1);
    setStartItem(1);
    setendItem(perPage);
  }, [perPage]);

  useEffect(() => {
    setBusinessesData(businessData);
    let totalPageNo = businessDataCount / perPage;
    let roundNu = Math.ceil(totalPageNo);
    setTotalPage(roundNu);
  }, [businessData])

  useEffect(() => {
    setTotalRecord(businessDataCount);
    if (businessDataCount === 0) {
      setTotalPage(0)
      setStartItem(0)
      setendItem(0)
      return
    }
    let totalPageNo = businessDataCount / perPage;
    let roundNu = Math.ceil(totalPageNo);
    setTotalPage(roundNu);
  }, [businessDataCount])
  return (
    <>
      <main>
        <div className="container-fluid px-lg-4">
          <h3 className="mt-3 mb-4">{t("dashboardPage.lblDashboard")}</h3>
          <div className="row">
            <div className="col-xl-3 col-md-6 h-100">
              <div className="card border-0 bg-primary text-white mb-4">
                <div className="card-body">
                  <div className="text-white">
                    {t("dashboardPage.messages.messageSentToday")}
                  </div>
                  <div className="text-xl fw-bold">
                    <h4>{analytics.todayMmsSentLogCount}</h4>
                  </div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <Link
                    className="small text-white stretched-link text-decoration-none"
                    to="/logList/?day=today"
                  >
                    {t("dashboardPage.messages.messageViewDetails")}
                  </Link>
                  <div className="small text-white">
                    <i className="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 h-100">
              <div className="card border-0 bg-warning text-white mb-4">
                <div className="card-body">
                  <div className="text-white">
                    {t("dashboardPage.messages.messageSentTotal")}
                  </div>
                  <div className="text-xl fw-bold">
                    <h4>{analytics.totalMmsPendingDeliveredLogCount}</h4>
                  </div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <Link
                    className="small text-white stretched-link text-decoration-none"
                    to="/logList"
                  >
                    {t("dashboardPage.messages.messageViewDetails")}
                  </Link>
                  <div className="small text-white">
                    <i className="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 h-100">
              <div className="card border-0 bg-success text-white mb-4">
                <div className="card-body">
                  <div className="text-white">
                    {t("dashboardPage.messages.messageDeliverTotal")}
                  </div>
                  <div className="text-xl fw-bold">
                    <h4>{analytics.totalMmsDeliveredLogCount}</h4>
                  </div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <Link
                    className="small text-white stretched-link text-decoration-none"
                    to="/logList/?status=delivered"
                  >
                    {t("dashboardPage.messages.messageViewDetails")}
                  </Link>
                  <div className="small text-white">
                    <i className="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 h-100">
              <div className="card border-0 bg-danger text-white mb-4">
                <div className="card-body">
                  <div className="text-white">
                    {t("dashboardPage.messages.messageUnDeliverTotal")}
                  </div>
                  <div className="text-xl fw-bold">
                    <h4>{analytics.totalMmsUndeliveredLogCount}</h4>
                  </div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <Link
                    className="small text-white stretched-link text-decoration-none"
                    to="/logList/?status=undelivered"
                  >
                    {t("dashboardPage.messages.messageViewDetails")}
                  </Link>
                  <div className="small text-white">
                    <i className="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mt-4 mb-3 col-12 d-flex align-items-center justify-content-between flex-wrap gap-2">
              <h4 className="mb-0">
                {t("dashboardPage.table.lblBusinessList")}
              </h4>
              <div>
                <Link
                  className="btn btn-sm btn-primary text-decoration-none"
                  to="/business-register"
                >
                  <i className="fas fa-add me-1"></i>

                  {t("dashboardPage.table.lblRegister")}
                </Link>
              </div>
            </div>
            <div className="col-12">
              <div className="card card-border mb-4">
                <div className="card-body">
                  <DatatableWrapper body={body} headers={header}>
                    <div className="mb-2 d-flex justify-content-end">
                      <PaginationOptions
                        controlledProps={{
                          filteredDataLength: perPage,
                          onRowsPerPageChange: setperPage,
                          rowsPerPageOptions: pageLimitOptions,
                          rowsPerPage: perPage,
                        }}
                      />
                    </div>
                    <Table responsive borderless striped>
                      <TableHeader />
                      <TableBody />
                    </Table>
                    <div className="pt-3 border-top d-flex justify-content-between align-items-center gap-3 flex-wrap">
                      <PaginationBox
                        filteredDataLength={perPage}
                        startItem={startItem}
                        totalPage={totalPage}
                        endItem={endItem}
                        currentPage={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        handlePageClick={handlePageClick}
                        rowsPerPageOptions={pageLimitOptions}
                        rowsPerPage={perPage}
                        totalRecord={totalRecord}
                      />
                    </div>
                  </DatatableWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="modal fade" id="addressModal" aria-labelledby="textMessageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="textMessageModalLabel">{t("dashboardPage.viewAddresses.lblAddress")}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div className="modal-body ">
              <div className="mb-5 mt-3">
                <>
                  {!!businessAddress.BusinessAddresses &&
                    businessAddress.BusinessAddresses?.length > 0 &&
                    businessAddress.BusinessAddresses.map((addressValue: any, index: any) => {

                      return (
                        <div className="mb-5">
                          <table className="table border" key={index}>
                            <tbody>
                              <>
                                <tr>
                                  <td width="40%">
                                    <p className="mb-0">{t("dashboardPage.viewAddresses.lblAddressType")}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{addressValue.label}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="40%">
                                    <p className="mb-0">{t("dashboardPage.viewAddresses.lblStreet")}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{addressValue.street}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="40%">
                                    <p className="mb-0">{t("dashboardPage.viewAddresses.lblPostalCode")}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{addressValue.postalCode}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="40%">
                                    <p className="mb-0">{t("dashboardPage.viewAddresses.lblCity")}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{addressValue.city}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="40%">
                                    <p className="mb-0">{t("dashboardPage.viewAddresses.lblStateProvince")}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{addressValue.stateProvince}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="40%">
                                    <p className="mb-0">{t("dashboardPage.viewAddresses.lblCountryRegion")}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{addressValue.countryRegion}</p>
                                  </td>
                                </tr>

                              </>

                            </tbody>
                          </table>
                        </div>
                      )
                    })}
                </>
              </div>
            </div>
          </div>
        </div>
      </div >
      <DeleteDialogBox
        show={showDelete}
        clickOk={() => {
          deleteBusinessHandler(businessDeleteID);
          setShowDelete(false);
        }}
        clickCancel={() => {
          setShowDelete(false);
        }}
        color={"btn-danger"}
        btncancel={"Cancel"}
        btnyes={"Delete"}
        question={"Are you sure you want to delete this business info?"}
      />
    </>
  );
};

export default DashboardPage;
