import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import DefaultHeader from './layouts/defaultHeader';
import DefaultSideNav from './layouts/defaultSideNav';
import DashboardPage from './pages/dashboard.page';
import LoginPage from './pages/login.page';
import { IUser } from './types/user.type';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthVerify from './common/authVerify';
import BusinessRegisterPage from './pages/businessRegister.page';
import BusinessUpdatePage from './pages/businessUpdate.page';
import BusinessViewPage from './pages/businessView.page';
import Settingpage from './pages/setting.page';
import DeliverLogListPage from './pages/logList.page';
import ChangePasswordPage from './pages/changePassword.page';
import ApiGenerator from './pages/apiKeyPage';
import ForgotPasswordPage from './pages/forgotPasswordPage';
import ResetPasswordPage from './pages/resetPasswordPage';
import UserRegisterPage from './pages/userRegister.page';
import ProfilePage from './pages/profilePage';
import QrCustomizePage from './pages/qrCustomize.page';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { refreshHandler } from "./store/slicers/authSlicer";
import EmailVerification from './pages/emailVerification';

function App() {
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);
  const authStatus: any = useSelector((state: any) => state.auth.isAuth);
  const loaderState: any = useSelector((state: any) => state.common.showLoader);
  let data: any = localStorage.getItem("user");
  let parsedData = data ? JSON.parse(data) : {};

  let dispatch = useDispatch();

  useEffect(() => {
    if (data || authStatus) {
      setCurrentUser(parsedData);
      return;
    }
    setCurrentUser(undefined);
  }, [authStatus]);

  useEffect(() => {
    if (data) {
      dispatch(refreshHandler(parsedData));
    }
  }, []);

  if (!currentUser) {
    return (
      <>
        {loaderState && (<div className="loading"></div>)}
        <div className='bg-dark'>
          <ToastContainer />
          <Router>
            <div id="layoutAuthentication" className='justify-content-center'>
              <Routes>
                <Route path="*" caseSensitive={false} element={<LoginPage title="Login Page" />} />
                <Route path="/login" caseSensitive={false} element={<LoginPage title="Login Page" />} />
                <Route path="/forgot-password" caseSensitive={false} element={<ForgotPasswordPage title='forgotPasswordPage' />} />
                <Route path="/reset-password/:token" caseSensitive={false} element={<ResetPasswordPage title="Forgot password Reset page" />} />
                <Route path='/register' caseSensitive={false} element={<UserRegisterPage title='Registration Page' />} />
                <Route path='/verify/:token' caseSensitive={false} element={<EmailVerification />} />
              </Routes>
            </div>
          </Router>
        </div>
      </>
    )
  } else {
    return (
      <>
        {loaderState && (<div className="loading"></div>)}
        <div className="sb-nav-fixed">
          <ToastContainer />
          <Router>
            <DefaultHeader title=''></DefaultHeader>
            <div id="layoutSidenav">
              <DefaultSideNav title=''></DefaultSideNav>
              <div id="layoutSidenav_content">
                <Routes>
                  <Route path="*" caseSensitive={false} element={<DashboardPage title="Dashboard Page" />} />
                  <Route path="/apikey" caseSensitive={false} element={<ApiGenerator title="API Generator Page" />} />
                  <Route path="/dashboard" caseSensitive={false} element={<DashboardPage title="Dashboard Page" />} />
                  <Route path="/api-key-page" caseSensitive={false} element={<ApiGenerator title="Dashboard Page" />} />
                  <Route path="/business-register" caseSensitive={false} element={<BusinessRegisterPage title="business registration Page" />} />
                  <Route path="/business-update/:businessRef" caseSensitive={false} element={<BusinessUpdatePage title="business update Page" />} />
                  <Route path="/business-view/:businessRef" caseSensitive={false} element={<BusinessViewPage title="business view Page" />} />
                  <Route path='/qrcustomize-page/:id/:businessRef?' caseSensitive={false} element={<QrCustomizePage title="qr customize page" />} />
                  <Route path='/setting-page' caseSensitive={false} element={<Settingpage title='setting page' />} />
                  <Route path='/change-password' caseSensitive={false} element={<ChangePasswordPage title='changePasswordPage' />} />
                  <Route path='/profile-page' caseSensitive={false} element={<ProfilePage title='profile page' />} />
                  <Route path="/logList/:dayOrStatus?" caseSensitive={false} element={<DeliverLogListPage title="LogList Page" />} />
                  {/* <Route path="/logList/:dayOrStatus"><Route path=":day" element={<DeliverLogListPage title="LogList Page" />} /><Route path=":status" element={<DeliverLogListPage title="LogList Page" />} /></Route> */}
                </Routes>
              </div>
            </div>
            <AuthVerify title='' />
          </Router>

        </div>
      </>
    )
  }

}

export default App;
