import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL;
const TENANT_REF = process.env.REACT_APP_TENANT_REF ?? '';

export const register = async (firstName: any, lastName: any, loginEmail: any,
    password: any, organizationName: any, mobileNumber: any, contactEmail: any,organizationLogo:any,profilePic:any) => {
        let bodyData=new FormData()
        bodyData.append("tenantRef", TENANT_REF)
        bodyData.append("firstName",firstName)
        bodyData.append("lastName",lastName)
        bodyData.append("loginEmail",loginEmail)
        bodyData.append("password",password)
        bodyData.append("organizationName",organizationName)
        bodyData.append("mobileNumber",mobileNumber)
        bodyData.append("contactEmail",contactEmail)
        bodyData.append("profilePic",profilePic)
        bodyData.append("organizationLogo",organizationLogo)
    return axios
        .post(API_URL + "pub/register", bodyData)
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        })

}
export const userProfile = async () => {
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "/me",
        method: "Get",
        headers: newHeader
        
    })
};
export const profileUpdate = async (firstName: any, lastName: any, profilePic:any, isImageChanged: any) => {
    const bodyData= new FormData();
    bodyData.append("firstName",firstName);
    bodyData.append("lastName",lastName);
    bodyData.append("profilePic",profilePic);
    bodyData.append("isImageChanged", isImageChanged);
   
    let newHeader = {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
    }
   
    return axios({
        url: API_URL + "me/update",
        method: "Put",
        data: bodyData,
        headers: newHeader
    });
};
export const createKeyName = async (keyName: any) => {
    let newHeader = {
        ...authHeader(),
    }
    let config = {
        headers: newHeader,
    }
    return axios
        .post(API_URL + "client/register", {
            keyName
        }, config)
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        })
        .catch((error) => {
            console.log(error, "error")
        })

}
export const getClientIdData = async (pageSize: any, perPage: any, searchVal: any) => {
    let newHeader = {
        ...authHeader(),
    }
    let config = {
        headers: newHeader,
        params: {
            page: pageSize,
            perPage: perPage,
            searchVal: searchVal
        },
    }
    return axios
        .get(API_URL + "/client/list", config)
        .then((response) => {
            if (response.data.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data.data))
            }
            return response.data;
        })
        .catch((error) => {
            console.log(error, "error")
        })
};

export const deletClientData = async (clientRef: any) => {
    let data = {
        clientRef
    }
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "client/delete",
        method: "Delete",
        data: data,
        headers: newHeader
    })

}
