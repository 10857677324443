import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import { toast } from "react-toastify";



export const ImageEditor = ({ getImage, img, imgUrl }:any) => {
  const [fileList, setFileList] = useState<any>([
  ]);

  const onChange = (event: any) => {
      if(event?.file?.status !== "removed"){
        if(!event?.file.type.startsWith('image/')){
          toast.error("You can only upload image files!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        else if(event?.fileList[0]?.size <= 5 * 1024 * 1024){
          setFileList(event?.fileList)
          getImage(fileList[0])
          if(event?.fileList[0]){
          }
        }else{
          toast.error("Image size should be up to 5MB.", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
        }
      }else{
        setFileList(event?.fileList)
        getImage(fileList[0])
      }
   
  }
  useEffect(()=>{
    if(img){
        let obj = {
            uid:"1",
            url:img
        }
        setFileList([obj])
    }
  },[img])
 

  return (
    <ImgCrop showGrid rotationSlider aspectSlider showReset>
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        // onPreview={onPreview}
      >
        {fileList.length <1 && '+ Upload'}
      </Upload>
    </ImgCrop>
  );
};
