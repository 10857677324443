import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import tEn from "./translations/en/translate.json";
import tDe from "./translations/de/translate.json";
import { Provider } from 'react-redux';
import store from './store/store';

i18next.init({
  resources: {
    en: {
      translate: tEn               // 'translate' is our custom namespace
    },
    de: {
      translate: tDe
    },
  },
  interpolation: { escapeValue: false }, // React already does escaping
  lng: window.localStorage.language,
  fallbackLng: "en",
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
