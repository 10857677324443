import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;
const TENANT_REF = process.env.REACT_APP_TENANT_REF;

export const logout = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("currentTenant")

    sessionStorage.clear()
};

export const getCurrentUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
};

export const login = async ({ email, password }: any) => {
    try {
        const response = await axios.post(API_URL + "pub/login", {
            email,
            password,
        });

        if (response.data.data.token) {
            localStorage.setItem("currentTenant", response.data.data.userData.tenantData[0]?.tenantRef);
            localStorage.setItem("user", JSON.stringify(response.data.data));
        }

        return response.data;
    } catch (error: any) {
        // Handle error
        if (error.response) {
            console.error("Request failed with status code:", error.response.status);
            console.error("Response data:", error.response.data);
        } else if (error.request) {
            console.error("No response received:", error.request);
        } else {
            console.error("Error setting up the request:", error.message);
        }
        throw error;
    }
};

export const verifyEmail = async (token: string) => {
    const response = await axios.post(API_URL + 'pub/email-verify', { token });
    return response.data;
};

export const loginWithGoogle = async (data: any) => {
    return axios
        .post(API_URL + "pub/sso/google", {
            tenantRef: TENANT_REF,
            data: data
        })
        .then((response) => {
            if (response.data.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }
            return response.data.data;
        });
};

export const loginWithMicrosoft = async (data: any) => {
    return axios
        .post(API_URL + "pub/sso/microsoft", {
            tenantRef: TENANT_REF,
            data: data
        })
        .then((response) => {
            if (response.data.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }
            return response.data.data;
        });
};

export const allSettings = async () => {
    let newHeader = {
        ...authHeader(),
    }
    let config = {
        headers: newHeader,
    }
    return axios
        .get(API_URL + "settings", config)
        .then((response) => {

            if (response.data.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data.data))
            }
            return response.data;
        })
        .catch((error) => {

        })
};

export const allSettingUpdate = async (settingRef: any, value: any) => {
    let data = {
        settingRef,
        value
    }
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "setting/update",
        method: "Put",
        data: data,
        headers: newHeader
    })
};

export const changePassword = async ({ id, oldPassword, newPassword, confirmNewPassword }: { id: any, oldPassword: string, newPassword: string, confirmNewPassword: string }, { rejectWithValue }: any) => {
    let data = { id, oldPassword, newPassword, confirmNewPassword }
    let newHeader = {
        ...authHeader(),
    }
    const resp: any = axios
        .post(API_URL + "change-password", data, {
            headers: newHeader
        })
        .then((response) => {
            return response
        })
        .catch((error) => {
            return rejectWithValue(error.response.data)
        });
    return resp
}

export const forgotPassword = async (email: string) => {
    return axios
        .post(API_URL + "pub/forgot-password", {
            email
        })
        .then((res) => {
            return res.data;
        });
};

export const resetPassword = async (userRef: any, password: string, expireTime: any) => {
    let data = { userRef, password, expireTime }
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "pub/reset-password",
        method: "Post",
        headers: newHeader,
        data: data
    })
}

