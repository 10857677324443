
import React, { FC, } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IUserForgotPassword } from '../types/user.type';

import { toast } from 'react-toastify';
import { forgotPassword } from '../services/auth.service';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ForgotPasswordPageProps {
    title: string;
}

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = ({ title }) => {
    const { t } = useTranslation("translate");
    let navigate = useNavigate();
    const initialValues: IUserForgotPassword = {
        email: ""
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('This field is required!')
    });

    const handleEmail = (formValue: { email: any }) => {
        const { email } = formValue;
        forgotPassword(email).then(
            (response) => {
                toast.success('link send on your mail successfully, Email is Valid!')
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                navigate("/login");
            },
            (error: { response: { data: { message: any; }; }; message: any; toString: () => any; }) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                toast.error('Email is not exist')
            }
        );
    };
    // const handleback = () => {
    //     navigate("/")
    // }
    return (
        <>
            <main>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="card shadow border-0 mb-5">
                                <div className="card-body p-4">
                                    <div className='d-flex justify-content-center mb-4  gap-5 align-items-center'>
                                        <h4 className="text-center ml-4 fw-semibold">{t("forgotPassword.lblForgotPassword")}</h4>
                                    </div>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={handleEmail}
                                        validationSchema={validationSchema}
                                    >

                                        <Form>
                                            <div className="form-floating mb-4">
                                                <Field className="form-control" id="inputEmail" name="email" type="email" placeholder="name@example.com" />
                                                <label htmlFor="inputEmail">{t("forgotPassword.lblEmail")}</label>
                                                <ErrorMessage name="email" component="div" className="text-danger txt-error position-absolute" />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <button className="btn btn-light" type="button" onClick={() => navigate("/")}>{t("forgotPassword.btnClickBack")}</button>
                                                <button className="btn btn-primary" type="submit">{t("forgotPassword.btnClickResetPassword")}</button>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )

}

export default ForgotPasswordPage;