/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { changePassword } from "../services/auth.service";
import { IUserChangePassword } from "../types/user.type";
import * as AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changePasswordHandler } from "../store/slicers/authSlicer";
import * as actions from "../store/actions/index";
import YupPassword from 'yup-password';
YupPassword(Yup);
interface changePasswordPageProps {
    title: string
}

const initialValues: IUserChangePassword = {
    id: "",
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
}
const user = AuthService.getCurrentUser();

const ChangePasswordPage: FC<changePasswordPageProps> = ({ title }) => {
    const { t } = useTranslation("translate");
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const [message, setMessage] = useState<string>("");
    const [succesful, setSuccessful] = useState<boolean>(false);

    const [passwordType, setPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [confirmNewPasswordType, setConfirmNewPasswordType] = useState("password")

    const handleChangePassword = (formValue: { id: any, oldPassword: string, newPassword: string, confirmNewPassword: string }) => {
        const { id, newPassword, oldPassword, confirmNewPassword } = formValue;
        const payload = {
            id: user.userData.userRef,
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        };
        dispatch(changePasswordHandler(payload)).then((data: any) => {
            if (data.type === `${actions.CHANGEPASSWORD}/${actions.REJECTED}`) {
                setSuccessful(false);
                setMessage(data.payload.errorMessage)
                return;
            }
            toast.success("Password Changed SuccessFully!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10,
            });
            navigate("/dashboard");
        }
        )
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType('password')
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const toggleNewPassword = () => {
        if (newPasswordType === "password") {
            setNewPasswordType("text")
            return;
        }
        setNewPasswordType('password')
    }
    const toggleConfirmNewPassword = () => {
        if (confirmNewPasswordType === "password") {
            setConfirmNewPasswordType("text")
            return;
        }
        setConfirmNewPasswordType('password')
    }


    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string()
            .required('This field is required!'),
        newPassword: Yup.string()
            .required('This field is required!')
            .minLowercase(1, 'password must contain at least 1 lower case letter')
            .minUppercase(1, 'password must contain at least 1 upper case letter')
            .minNumbers(1, 'password must contain at least 1 number')
            .minSymbols(1, 'password must contain at least 1 special character')
            .test(
                "len",
                ('The password must be between 6 to 40 characters.'),
                (val: any) =>
                    val &&
                    val.toString().length >= 6 &&
                    val.toString().length <= 40
            ),
        confirmNewPassword: Yup.string()
            .required('This field is required!')
            .when("newPassword", {
                is: (val: string | any[]) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("newPassword")],
                    "Both password need to be the same"
                )
            })
    });

    return (
        <>

            <main className='d-flex flex-column justify-content-center mb-5 flex-fill'>
                <div className="container-fluid px-lg-4">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-5">
                            <div className="card shadow border-0 mb-5">
                                <div className="card-body">
                                    <h3 className="text-center fw-semibold mb-4">{t("dashboardPage.lablChangePassword")}</h3>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={handleChangePassword}
                                        validationSchema={validationSchema}
                                    >

                                        <Form>
                                            <div className='password-toggle form-floating mb-4'>
                                                <Field name="oldPassword" type={passwordType} className="form-control" placeholder="Please type your Old Password" />
                                                <label>{t("changePasswordPage.lblOldPassword")}</label>
                                                {/* <a href="#/" onClick={togglePassword} className="text-black">
                                                            <i className={`fa-solid ${passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                        </a> */}
                                                <ErrorMessage name="oldPassword" component="div" className="text-danger txt-error position-absolute" />
                                                {message && (
                                                    <div className="form-group">
                                                        <div
                                                            className={
                                                                succesful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                                                            }
                                                            role="alert"
                                                        >
                                                            {message}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='password-toggle form-floating mb-4 pt-1'>
                                                <Field name="newPassword" type={newPasswordType} className="form-control" placeholder="Please type New Password" />
                                                <label>{t("changePasswordPage.lblNewPassword")}</label>
                                                <ErrorMessage name="newPassword" component="div" className="text-danger txt-error position-absolute" />
                                            </div>
                                            <div className='password-toggle form-floating mb-4 pt-1'>
                                                <Field name="confirmNewPassword" type={confirmNewPasswordType} className="form-control" placeholder="Please Confirm New Password" />
                                                <label>{t("changePasswordPage.lblConfirmNewPassword")}</label>
                                                <ErrorMessage name="confirmNewPassword" component="div" className="text-danger txt-error position-absolute" />
                                            </div>
                                            <div className="pt-1">
                                                <button className="btn btn-primary" type="submit">{t("changePasswordPage.btnClickSave")}</button>
                                            </div>
                                        </Form>

                                    </Formik>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </main>

        </>
    )
}
export default ChangePasswordPage;
