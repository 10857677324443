/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as AuthService from "../services/auth.service";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutHandler } from '../store/slicers/authSlicer';

interface DefaultHeaderProps {
    title: string;
}


// eslint-disable-next-line no-empty-pattern
const DefaultHeader: FC<DefaultHeaderProps> = ({ }) => {

    const { t, i18n } = useTranslation('translate');
    const [selectedLang, setSelectedLang] = useState<string>(i18n.language); // Initialize with the current language
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const changeLang = (e: any) => {
        i18n.changeLanguage(e);
        localStorage.setItem("language", e);
        setSelectedLang(e); // Update the selected language
    }

    let getUserData: any = localStorage.getItem('user');
    const tenantData = JSON.parse(getUserData)?.userData?.tenantData
    const handleOrganizationChange = (e: any) => {
        localStorage.setItem("currentTenant", e.target.value)
        window.location.href = "/";
    }
    const handleLogout = () => {
        AuthService.logout();
        toast.success("Logout Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10,
        });
        dispatch(logoutHandler())
        navigate("/");
    }
    useEffect(() => {


        // Toggle the side navigation
        const sidebarToggle = document.body.querySelector('#sidebarToggle');
        if (sidebarToggle) {
            // Uncomment Below to persist sidebar toggle between refreshes
            // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
            //     document.body.classList.toggle('sb-sidenav-toggled');
            // }
            sidebarToggle.addEventListener('click', event => {
                event.preventDefault();
                document.body.classList.toggle('sb-sidenav-toggled');
                // localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
            });
        }
    })

    return (
        <>
            <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                {/* <!-- Navbar Brand--> */}
                <Link className="navbar-brand ps-3" to="/dashboard">ezycards.co</Link>
                {/* <!-- Sidebar Toggle--> */}
                <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle"><i className="fas fa-bars"></i></button>
                {/* <!-- Navbar Search--> */}

                <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                    {/* <div className="input-group">
                        <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                        <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
                    </div> */}
                </form>
                {/* <!-- Navbar--> */}
                {/*<!--button for language changes--> */}
                <div className="">
                    <button
                        className={`btn shadow-none ${selectedLang === 'de' ? 'highlight' : ''}`}
                        onClick={() => changeLang('de')}
                    >
                        <img className='img-fluid flag-image' src="https://cdn-icons-png.flaticon.com/512/330/330523.png" alt="germany-icon" />
                    </button>
                    <button
                        className={`btn shadow-none ${selectedLang === 'en' ? 'highlight' : ''}`}
                        onClick={() => changeLang('en')}
                    >
                        <img className='img-fluid flag-image' src="https://cdn-icons-png.flaticon.com/512/206/206626.png" alt="germany-icon" />
                    </button>
                </div>

                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        {tenantData?.length > 1 ?
                            <select
                                className="tenantDropDown form-select form-select-sm"
                                name="emailLabel"
                                onChange={(e: any) => handleOrganizationChange(e)}
                            >
                                {tenantData?.map((data: any, index: any) => {
                                    return (
                                        <option value={data?.tenantRef} selected={data.tenantRef == localStorage.getItem('currentTenant') ? true : false} >{data.organization}</option>
                                    )
                                })}
                            </select>
                            :
                            ""}
                    </li>
                </ul>

                <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" id="navbarDropdown" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></Link>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="/profile-page">{t("header.dropDownProfile")}</Link></li>
                            <li><button className="dropdown-item" onClick={handleLogout}>{t("header.dropDownLogOut")}</button></li>
                        </ul>
                    </li>
                </ul>
            </nav>

        </>
    )

}

export default DefaultHeader;
