import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

export const customizeQr = async () => {
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "qrcode-types",
        method: "Get",
        headers: newHeader
    })

}


export const saveQrData = async (payload: any) => {
    let newHeader = {
        ...authHeader(),
        "Content-type": "multipart/form-data",
    }
    return axios({
        url: API_URL + "qrcode-types",
        method: "POST",
        data: payload,
        headers: newHeader
    })
    .then(response => response.data)
    .catch(error => {
        console.log(error);
    });
}

export const currentData = async (businessId: any, QrType: any) => {
    let newHeader = {
        ...authHeader(),
    }
    return axios({
        url: API_URL + "current-qr",
        method: "Get",
        headers: newHeader,
        params: {
            "businessRfid": businessId,
            "qrType": QrType
        }
    })
}

export const iconLink = async (formData: any) => {
    let newHeader = {
        ...authHeader(),
        "content-type": "multipart/form-data"
    };
    return axios({
        url: API_URL + "qrcode-icon",
        method: "POST",
        headers: newHeader,
        data: formData
    })
    .then(response => response.data)
    .catch(error => {
        console.log(error);
    });
}
