import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { userProfile } from "../services/register.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { profileUpdate } from "../services/register.service";
import ModalOverlay from "../components/modal";
import { ImageEditor } from "../components/imageEditor";
interface ProfilePageProps {
  title: string;
}

const ProfilePage: FC<ProfilePageProps> = ({ title }) => {

  const [editedImg, setEditedImg] = useState();
  const [profilePic, setProfilePic] = useState<any>();
  const [imageType, setImageType] = useState<any>("");
  const [show, setShow] = useState(false);
  const [user, setUserData] = useState<any>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const { t } = useTranslation("translate");

  const getUserDetail = async () => {
    const userDataList: any = await userProfile();
    setUserData(userDataList.data.data.userData);
    setFirstName(userDataList.data.data.userData.firstName);
    setLastName(userDataList.data.data.userData.lastName);
    if(0 == userDataList.data.data.userData.profilePicEmpty) {
      setProfilePic(userDataList.data.data.userData.profilePic);
    } else {
      setProfilePic(undefined);
    }
  };
  const navigate = useNavigate();
  const navigateDashboard = () => {
    navigate("/dashboard");
  };

  async function updateProfileHandler() {
    let base64Response;
    let profileImg: any;
    let finalImg: any;
    let isImageChanged: any = 0;
  
    if (editedImg) {
      isImageChanged = 1;
      finalImg = editedImg;
    } else if (profilePic) {
      finalImg = profilePic;
    }  else {
      isImageChanged = 1;
      finalImg = undefined;
    }
    if (finalImg != undefined && finalImg != "" && 1 == isImageChanged) {
      base64Response = await fetch(finalImg);
      profileImg = await base64Response.blob();
    }

    const response = await profileUpdate(firstName, lastName, profileImg, isImageChanged);
    if (!response) {
      toast.error("Please add a field", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else {
      setShow(false);
      getUserDetail();
      toast.success("Profile updated sucessfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      }
      );

    }
  }

  useEffect(() => {
    getUserDetail();
  }, []);
  function getImageHandler(data: any) {
    if (data?.status != "removed") {
      setEditedImg(data?.thumbUrl);
    } else {
      setEditedImg(undefined);
      setProfilePic(undefined);
    }
  }

  return (
    <>
      <section>
        <main className='d-flex flex-column justify-content-center mb-5 flex-fill parent-container'>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5">
              <div className="card shadow border-0 mb-5 mt-5">
               
                {show ? (
                  <div className="card m-4">
                      <div className="card-header">
                       <h3>{t('profilePage.lblProfileEditTitle')}</h3>
                      </div>
                        <div className="card-body ">
                            <div>
                                  <div className="mt-2 mousePointer text-center">
                                      <ImageEditor
                                        img={profilePic}
                                        getImage={getImageHandler}
                                      />
                                  </div>
                            </div>
                            <div className="form-floating mt-2" >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your FirstName"
                                value={firstName}
                                onChange={(e: any) => setFirstName(e.target.value)}
                              />
                              <label htmlFor="inputFirstName">
                              {t('profilePage.lblFirstName')}
                              </label>
                            </div>
                            <div className="form-floating mt-2 mb-4" >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your LastName"
                                value={lastName}
                                onChange={(e: any) => setLastName(e.target.value)}
                              />
                              <label htmlFor="inputFirstName">
                              {t('profilePage.lblLastName')}
                              </label>
                            </div>      
                            <div className="row">
                              <div className="col-12">
                                {!show ? <button
                                  type="button"
                                  className="btn btn-secondary back"
                                  onClick={navigateDashboard}
                                >
                                  {t('profilePage.btnClickBack')}
                                </button> :
                                  <button
                                    type="button"
                                    className="btn btn-secondary back"
                                    onClick={() => setShow(false)}
                                  >
                                  {t('profilePage.btnClickBack')}
                                  </button>}
                                {!show ? (
                                  <button
                                    type="button"
                                    className="btn btn-danger float-right "
                                    onClick={() => setShow(!show)}
                                  >
                                    {t('profilePage.lblEditbtn')}
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-success float-right "
                                    onClick={updateProfileHandler}
                                  >
                                {t('profilePage.btnClickSave')}
                                  </button>
                                )}

                              </div>
                            </div>
                          </div>
                  </div>
                ) : 
                (
                  <div className="card m-4">
                      <div className="card-header">
                       <h3>{t('profilePage.lblProfileTitle')}</h3>
                      </div>
                      <div className="card-body ">
                       <div className="text-start">
                             <div className=" text-center">             
                              <div className="mb-4 ">
                                <img className="profile-user-img img-fluid img-circle" src={user.profilePic} alt="User profile picture" />
                              </div>
                            </div>
                            <div className=" mt-2 card p-2" >
                              <label>{t('profilePage.lblFirstName')}</label>
                              <p className="text-start mb-1 text-muted">{user.firstName}</p>
                            </div>
                            <div className=" mt-2 card p-2" >
                              <label>{t('profilePage.lblLastName')}</label>
                              <p className="text-start mb-1 text-muted">{user.lastName}</p>
                            </div>
                            <div className=" mt-2 mb-4 card p-2" >
                              <label>{t('profilePage.lblEmail')}</label>
                              <p className="text-start mb-1 text-muted ">{user.email}</p>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                {!show ? <button
                                  type="button"
                                  className="btn btn-secondary back"
                                  onClick={navigateDashboard}
                                >
                                  {t('profilePage.btnClickBack')}
                                </button> :
                                  <button
                                    type="button"
                                    className="btn btn-secondary back"
                                    onClick={() => setShow(false)}
                                  >
                                  {t('profilePage.btnClickBack')}
                                  </button>}
                                {!show ? (
                                  <button
                                    type="button"
                                    className="btn btn-danger float-right "
                                    onClick={() => setShow(!show)}
                                  >
                                    {t('profilePage.lblEditbtn')}
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-success float-right "
                                    onClick={updateProfileHandler}
                                  >
                                {t('profilePage.btnClickSave')}
                                  </button>
                                )}

                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </section>

    </>
  );
};
export default ProfilePage;
