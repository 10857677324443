import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as htmlToImage from "html-to-image";
import { businessGetBybusinessRef } from "../services/business.service";
import { useTranslation } from "react-i18next";
import { jsPDF } from 'jspdf';
import { useSelector } from "react-redux";
import html2canvas from 'html2canvas';
import { ThunkDispatch } from "../store/slicers/commonSlice";
import { enableLoader, hideLoader } from "../store/slicers/commonSlice"


import {
  QR25D,
  QRBubble,
  QRDsj,
  QRFunc,
  QRImage,
  QRLine,
  QRNormal,
  QRResImage,
} from "react-qrbtf";
import { useDispatch } from "react-redux";
import { strictEqual } from "assert";

interface BusinessViewPageProps {
  title: string;
}

const BusinessViewPage: FC<BusinessViewPageProps> = ({ }) => {
  const { t } = useTranslation("translate");
  const [businessesList, setBusinessesData] = useState<any>([]);
  const [disabled, setDisable] = useState<boolean>(false);
  const loaderState: any = useSelector((state: any) => state.common.showLoader);
  const [base64Image, setBase64Image] = useState('');

  let { businessRef } = useParams();
  const dispatch: ThunkDispatch = useDispatch();

  let navigate = useNavigate();
  const MIME = { jpg: "image/jpeg", png: "image/png" };

  const getBusiness = async (businessRef: string) => {
    const businessesDataList = await businessGetBybusinessRef(businessRef);
    setBusinessesData(businessesDataList.data.data.business);
  };
  const handleQrDownload = (divSelector: string, filename: string, buttonId: string) => {
    setDisable(true)
    let myContainer: any | null = document.querySelector("#" + divSelector);
    if (!myContainer) {
      throw new Error("Could not find selector for download a QR Code");
    }
    myContainer.insertAdjacentHTML('beforeend', '<span className="pt-2"></span>');
    htmlToImage.toJpeg(myContainer, { quality: 0.95, backgroundColor: "white" })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = filename;
        link.href = dataUrl;
        link.click();
        setDisable(false);
      })
      .catch(err => {
        console.log(err);
        setDisable(false);
      });
  };

  const handleDownloadPDF = async (type: string) => {
    dispatch(enableLoader(true));
    document.body.style.overflow = "hidden";

    const qrDiv: any = document.getElementById(type);
    qrDiv.classList.remove('hidden');

    await new Promise((resolve) => setTimeout(resolve));

    const content: any = document.getElementById(type);

    let widthInInches, heightInInches, mmToInchFactor;

    if (type === 'banner' || type === 'sticker') {
      widthInInches = 8.5;
      heightInInches = 11;
    } else {
      // Set the canvas size to Junior Legal (5 × 7 inches or 127 × 178 mm)
      widthInInches = 5;
      heightInInches = 7;
    }

    mmToInchFactor = 25.4;
    const width = widthInInches * mmToInchFactor;
    const height = heightInInches * mmToInchFactor;

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const pdf = new jsPDF({
      orientation: type === 'banner' ? 'landscape' : 'portrait',
      unit: 'mm',
      format: [width, height],
    });

    const imageQuality =
      type === 'sticker' || type === 'banner' ? 1.0 : 1.8;

    html2canvas(content, {
      // scale: mmToInchFactor / 25.4,
      scale: type === 'banner' ? 1 : 2,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', imageQuality);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      const pdfBlob = pdf.output('blob');
      if (pdfBlob.size > 700 * 1024) {
        console.log("file size large");
      } else {
        const filename = `${type}.pdf`;
        saveAs(pdfBlob, filename);

        qrDiv.classList.add('hidden');
        dispatch(hideLoader());
        document.body.style.overflow = "unset";
      }
    });
  };
  // Rest of the code remains the same...

  // downloadable link from a blob
  function saveAs(blob: Blob, filename: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

  useEffect(() => {
    const imageUrl = `${process.env.REACT_APP_BACKEND_BASE_URL + '/images/anonymous.png'}`;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String: any = reader.result;
          setBase64Image(base64String);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        console.error('Error loading image:', error);
      });

  }, [])

  useEffect(() => {
    if (!!businessRef) {
      getBusiness(businessRef);
    }
  }, []);

  const handleClickEdit = (type: any) => {
    navigate(`/qrcustomize-page/${type}/${businessRef}`);
  }

  return (
    <>
      <section>
        <div className="container-fluid px-lg-4">
          <h3 className="mt-3 mb-4">
            {t("businessViewPage.lblBusinessProfile")}
          </h3>
          <div className="card mb-4 card-border">
            <div className="container py-4">
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-6 col-xl-5 mb-4">
                  <div className="user-contact-detail">
                    <div className="d-flex align-items-center justify-content-between">
                      <Link to="/" className="back-btn">
                        <i className="fa-solid fa-chevron-left text-light"></i>
                      </Link>
                      <div className="d-flex align-items-center gap-4">

                        <div className="dropdown">
                          <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="d-flex align-items-center text-light gap-3">
                              <span>{t("businessViewPage.lblScanQRTags")}</span>
                              <i className="fa-solid fa-angle-down"></i>
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-start">
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#textMessageModal"
                              >
                                <span className="d-flex align-items-center">
                                  <img className="me-3" src='/QR-code.png' style={{ width: "25px", height: "25px" }} alt="" />
                                  <span>SHOW QR</span>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item pt-2 pb-2"
                                onClick={() => handleDownloadPDF("sticker")}
                              >
                                <span className="d-flex align-items-center">
                                  <img className="me-3" src='/stiker.png' style={{ width: "25px", height: "25px" }} alt="" />
                                  <span>Download Sticker</span>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item pt-2 pb-2"
                                onClick={() => handleDownloadPDF("banner")}
                              >
                                <span className="d-flex align-items-center">
                                  <img className="me-3" src='/banner.png' style={{ width: "25px", height: "25px" }} alt="" />
                                  <span>Download Banner</span>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item pt-2 pb-2"
                                onClick={() => handleDownloadPDF("brocherQr")}

                              >
                                <span className="d-flex align-items-center">
                                  <img className="me-3" src='/brochure.png' style={{ width: "25px", height: "25px" }} alt="" />
                                  <span>Download Brochure</span>
                                </span>
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                to="#"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#whatsappMessageModal"
                              >
                                <span className="d-flex align-items-center justify-content-center gap-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-whatsapp"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                  </svg>
                                  <span>{t("businessViewPage.lblQRCode")}</span>
                                </span>
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="user-info mb-3">
                      {1 == businessesList.isIndividual ? (
                        <>
                          <div className="avatar">
                            <img
                              className="img-fluid rounded-circle"
                              src={businessesList.brandPic ? businessesList.brandPic : `${process.env.REACT_APP_BACKEND_BASE_URL + '/images/anonymous.png'}`}
                              alt=""
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="avatar business">
                            <img
                              className="img-fluid img-fluid-business"
                              src={businessesList.brandPic ? businessesList.brandPic : `${process.env.REACT_APP_BACKEND_BASE_URL + '/images/anonymous.png'}`}
                              alt=""
                            />
                          </div>
                        </>
                      )}
                      <div className="user-detail">
                        {1 == businessesList.isIndividual ? (
                          <>
                            <div className="d-flex align-items-center justify-content-center gap-4">
                              <div className="text-light">
                                {businessesList.firstName}{" "}
                                {businessesList.lastName}
                              </div>
                            </div>
                            <div className="text-muted text-center fw-semibold mb-2">
                              {businessesList.title},{" "}
                              {businessesList.organization}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex align-items-center justify-content-center gap-4">
                              <div className="text-light">
                                {businessesList.organization}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="text-muted text-center fw-semibold mb-3">
                          {
                            businessesList.BusinessMobileNumbers?.[0]
                              ?.userGivenMobileNumber
                          }
                        </div>
                        <div className="d-flex align-items-center justify-content-center gap-5 mb-3">
                          <a
                            href={`tel:${businessesList.BusinessMobileNumbers?.[0]?.userGivenMobileNumber}`}
                          >
                            <i className="fa-solid fa-phone fa-lg text-success"></i>
                          </a>
                          <a
                            href={`mailto: ${businessesList.BusinessEmailAddresses?.[0].lowerFormattedEmailAddress}`}
                          >
                            <i className="fa-solid fa-envelope fa-lg text-muted"></i>
                          </a>
                        </div>
                        <div className="d-flex justify-content-center">
                          <Link
                            to={`/business-update/${businessRef}`}
                            className="btn btn-primary rounded-pill"
                            role="button"
                          >
                            <span className="d-flex align-items-center ">
                              <span>{t("businessViewPage.lblEditProfile")}</span>

                              <span><i className="fa-solid fa-angle-right"></i></span>
                            </span>
                          </Link>
                        </div>
                        <br></br>
                        <div className="loader-container">
                          {loaderState && (<div className="loading d-block"></div>)}
                        </div>

                      </div>
                    </div>
                    <div className="user-mail-detail mb-3">
                      {businessesList?.BusinessMobileNumbers?.map(
                        (businessMobileNumbersValue: any, index: any) => {
                          return (
                            <>
                              <div
                                className="d-flex flex-wrap align-items-center justify-content-between  user-mail-detail-item"
                                key={index}
                              >
                                <div>
                                  <div className="text-muted">
                                    {
                                      businessMobileNumbersValue.mobileNumberLabel
                                    }
                                  </div>
                                  <div className="text-light">
                                    {
                                      businessMobileNumbersValue.internationalFormattedMobileNumber
                                    }
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap-4">
                                  <a
                                    href={`tel:${businessMobileNumbersValue.internationalFormattedMobileNumber}`}
                                  >
                                    <i className="fa-solid fa-phone fa-lg text-success"></i>
                                  </a>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                    <div className="user-mail-detail mb-3">
                      {businessesList?.BusinessEmailAddresses?.map(
                        (BusinessEmailAddressesValue: any, index: any) => {
                          return (
                            <div
                              className="d-flex flex-wrap align-items-center justify-content-between user-mail-detail-item"
                              key={index}
                            >
                              <div>
                                <div className="text-muted">
                                  {
                                    BusinessEmailAddressesValue.emailAddressLabel
                                  }
                                </div>
                                <div className="text-light">
                                  {
                                    BusinessEmailAddressesValue.lowerFormattedEmailAddress
                                  }
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  href={`mailto: ${BusinessEmailAddressesValue.lowerFormattedEmailAddress}`}
                                >
                                  <i className="fa-solid fa-envelope fa-lg text-muted"></i>
                                </a>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="user-mail-detail mb-3">
                      {businessesList?.BusinessAddresses?.map(
                        (BusinessAddressesValue: any, index: any) => {
                          return (
                            <div
                              className="d-flex flex-wrap align-items-center justify-content-between user-mail-detail-item"
                              key={index}
                            >
                              <div>
                                <div className="text-muted">
                                  {BusinessAddressesValue.label}
                                </div>
                                <div className="text-light">
                                  {BusinessAddressesValue.street}
                                </div>
                                <div className="text-light">
                                  {BusinessAddressesValue.postalCode}
                                </div>
                                <div className="text-light">
                                  {BusinessAddressesValue.city}
                                </div>
                                <div className="text-light">
                                  {BusinessAddressesValue.stateProvince}
                                </div>
                                <div className="text-light">
                                  {BusinessAddressesValue.countryRegion}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="user-mail-detail mb-3">
                      <div className="text-light">
                        {businessesList?.BusinessWebsites?.map(
                          (BusinessWebsitesValue: any, index: any) => {
                            return (
                              <>
                                {BusinessWebsitesValue.websiteAddress && (
                                  <div
                                    className="d-flex flex-wrap flex-column user-mail-detail-item"
                                    key={index}
                                  >
                                    {BusinessWebsitesValue.websiteAddress == "" ? "" :
                                      <div className="text-muted">
                                        {BusinessWebsitesValue.websiteLabel}
                                      </div>
                                    }
                                    <a
                                      href={BusinessWebsitesValue.websiteAddress}
                                      target="_blank"
                                      className="break-all"
                                    >
                                      {BusinessWebsitesValue.websiteAddress}
                                    </a>
                                  </div >
                                )}
                              </>
                            )
                          })}
                      </div >
                    </div >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <div
        className="modal fade"
        id="textMessageModal"
        aria-labelledby="textMessageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="textMessageModalLabel">
                {t("businessViewPage.mdlHeaderTextQrCode")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/*customize qr for ezycards*/}
              <div
                id="textQrCode"
                className="qr-border d-flex flex-column align-items-center justify-content-center qrBoxDivPreview"
              >
                {businessesList.prettyQrSms?.qrType === "QRNormal" ? (
                  <>
                    <QRNormal
                      level="L"
                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                      type={businessesList.prettyQrSms?.properties?.type}
                      size={businessesList.prettyQrSms?.properties?.size}
                      opacity={businessesList.prettyQrSms?.properties?.opacity}
                      posType={businessesList.prettyQrSms?.properties?.posType}
                      otherColor={
                        businessesList.prettyQrSms?.properties?.otherColor
                      }
                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                      icon={businessesList.prettyQrSms?.properties?.icon}
                    />
                  </>
                ) : businessesList.prettyQrSms?.qrType === "QRDsj" ? (
                  <>
                    <QRDsj
                      level="L"
                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                      posType={businessesList.prettyQrSms?.properties?.posType}
                      scale={businessesList.prettyQrSms?.properties?.scale}
                      crossWidth={businessesList.prettyQrSms?.properties?.crossWidth}
                      posWidth={businessesList.prettyQrSms?.properties?.posWidth}
                      icon={businessesList.prettyQrSms?.properties?.icon}
                    />
                  </>
                ) : businessesList.prettyQrSms?.qrType === "QR25D" ? (
                  <>
                    <QR25D
                      level="L"
                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                      height={businessesList.prettyQrSms?.properties?.height}
                      posHeight={
                        businessesList.prettyQrSms?.properties?.posHeight
                      }
                      leftColor={
                        businessesList.prettyQrSms?.properties?.leftColor
                      }
                      rightColor={
                        businessesList.prettyQrSms?.properties?.rightColor
                      }
                      topColor={businessesList.prettyQrSms?.properties?.topColor}
                    />
                  </>
                ) : businessesList.prettyQrSms?.qrType === "QRImage" ? (
                  <>
                    <QRImage
                      level="L"
                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                      type={businessesList.prettyQrSms?.properties?.type}
                      size={businessesList.prettyQrSms?.properties?.size}
                      opacity={businessesList.prettyQrSms?.properties?.opacity}
                      posType={businessesList.prettyQrSms?.properties?.posType}
                      darkColor={
                        businessesList.prettyQrSms?.properties?.darkColor
                      }
                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                      lightColor={
                        businessesList.prettyQrSms?.properties?.lightColor
                      }
                      image={businessesList.prettyQrSms?.properties?.image}
                    />
                  </>
                ) : businessesList.prettyQrSms?.qrType === "QRBubble" ? (
                  <>
                    <QRBubble
                      level="L"
                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                      circleColor={
                        businessesList.prettyQrSms?.properties?.circleColor
                      }
                      posColor={businessesList.prettyQrSms?.properties.posColor}
                    />
                  </>
                ) : businessesList.prettyQrSms?.qrType === "QRFunc" ? (
                  <>
                    <QRFunc
                      level="L"
                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                      funcType={businessesList.prettyQrSms?.properties?.funcType}
                      type={businessesList.prettyQrSms?.properties?.type}
                      posType={businessesList.prettyQrSms?.properties?.posType}
                      otherColor1={businessesList.prettyQrSms?.properties?.otherColor1}
                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                    />
                  </>
                ) : businessesList.prettyQrSms?.qrType === "QRLine" ? (
                  <>
                    <QRLine
                      level="L"
                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                      direction={businessesList.prettyQrSms?.properties?.direction}
                      posType={businessesList.prettyQrSms?.properties?.posType}
                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                      lineColor={businessesList.prettyQrSms?.properties?.lineColor}
                      lineOpacity={businessesList.prettyQrSms?.properties?.lineOpacity}
                      lineWidth={businessesList.prettyQrSms?.properties?.lineWidth}
                      icon={businessesList.prettyQrSms?.properties?.icon}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={businessesList.qrCodePicWithLogo}
                      alt="Qr-Code"
                      className="img-fluid"
                      width={400}
                    />{" "}
                  </>
                )}
                <span>
                  {1 == businessesList.isIndividual ?
                    businessesList.firstName + " " + businessesList.lastName : businessesList.organization
                  }
                  &nbsp;&nbsp;
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chat-dots text-right" viewBox="0 0 16 16">
                      <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                    </svg>
                  </span>
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <a className="btn btn-primary btn-edit" data-bs-dismiss="modal" onClick={() => handleClickEdit("SmsQr")}>
                Edit
              </a>
              <button id="smsQrDwnld" className="btn btn-primary" disabled={disabled}
                onClick={() => {
                  handleQrDownload("textQrCode", "SMS-QR-Code.jpg", "smsQrDwnld")
                }}>
                {t("businessViewPage.btnClickDownloadQr")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="whatsappMessageModal"
        aria-labelledby="whatsappMessageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="whatsappMessageModalLabel">
                {t("businessViewPage.mdlHeaderWpQrCode")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div
                id="wpQrCode"
                className="qr-border d-flex flex-column align-items-center justify-content-center qrBoxDivPreview"
              >
                {/*customize qr for whatsapp*/}
                {businessesList.prettyQrWp?.qrType === "QRNormal" ? (
                  <>
                    <QRNormal
                      level="L"
                      value={businessesList.prettyQrWp?.properties?.valueWp}
                      type={businessesList.prettyQrWp?.properties?.type}
                      size={businessesList.prettyQrWp?.properties?.size}
                      opacity={businessesList.prettyQrWp?.properties?.opacity}
                      posType={businessesList.prettyQrWp?.properties?.posType}
                      otherColor={businessesList.prettyQrWp?.properties?.otherColor}
                      posColor={businessesList.prettyQrWp?.properties?.posColor}
                      icon={businessesList.prettyQrWp?.properties?.icon}
                    />
                  </>
                ) : businessesList.prettyQrWp?.qrType === "QRDsj" ? (
                  <>
                    <QRDsj
                      level="L"
                      value={businessesList.prettyQrWp?.properties?.valueWp}
                      posType={businessesList.prettyQrWp?.properties?.posType}
                      scale={businessesList.prettyQrWp?.properties?.scale}
                      crossWidth={businessesList.prettyQrWp?.properties?.crossWidth}
                      posWidth={businessesList.prettyQrWp?.properties?.posWidth}
                      icon={businessesList.prettyQrWp?.properties?.icon}
                    />
                  </>
                ) : businessesList.prettyQrWp?.qrType === "QR25D" ? (
                  <>
                    <QR25D
                      level="L"
                      value={businessesList.prettyQrWp?.properties?.valueWp}
                      height={businessesList.prettyQrWp?.properties?.height}
                      posHeight={
                        businessesList.prettyQrWp?.properties?.posHeight
                      }
                      leftColor={
                        businessesList.prettyQrWp?.properties?.leftColor
                      }
                      rightColor={
                        businessesList.prettyQrWp?.properties?.rightColor
                      }
                      topColor={businessesList.prettyQrWp?.properties?.topColor}
                    />
                  </>
                ) : businessesList.prettyQrWp?.qrType === "QRImage" ? (
                  <>
                    <QRImage
                      level="L"
                      value={businessesList.prettyQrWp?.properties?.valueWp}
                      type={businessesList.prettyQrWp?.properties?.type}
                      size={businessesList.prettyQrWp?.properties?.size}
                      opacity={businessesList.prettyQrWp?.properties?.opacity}
                      posType={businessesList.prettyQrWp?.properties?.posType}
                      darkColor={
                        businessesList.prettyQrWp?.properties?.darkColor
                      }
                      posColor={businessesList.prettyQrWp?.properties?.posColor}
                      lightColor={
                        businessesList.prettyQrWp?.properties?.lightColor
                      }
                      image={businessesList.prettyQrWp?.properties?.image}
                    />
                  </>
                ) : businessesList.prettyQrWp?.qrType === "QRBubble" ? (
                  <>
                    <QRBubble
                      level="L"
                      value={businessesList.prettyQrWp?.properties?.valueWp}
                      circleColor={
                        businessesList.prettyQrWp?.properties?.circleColor
                      }
                      posColor={businessesList.prettyQrWp?.properties.posColor}
                    />
                  </>
                ) : businessesList.prettyQrWp?.qrType === "QRFunc" ? (
                  <>
                    <QRFunc
                      level="L"
                      value={businessesList.prettyQrWp?.properties?.valueWp}
                      funcType={businessesList.prettyQrWp?.properties?.funcType}
                      type={businessesList.prettyQrWp?.properties?.type}
                      posType={businessesList.prettyQrWp?.properties?.posType}
                      otherColor1={businessesList.prettyQrWp?.properties?.otherColor1}
                      posColor={businessesList.prettyQrWp?.properties?.posColor}
                    />
                  </>
                ) : businessesList.prettyQrWp?.qrType === "QRLine" ? (
                  <>
                    <QRLine
                      level="L"
                      value={businessesList.prettyQrWp?.properties?.valueWp}
                      direction={businessesList.prettyQrWp?.properties?.direction}
                      posType={businessesList.prettyQrWp?.properties?.posType}
                      posColor={businessesList.prettyQrWp?.properties?.posColor}
                      lineColor={businessesList.prettyQrWp?.properties?.lineColor}
                      lineOpacity={businessesList.prettyQrWp?.properties?.lineOpacity}
                      lineWidth={businessesList.prettyQrWp?.properties?.lineWidth}
                      icon={businessesList.prettyQrWp?.properties?.icon}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={businessesList?.qrCodePicWPWithLogo ?? ""}
                      alt="Qr-Code"
                      className="img-fluid"
                      width={400}
                    />{" "}
                  </>
                )}
                <span>
                  {1 == businessesList.isIndividual ?
                    businessesList.firstName + " " + businessesList.lastName : businessesList.organization
                  }
                  &nbsp;&nbsp;
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-whatsapp text-right" viewBox="0 0 16 16">
                      <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                    </svg>
                  </span>
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <a className="btn btn-primary btn-edit" data-bs-dismiss="modal" onClick={() => handleClickEdit("WpQr")}>
                Edit
              </a>
              <button id="wpQrDwnld" className="btn btn-primary" disabled={disabled}
                onClick={() => {
                  handleQrDownload("wpQrCode", "WhatsApp-QR-Code.jpg", "wpQrDwnld")
                }}>
                {t("businessViewPage.btnClickDownloadQr")}
              </button>
            </div>
          </div>
        </div>
      </div>


      <div id="sticker" className="hidden size-letter card">
        <div className="card-body text-center scannerQr">
          <div className="img-wrapper bg-white rounded">
            {businessesList.prettyQrSms?.qrType === "QRNormal" ? (
              <>
                <QRNormal
                  level="L"
                  value={businessesList.prettyQrSms?.properties?.valueMMS}
                  type={businessesList.prettyQrSms?.properties?.type}
                  size={businessesList.prettyQrSms?.properties?.size}
                  opacity={businessesList.prettyQrSms?.properties?.opacity}
                  posType={businessesList.prettyQrSms?.properties?.posType}
                  otherColor={
                    businessesList.prettyQrSms?.properties?.otherColor
                  }
                  posColor={businessesList.prettyQrSms?.properties?.posColor}
                  icon={businessesList.prettyQrSms?.properties?.icon}
                />
              </>
            ) : businessesList.prettyQrSms?.qrType === "QRDsj" ? (
              <>
                <QRDsj
                  level="L"
                  value={businessesList.prettyQrSms?.properties?.valueMMS}
                  posType={businessesList.prettyQrSms?.properties?.posType}
                  scale={businessesList.prettyQrSms?.properties?.scale}
                  crossWidth={businessesList.prettyQrSms?.properties?.crossWidth}
                  posWidth={businessesList.prettyQrSms?.properties?.posWidth}
                  icon={businessesList.prettyQrSms?.properties?.icon}
                />
              </>
            ) : businessesList.prettyQrSms?.qrType === "QR25D" ? (
              <>
                <QR25D
                  level="L"
                  value={businessesList.prettyQrSms?.properties?.valueMMS}
                  height={businessesList.prettyQrSms?.properties?.height}
                  posHeight={
                    businessesList.prettyQrSms?.properties?.posHeight
                  }
                  leftColor={
                    businessesList.prettyQrSms?.properties?.leftColor
                  }
                  rightColor={
                    businessesList.prettyQrSms?.properties?.rightColor
                  }
                  topColor={businessesList.prettyQrSms?.properties?.topColor}
                />
              </>
            ) : businessesList.prettyQrSms?.qrType === "QRImage" ? (
              <>
                <QRImage
                  level="L"
                  value={businessesList.prettyQrSms?.properties?.valueMMS}
                  type={businessesList.prettyQrSms?.properties?.type}
                  size={businessesList.prettyQrSms?.properties?.size}
                  opacity={businessesList.prettyQrSms?.properties?.opacity}
                  posType={businessesList.prettyQrSms?.properties?.posType}
                  darkColor={
                    businessesList.prettyQrSms?.properties?.darkColor
                  }
                  posColor={businessesList.prettyQrSms?.properties?.posColor}
                  lightColor={
                    businessesList.prettyQrSms?.properties?.lightColor
                  }
                  image={businessesList.prettyQrSms?.properties?.image}
                />
              </>
            ) : businessesList.prettyQrSms?.qrType === "QRBubble" ? (
              <>
                <QRBubble
                  level="L"
                  value={businessesList.prettyQrSms?.properties?.valueMMS}
                  circleColor={
                    businessesList.prettyQrSms?.properties?.circleColor
                  }
                  posColor={businessesList.prettyQrSms?.properties.posColor}
                />
              </>
            ) : businessesList.prettyQrSms?.qrType === "QRFunc" ? (
              <>
                <QRFunc
                  level="L"
                  value={businessesList.prettyQrSms?.properties?.valueMMS}
                  funcType={businessesList.prettyQrSms?.properties?.funcType}
                  type={businessesList.prettyQrSms?.properties?.type}
                  posType={businessesList.prettyQrSms?.properties?.posType}
                  otherColor1={businessesList.prettyQrSms?.properties?.otherColor1}
                  posColor={businessesList.prettyQrSms?.properties?.posColor}
                />
              </>
            ) : businessesList.prettyQrSms?.qrType === "QRLine" ? (
              <>
                <QRLine
                  level="L"
                  value={businessesList.prettyQrSms?.properties?.valueMMS}
                  direction={businessesList.prettyQrSms?.properties?.direction}
                  posType={businessesList.prettyQrSms?.properties?.posType}
                  posColor={businessesList.prettyQrSms?.properties?.posColor}
                  lineColor={businessesList.prettyQrSms?.properties?.lineColor}
                  lineOpacity={businessesList.prettyQrSms?.properties?.lineOpacity}
                  lineWidth={businessesList.prettyQrSms?.properties?.lineWidth}
                  icon={businessesList.prettyQrSms?.properties?.icon}
                />
              </>
            ) : (
              <>
                <img
                  src={businessesList.qrCodePicWithLogo}
                  alt="Qr-Code"
                  className="img-fluid"
                />{" "}
              </>
            )}

          </div>
          <h1 className="text-center mt-3 text-white"> Scan Me</h1>
        </div>
      </div>
      <div id="banner" className="hidden size-letter-landscape">
        <table cellPadding={0} cellSpacing={0} className="w-100">
          <tbody>
            <tr>
              <td className="inline_td" valign="top">
                <table cellPadding={0} cellSpacing={0} className="left_main_table">
                  <tbody>
                    <tr>
                      <td className="p-4 bar_cod_a">
                        <div className="position-relative">

                          <h2 className="big_title">Save the service department's<br /> contacts right on our phone</h2>

                          <div className="text-center margin-bottom-30">
                            <p className="p_tag">No app required! Once downloaded, you can locate the card in your contacts.</p>
                          </div>
                          <div className="">
                            <ul className="listing mb-3">
                              <li>Scan the OR! Click the link that pops up, then click "download card." </li>
                              <li>On iPhone: click the export button <img src="/upload_icon.png" /> then "Contacts," then "Save." </li>
                              <li>On Androids: select the account where you'd like to save the contact.</li>
                            </ul>
                            <div className="text-center">
                              {/* <img src="barcodea.png"  /> */}
                              <div style={{ width: "270px" }} className="mx-auto bg-white width-270px">
                                {businessesList.prettyQrSms?.qrType === "QRNormal" ? (
                                  <>
                                    <QRNormal
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      type={businessesList.prettyQrSms?.properties?.type}
                                      size={businessesList.prettyQrSms?.properties?.size}
                                      opacity={businessesList.prettyQrSms?.properties?.opacity}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      otherColor={
                                        businessesList.prettyQrSms?.properties?.otherColor
                                      }
                                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                                      icon={businessesList.prettyQrSms?.properties?.icon}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRDsj" ? (
                                  <>
                                    <QRDsj
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      scale={businessesList.prettyQrSms?.properties?.scale}
                                      crossWidth={businessesList.prettyQrSms?.properties?.crossWidth}
                                      posWidth={businessesList.prettyQrSms?.properties?.posWidth}
                                      icon={businessesList.prettyQrSms?.properties?.icon}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QR25D" ? (
                                  <>
                                    <QR25D
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      height={businessesList.prettyQrSms?.properties?.height}
                                      posHeight={
                                        businessesList.prettyQrSms?.properties?.posHeight
                                      }
                                      leftColor={
                                        businessesList.prettyQrSms?.properties?.leftColor
                                      }
                                      rightColor={
                                        businessesList.prettyQrSms?.properties?.rightColor
                                      }
                                      topColor={businessesList.prettyQrSms?.properties?.topColor}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRImage" ? (
                                  <>
                                    <QRImage
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      type={businessesList.prettyQrSms?.properties?.type}
                                      size={businessesList.prettyQrSms?.properties?.size}
                                      opacity={businessesList.prettyQrSms?.properties?.opacity}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      darkColor={
                                        businessesList.prettyQrSms?.properties?.darkColor
                                      }
                                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                                      lightColor={
                                        businessesList.prettyQrSms?.properties?.lightColor
                                      }
                                      image={businessesList.prettyQrSms?.properties?.image}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRBubble" ? (
                                  <>
                                    <QRBubble
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      circleColor={
                                        businessesList.prettyQrSms?.properties?.circleColor
                                      }
                                      posColor={businessesList.prettyQrSms?.properties.posColor}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRFunc" ? (
                                  <>
                                    <QRFunc
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      funcType={businessesList.prettyQrSms?.properties?.funcType}
                                      type={businessesList.prettyQrSms?.properties?.type}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      otherColor1={businessesList.prettyQrSms?.properties?.otherColor1}
                                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRLine" ? (
                                  <>
                                    <QRLine
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      direction={businessesList.prettyQrSms?.properties?.direction}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                                      lineColor={businessesList.prettyQrSms?.properties?.lineColor}
                                      lineOpacity={businessesList.prettyQrSms?.properties?.lineOpacity}
                                      lineWidth={businessesList.prettyQrSms?.properties?.lineWidth}
                                      icon={businessesList.prettyQrSms?.properties?.icon}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={businessesList.qrCodePicWithLogo}
                                      alt="Qr-Code"
                                      className="img-fluid"
                                      width={400}
                                    />{" "}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="inline_td" valign="top">
                <table cellPadding={0} cellSpacing={0}>
                  <tbody>
                    <tr>
                      <td>
                        <div className="position-relative">
                          <img src="/car.jpg" className="right-car-img" />
                          <img src="/iphone.png" className="iphone-img" />
                          <div className="iphone-inner">
                            <table cellPadding={0} cellSpacing={0} className="w-100">
                              <tbody>
                                <tr>
                                  <td valign="top">
                                    <table cellPadding={0} cellSpacing={0} className="w-100 overflow-hidden">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <table cellPadding={0} cellSpacing={0} className="top_table">
                                              <tbody>
                                                <tr>
                                                  <td valign="top" width="50%">
                                                    <a href="#" className="color-0079ff text-decoration-none">
                                                      <img src="/arrow-back-ios.png" width="8px" className="float-left margin-right-3px" />
                                                      <span className="color-0079ff font-size-12px font-weight-bold">Contact</span>
                                                    </a>
                                                  </td>

                                                  <td valign="top" width="50%" align="right">
                                                    <a href="#" className="text-decoration-none color-0079ff font-size-12px font-weight-bold">Edit</a>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colSpan={2} valign="top" width="50%" className="text-center p-0">
                                                    {1 == businessesList.isIndividual ? (
                                                      <div className="avatar">
                                                        <img
                                                          style={{ width: "100px" }}
                                                          className="img-fluid rounded-circle mx-auto "
                                                          src={businessesList.brandPic ? businessesList.brandPicUser : base64Image}
                                                          alt=""
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div className="avatar business">
                                                        <img
                                                          style={{ width: "80px" }}
                                                          className="img-fluid img-fluid-business mx-auto"
                                                          src={businessesList.brandPic ? businessesList.brandPicUser : base64Image}
                                                          alt=""
                                                        />
                                                      </div>
                                                    )}
                                                    <h2 className="font-size-16px m-0" style={{ marginTop: 2 }}>{1 == businessesList.isIndividual ?
                                                      businessesList.firstName + " " + businessesList.lastName : businessesList.organization
                                                    }</h2>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table cellPadding={0} cellSpacing={0} className="comm_table">
                                              <tbody>
                                                <tr>
                                                  <td valign="top" width="20%" className="text-center pb-2">
                                                    <a href="#" className="text-decoration-none">
                                                      <img src="/chat.png" width="30px" />
                                                      <p className="color-0079ff font-size-12px m-0">message</p>
                                                    </a>
                                                  </td>
                                                  <td valign="top" width="20%" className="text-center pb-2">
                                                    <a href="#" className="text-decoration-none">
                                                      <img src="/phon.png" width="30px" />
                                                      <p className="color-0079ff font-size-12px m-0">call</p>
                                                    </a>
                                                  </td>
                                                  <td valign="top" width="20%" className="text-center pb-2">
                                                    <a href="#" className="text-decoration-none">
                                                      <img src="/video.png" width="30px" />
                                                      <p className="color-0079ff font-size-12px m-0">video</p>
                                                    </a>
                                                  </td>
                                                  <td valign="top" width="20%" className="text-center pb-2">
                                                    <a href="#" className="text-decoration-none">
                                                      <img src="/email.png" width="30px" />
                                                      <p className="color-0079ff font-size-12px m-0">mail</p>
                                                    </a>
                                                  </td>
                                                  <td valign="top" width="20%" className="text-center pb-2">
                                                    <a href="#" className="text-decoration-none">
                                                      <img src="/pay.png" width="30px" />
                                                      <p className="color-0079ff font-size-12px m-0">pay</p>
                                                    </a>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table cellPadding={0} cellSpacing={0} className="comm_table">
                                              <tbody>
                                                <tr>
                                                  <td valign="top" width="100%" className="font-size-12px font-weight-500">
                                                    {businessesList?.BusinessMobileNumbers?.map(
                                                      (businessMobileNumbersValue: any, index: any) => {
                                                        return (
                                                          <>
                                                            <div
                                                              className="d-flex flex-wrap align-items-center justify-content-between  user-mail-detail-item"
                                                              key={index}
                                                            >
                                                              <div>
                                                                <div className="text-muted">
                                                                  {
                                                                    businessMobileNumbersValue.mobileNumberLabel
                                                                  }
                                                                </div>
                                                                <div className="text-primary">
                                                                  {
                                                                    businessMobileNumbersValue.internationalFormattedMobileNumber
                                                                  }
                                                                </div>
                                                              </div>
                                                              <div className="d-flex align-items-center gap-4">
                                                                <a
                                                                  href={`tel:${businessMobileNumbersValue.internationalFormattedMobileNumber}`}
                                                                >
                                                                  <i className="fa-solid fa-phone fa-lg text-success"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table cellPadding={0} cellSpacing={0} className="comm_table">
                                              <tbody>
                                                <tr>
                                                  <td valign="top" width="100%" className="font-size-12px font-weight-500">
                                                    {businessesList?.BusinessEmailAddresses?.map(
                                                      (BusinessEmailAddressesValue: any, index: any) => {
                                                        return (
                                                          <div
                                                            className="d-flex flex-wrap align-items-center justify-content-between user-mail-detail-item"
                                                            key={index}
                                                          >
                                                            <div>
                                                              <div className="text-muted">
                                                                {
                                                                  BusinessEmailAddressesValue.emailAddressLabel
                                                                }
                                                              </div>
                                                              <div className="text-primary">
                                                                {
                                                                  BusinessEmailAddressesValue.lowerFormattedEmailAddress
                                                                }
                                                              </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                              <a
                                                                href={`mailto: ${BusinessEmailAddressesValue.lowerFormattedEmailAddress}`}
                                                              >
                                                                <i className="fa-solid fa-envelope fa-lg text-muted"></i>
                                                              </a>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table cellPadding={0} cellSpacing={0} className="comm_table">
                                              <tbody>
                                                <tr>
                                                  <td valign="top" width="100%" className="font-size-12px font-weight-500">
                                                    {businessesList?.BusinessAddresses?.map(
                                                      (BusinessAddressesValue: any, index: any) => {
                                                        return (
                                                          <div
                                                            className="d-flex flex-wrap align-items-center justify-content-between user-mail-detail-item"
                                                            key={index}
                                                          >
                                                            <div>
                                                              <div className="text-muted">
                                                                {BusinessAddressesValue.label}
                                                              </div>
                                                              <div className="text-primary">
                                                                {BusinessAddressesValue.street}
                                                              </div>
                                                              <div className="text-primary">
                                                                {BusinessAddressesValue.postalCode}
                                                              </div>
                                                              <div className="text-primary">
                                                                {BusinessAddressesValue.city}
                                                              </div>
                                                              <div className="text-primary">
                                                                {BusinessAddressesValue.stateProvince}
                                                              </div>
                                                              <div className="text-primary">
                                                                {BusinessAddressesValue.countryRegion}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table cellPadding={0} cellSpacing={0} className="comm_table">
                                              <tbody>
                                                <tr>
                                                  <td valign="top" width="100%" className="font-size-12px font-weight-500">
                                                    {businessesList?.BusinessWebsites?.map(
                                                      (BusinessWebsitesValue: any, index: any) => {
                                                        return (
                                                          <>
                                                            {BusinessWebsitesValue.websiteAddress && (
                                                              <div
                                                                className="d-flex flex-wrap flex-column user-mail-detail-item"
                                                                key={index}
                                                              >
                                                                {BusinessWebsitesValue.websiteAddress == "" ? "" :
                                                                  <div className="text-muted">
                                                                    {BusinessWebsitesValue.websiteLabel}
                                                                  </div>
                                                                }
                                                                <a
                                                                  href={BusinessWebsitesValue.websiteAddress}
                                                                  target="_blank"
                                                                  className="break-all"
                                                                >
                                                                  {BusinessWebsitesValue.websiteAddress}
                                                                </a>
                                                              </div >
                                                            )}
                                                          </>
                                                        )
                                                      })}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="brocherQr" className="hidden size-5by7">
        <table cellPadding={0} cellSpacing={0} style={{ width: "100%" }} >
          <tbody>
            <tr>
              <td className="" valign="top">
                <div className="brocher-upper position-relative">
                  {/* <img src="/car.jpg" className="w-100 object-fit-cover" /> */}
                  <img src="/iphone.png" className="iphone-img" />
                  <div className="iphone-inner">
                    <table className="mx-auto">
                      <tbody>
                        <tr>
                          <td valign="top">
                            <table width="100%" className="w-100 height-150px overflow-hidden">
                              <tbody>
                                <tr>
                                  <td>
                                    <table className="w-100 margin-55px-auto-10px-auto">
                                      <tbody>
                                        <tr>
                                          <td valign="top" width="50%">
                                            <a href="#" className="text-decoration-none color-0079ff">
                                              <img src="/arrow-back-ios.png" width="8px" className="float-left margin-right-5px" />
                                              <span className="color-0079ff font-size-11px fw-bold">Contact</span>
                                            </a>
                                          </td>
                                          <td valign="top" width="50%" align="right">
                                            <a href="#" className="text-decoration-none color-0079ff font-size-11px fw-bold">Edit</a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2} valign="top" width="100%" className="text-center">
                                            {1 == businessesList.isIndividual ? (
                                              <div className="avatar">
                                                <img
                                                  style={{ width: "60px" }}
                                                  className="img-fluid rounded-circle mx-auto "
                                                  src={businessesList.brandPic ? businessesList.brandPicUser : base64Image}
                                                  alt=""
                                                />
                                              </div>
                                            ) : (
                                              <div className="avatar business">
                                                <img
                                                  style={{ width: "60px" }}
                                                  className="img-fluid img-fluid-business mx-auto"
                                                  src={businessesList.brandPic ? businessesList.brandPicUser : base64Image}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <h2 className="font-size-16px m-0" style={{ marginTop: 2 }}>{1 == businessesList.isIndividual ?
                                              businessesList.firstName + " " + businessesList.lastName : businessesList.organization
                                            }</h2>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table className="text-center w-100 border-bottom ">
                                      <tbody>
                                        <tr>
                                          <td valign="top" width="20%" className="text-center pb-2">
                                            <a href="#" className="text-decoration-none">
                                              <img src="/chat.png" width="30px" />
                                              <p className="p_design">message</p>
                                            </a>
                                          </td>
                                          <td valign="top" width="20%" className="text-center pb-2">
                                            <a href="#" className="text-decoration-none">
                                              <img src="/phon.png" width="30px" />
                                              <p className="p_design">call</p>
                                            </a>
                                          </td>
                                          <td valign="top" width="20%" className="text-center pb-2">
                                            <a href="#" className="text-decoration-none">
                                              <img src="/video.png" width="30px" />
                                              <p className="p_design">video</p>
                                            </a>
                                          </td>
                                          <td valign="top" width="20%" className="text-center pb-2">
                                            <a href="#" className="text-decoration-none">
                                              <img src="/email.png" width="30px" />
                                              <p className="p_design">mail</p>
                                            </a>
                                          </td>
                                          <td valign="top" width="20%" className="text-center pb-2">
                                            <a href="#" className="text-decoration-none">
                                              <img src="/pay.png" width="30px" />
                                              <p className="p_design">pay</p>
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table className="comm_table">
                                      <tbody>
                                        <tr>
                                          <td valign="top" width="100%" className="font-size-11px font-weight-500">

                                            {businessesList?.BusinessMobileNumbers?.map(
                                              (businessMobileNumbersValue: any, index: any) => {
                                                return (
                                                  <>
                                                    <div
                                                      className="d-flex flex-wrap align-items-center justify-content-between  user-mail-detail-item"
                                                      key={index}
                                                    >
                                                      <div className="text-muted">
                                                        {
                                                          businessMobileNumbersValue.mobileNumberLabel
                                                        }
                                                      </div>
                                                      <div className="text-primary w-100 d-flex justify-content-between">
                                                        {
                                                          businessMobileNumbersValue.internationalFormattedMobileNumber
                                                        }
                                                        <i className="fa-solid fa-phone fa-lg text-success"></i>
                                                      </div>
                                                      <div className="d-flex align-items-center gap-4">
                                                        <a
                                                          href={`tel:${businessMobileNumbersValue.internationalFormattedMobileNumber}`}
                                                        >

                                                        </a>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table className="comm_table">
                                      <tbody>
                                        <tr>
                                          <td valign="top" width="100%" className="font-size-11px font-weight-500">
                                            {businessesList?.BusinessEmailAddresses?.map(
                                              (BusinessEmailAddressesValue: any, index: any) => {
                                                return (
                                                  <div
                                                    className="d-flex flex-wrap align-items-center justify-content-between user-mail-detail-item"
                                                    key={index}
                                                  >
                                                    <div className="text-muted">
                                                      {
                                                        BusinessEmailAddressesValue.emailAddressLabel
                                                      }
                                                    </div>
                                                    <div className="text-primary w-100 d-flex justify-content-between">
                                                      {
                                                        BusinessEmailAddressesValue.lowerFormattedEmailAddress
                                                      }
                                                      <i className="fa-solid ms-auto fa-envelope fa-lg text-muted"></i>
                                                    </div>

                                                    <a
                                                      href={`mailto: ${BusinessEmailAddressesValue.lowerFormattedEmailAddress}`}
                                                    >

                                                    </a>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table className="comm_table">
                                      <tbody>
                                        <tr>
                                          <td valign="top" width="100%" className="font-size-11px font-weight-500">
                                            {businessesList?.BusinessAddresses?.map(
                                              (BusinessAddressesValue: any, index: any) => {
                                                return (
                                                  <div
                                                    className="d-flex flex-wrap align-items-center justify-content-between user-mail-detail-item"
                                                    key={index}
                                                  >
                                                    <div>
                                                      <div className="text-muted">
                                                        {BusinessAddressesValue.label}
                                                      </div>
                                                      <div className="text-primary">
                                                        {BusinessAddressesValue.street}
                                                      </div>
                                                      <div className="text-primary">
                                                        {BusinessAddressesValue.postalCode}
                                                      </div>
                                                      <div className="text-primary">
                                                        {BusinessAddressesValue.city}
                                                      </div>
                                                      <div className="text-primary">
                                                        {BusinessAddressesValue.stateProvince}
                                                      </div>
                                                      <div className="text-primary">
                                                        {BusinessAddressesValue.countryRegion}
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table className="comm_table">
                                      <tbody>
                                        <tr>
                                          <td valign="top" width="100%" className="font-size-11px font-weight-500">
                                            {businessesList?.BusinessWebsites?.map(
                                              (BusinessWebsitesValue: any, index: any) => {
                                                return (
                                                  <>
                                                    {BusinessWebsitesValue.websiteAddress && (
                                                      <div
                                                        className="d-flex flex-wrap flex-column user-mail-detail-item"
                                                        key={index}
                                                      >
                                                        {BusinessWebsitesValue.websiteAddress == "" ? "" :
                                                          <div className="text-muted">
                                                            {BusinessWebsitesValue.websiteLabel}
                                                          </div>
                                                        }
                                                        <a
                                                          href={BusinessWebsitesValue.websiteAddress}
                                                          target="_blank"
                                                          className="break-all"
                                                        >
                                                          {BusinessWebsitesValue.websiteAddress}
                                                        </a>
                                                      </div >
                                                    )}
                                                  </>
                                                )
                                              })}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="padding-0">
                <table cellPadding={0} cellSpacing={0} className="bottom_table">
                  <tbody>
                    <tr>
                      <td className="bottom_td_table">
                        <div className="brocher-lower position-relative">
                          <div className="position-relative">
                            <h2 className="new_add_text">Save the service department's<br /> contacts right on our phone</h2>
                            <img src="/arrow_shape.png" className="arrow-indicator" />
                          </div>
                          <div className="">
                            <div className="float-left d-inline-block listin_parent">
                              <ul>
                                <li className="">Scan the OR! Click the link that pops up, then click "download card." </li>
                                <li className="">On iPhone: click the export button <img src="/upload_icon.png" /> then "Contacts," then "Save." </li>
                                <li className="">On Androids: select the account where you'd like to save the contact.</li>
                              </ul>
                            </div>
                            <div className="float-right barcode-right-img">
                              <div className="bg-white">
                                {businessesList.prettyQrSms?.qrType === "QRNormal" ? (
                                  <>
                                    <QRNormal
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      type={businessesList.prettyQrSms?.properties?.type}
                                      size={businessesList.prettyQrSms?.properties?.size}
                                      opacity={businessesList.prettyQrSms?.properties?.opacity}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      otherColor={
                                        businessesList.prettyQrSms?.properties?.otherColor
                                      }
                                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                                      icon={businessesList.prettyQrSms?.properties?.icon}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRDsj" ? (
                                  <>
                                    <QRDsj
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      scale={businessesList.prettyQrSms?.properties?.scale}
                                      crossWidth={businessesList.prettyQrSms?.properties?.crossWidth}
                                      posWidth={businessesList.prettyQrSms?.properties?.posWidth}
                                      icon={businessesList.prettyQrSms?.properties?.icon}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QR25D" ? (
                                  <>
                                    <QR25D
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      height={businessesList.prettyQrSms?.properties?.height}
                                      posHeight={
                                        businessesList.prettyQrSms?.properties?.posHeight
                                      }
                                      leftColor={
                                        businessesList.prettyQrSms?.properties?.leftColor
                                      }
                                      rightColor={
                                        businessesList.prettyQrSms?.properties?.rightColor
                                      }
                                      topColor={businessesList.prettyQrSms?.properties?.topColor}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRImage" ? (
                                  <>
                                    <QRImage
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      type={businessesList.prettyQrSms?.properties?.type}
                                      size={businessesList.prettyQrSms?.properties?.size}
                                      opacity={businessesList.prettyQrSms?.properties?.opacity}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      darkColor={
                                        businessesList.prettyQrSms?.properties?.darkColor
                                      }
                                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                                      lightColor={
                                        businessesList.prettyQrSms?.properties?.lightColor
                                      }
                                      image={businessesList.prettyQrSms?.properties?.image}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRBubble" ? (
                                  <>
                                    <QRBubble
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      circleColor={
                                        businessesList.prettyQrSms?.properties?.circleColor
                                      }
                                      posColor={businessesList.prettyQrSms?.properties.posColor}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRFunc" ? (
                                  <>
                                    <QRFunc
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      funcType={businessesList.prettyQrSms?.properties?.funcType}
                                      type={businessesList.prettyQrSms?.properties?.type}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      otherColor1={businessesList.prettyQrSms?.properties?.otherColor1}
                                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                                    />
                                  </>
                                ) : businessesList.prettyQrSms?.qrType === "QRLine" ? (
                                  <>
                                    <QRLine
                                      level="L"
                                      value={businessesList.prettyQrSms?.properties?.valueMMS}
                                      direction={businessesList.prettyQrSms?.properties?.direction}
                                      posType={businessesList.prettyQrSms?.properties?.posType}
                                      posColor={businessesList.prettyQrSms?.properties?.posColor}
                                      lineColor={businessesList.prettyQrSms?.properties?.lineColor}
                                      lineOpacity={businessesList.prettyQrSms?.properties?.lineOpacity}
                                      lineWidth={businessesList.prettyQrSms?.properties?.lineWidth}
                                      icon={businessesList.prettyQrSms?.properties?.icon}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={businessesList.qrCodePicWithLogo}
                                      alt="Qr-Code"
                                      className="img-fluid"
                                    />{" "}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BusinessViewPage;