import React, { FC, useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IUserRegister } from "../types/register.type";
import { register } from "../services/register.service";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import YupPassword from "yup-password";
import { useTranslation } from "react-i18next";
import ModalOverlay from "../components/modal";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import { ImageEditor } from "../components/imageEditor";

YupPassword(Yup);

interface UserRegisterPageProps {
  title: string;
}

const UserRegisterPage: FC<UserRegisterPageProps> = ({ title }) => {
  const { t } = useTranslation("translate");

  const initialValueUserRegistration: IUserRegister = {
    firstName: "",
    lastName: "",
    loginEmail: "",
    password: "",
    organizationName: "",
    mobileNumber: "",
    contactEmail: "",
  };
  const [profileImg, setProfileImg] = useState<any>();
  const [orgLogo, setOrgLogo] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<any>("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [brandFiles, setBrandFiles] = useState<any[]>([]);
  const [message, setMessage] = useState<string>("");
  const [successful, setSuccessful] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);

  let navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    lastName: Yup.string()
      // .trim('The Lastname cannot include leading and trailing Spaces')
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    loginEmail: Yup.string()
      .required("This field is required!")
      .email("This is not a valid email!"),
    password: Yup.string()
      .required("This field is required!")
      .minLowercase(1, "password must contain at least 1 lower case letter")
      .minUppercase(1, "password must contain at least 1 upper case letter")
      .minNumbers(1, "password must contain at least 1 number")
      .minSymbols(1, "password must contain at least 1 special character")
      .test(
        "len",
        "The password must be between 6 to 40 characters.",
        (val: any) =>
          val && val.toString().length >= 6 && val.toString().length <= 40
      ),
    organizationName: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    contactEmail: Yup.string().email("This is not a valid email!"),
  });
  const handleUserRegister = async (formValue: IUserRegister) => {
    let {
      firstName,
      lastName,
      loginEmail,
      password,
      organizationName,
      mobileNumber,
      contactEmail,
    } = formValue;
    // let mobileNumber = phoneNumber
    firstName = firstName.trim();
    lastName = lastName.trim();
    organizationName = organizationName.trim();
    /// converting base64 image to binary ///

    /// converting base64 image to binary ///
    let base64Response;
    let profilePic: any;
    if (profileImg) {
      base64Response = await fetch(profileImg);
      profilePic = await base64Response.blob();
    }
    let base64ResponseLogo;
    let organizationLogo: any;
    if (orgLogo) {
      base64ResponseLogo = await fetch(orgLogo);
      organizationLogo = await base64ResponseLogo.blob();
    }

    //  setBrandFiles(imageFile)

    if (phoneNumber) {
      register(
        firstName,
        lastName,
        loginEmail,
        password,
        organizationName,
        phoneNumber,
        contactEmail,
        organizationLogo,
        profilePic
      ).then(
        (response) => {
          toast.success("Registered Successfully Please Verify Your Email!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
          navigate("/login");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.errorMessage) ||
            error.response.data.errors[0].constraints.isNotEmpty ||
            error.errorMessage ||
            error.toString();
          setSuccessful(false);
          setMessage(resMessage);
        }
      );
    } else {
      setPhoneError(true);
    }
  };

  const [countryCode, setCountryCode] = useState<any>("");

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryCode(data.country_code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function phoneNumberHandler(number: any) {
    setPhoneNumber(number);
    setPhoneError(false);
  }

  function getLogoHandler(data: any) {
    if (data?.status != "removed") {
      setOrgLogo(data?.thumbUrl);
    } else {
      setOrgLogo(undefined);
    }
  }

  function getImageHandler(data: any) {
    if (data?.status != "removed") {
      setProfileImg(data?.thumbUrl);
    } else {
      setProfileImg(undefined);
    }
  }
  return (
    <>
      <div
        id="layoutAuthentication_content"
        className="d-flex flex-column justify-content-center"
      >
        <main>
          <div className="container py-3">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="card shadow border-0">
                  <div className="card-body p-4">
                    <h4 className="text-center fw-semibold mb-5">
                      {t("registration.lblRegistration")}
                    </h4>

                    <Formik
                      initialValues={initialValueUserRegistration}
                      onSubmit={handleUserRegister}
                      validationSchema={validationSchema}
                      enableReinitialize={false}
                    >
                      <Form>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-floating mb-4">
                              <Field
                                className="form-control"
                                id="inputFirstName"
                                name="firstName"
                                type="text"
                                placeholder="Enter your first name"
                              />
                              <label htmlFor="inputFirstName">
                                {t("registration.lblFirstName")}
                              </label>
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-danger txt-error position-absolute"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating mb-4">
                              <Field
                                className="form-control"
                                id="inputLastName"
                                name="lastName"
                                type="text"
                                placeholder="Enter your last name"
                              />
                              <label htmlFor="inputLastName">
                                {t("registration.lblLastName")}
                              </label>
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-danger txt-error position-absolute"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-floating mb-4">
                              <Field
                                className="form-control"
                                id="inputEmail"
                                name="loginEmail"
                                type="email"
                                placeholder="name@example.com"
                              />
                              <label htmlFor="inputLoginEmail">
                                {t("registration.lblLoginEmail")}
                              </label>
                              <ErrorMessage
                                name="loginEmail"
                                component="div"
                                className="text-danger txt-error position-absolute"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating mb-4">
                              <Field
                                className="form-control"
                                id="inputPassword"
                                name="password"
                                type="password"
                                placeholder="Password"
                              />
                              <label htmlFor="inputPassword">
                                {t("registration.lblPassword")}
                              </label>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger txt-error position-absolute"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-floating mb-4">
                              <Field
                                className="form-control"
                                id="inputOrganization"
                                name="organizationName"
                                type="text"
                                placeholder="Enter your organization name"
                              />
                              <label htmlFor="inputOrganization">
                                {t("registration.lblOrganizationName")}
                              </label>
                              <ErrorMessage
                                name="organizationName"
                                component="div"
                                className="text-danger txt-error position-absolute"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="lh-sm border rounded mb-4">
                              <label
                                htmlFor="inputMobileNmber"
                                className="ps-2 pt-1"
                              >
                                {t("registration.lblOrganizationMobileNumber")}
                              </label>
                              <PhoneInput
                                defaultCountry={countryCode}
                                value={phoneNumber}
                                onChange={(e) => phoneNumberHandler(e)}
                                className="border-0 pt-0 form-control PhoneInputInput"
                                placeholder="Enter phone number"
                                name="mobileNumber"
                              />
                              {phoneError ? (
                                <p className="text-danger txt-error position-absolute mt-1">
                                  This field is required!
                                </p>
                              ) : (
                                ""
                              )}
                              {/* <Field className="form-control" id="inputMobileNumber" name="mobileNumber" type="text" placeholder="Enter your organization name" /> */}

                              {/* {message && (
                          <div className="form-group">
                            <div
                              className={
                                successful
                                  ? "alert alert-success"
                                  : "alert alert-danger p-2 border-0"
                              }
                              role="alert"
                            >
                              {message}
                            </div>
                          </div>
                        )} */}
                              {/* <ErrorMessage
                                name="mobileNumber"
                                component="div"
                                className="text-danger txt-error"
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-floating mb-4">
                              <Field
                                className="form-control"
                                id="inputEmail"
                                name="contactEmail"
                                type="text"
                                placeholder="Enter your organization name"
                              />
                              <label htmlFor="inputEmail">
                                {t("registration.lblContactEmail")}
                              </label>
                              <ErrorMessage
                                name="contactEmail"
                                component="div"
                                className="text-danger txt-error position-absolute"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 justify-content-between">
                          <div className="col-6 col-lg-4">
                            <div className="lh-sm mb-4   text-center">
                              <div className="mb-4">
                                <label htmlFor="inputOrganization">
                                  {t("registration.lblProfilePicture")}
                                </label>
                              </div>

                              <div className="d-inline mousePointer">
                                <ImageEditor getImage={getImageHandler} />
                              </div>
                            </div>
                          </div>
                          <div className="col-6 col-lg-4">
                            <div className="lh-sm  text-center">
                              <div className="mb-4">
                                <label htmlFor="inputOrganization">
                                  {t("registration.lblOrganizationLogo")}
                                </label>
                              </div>

                              <div className="d-inline  mousePointer">
                                <ImageEditor getImage={getLogoHandler} />
                              </div>
                            </div>
                          </div>
                        </div>
                        {message && (
                          <div className="form-group">
                            <div
                              className={
                                successful
                                  ? "alert alert-success"
                                  : "alert alert-danger p-2 border-0"
                              }
                              role="alert"
                            >
                              {message}
                            </div>
                          </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <div className="small">
                              {t("registration.lblText")}{" "}
                              <Link to="/login" className="text-decoration-none">
                                {t("registration.lblLoginLink")}
                              </Link>
                            </div>
                          </div>
                          <div>
                            <button
                              className="btn btn-primary btn-block me-1"
                              type="submit"
                            >
                              {t("registration.btnClickRegister")}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default UserRegisterPage;
