/* eslint-disable no-mixed-operators */
/* eslint-disable no-empty-pattern */
import React, { FC, useEffect, useState,useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IBusiness } from "../types/business.type";
import * as Yup from "yup";
import {
  addTypeLabel,
  businessGetBybusinessRef,
  businessList,
  businessUpdate,
  getEmailLabelData,
  getMobileLabelData,
  getWebsiteLabelData,
  getAddressLabelData,
  deleteImage,
} from "../services/business.service";
import { toast } from "react-toastify";
import * as bootstrap from "bootstrap";
import { ILabel } from "../types/label.type";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { ImageEditor } from "../components/imageEditor";

interface BusinessUpdatePageProps {
  title: string;
}

const BusinessUpdatePage: FC<BusinessUpdatePageProps> = ({ }) => {
  const { t } = useTranslation("translate");
  const itemNameMobileNumber = {
    userGivenMobileNumber: "",
    mobileNumberLabel: "",
  };
  const [elemNamesMobileNumbers, setElemNamesMobileNumbers] = useState([
    itemNameMobileNumber,
  ]);
  const itemNameEmail = {
    userGivenEmailAddress: "",
    emailAddressLabel: "",
  };
  const [elemNamesEmails, setElemNamesEmails] = useState([itemNameEmail]);
  const itemNameWebsite = { websiteAddress: "", websiteLabel: "" };
  const [elemNamesWebsites, setElemNamesWebsites] = useState([itemNameWebsite]);
  const itemNameAddress = {
    address: "",
    label: "",
    street: "",
    city: "",
    stateProvince: "",
    postalCode: "",
    countryRegion: "",
  };
  const [elemNamesAddress, setElemNamesAddress] = useState([itemNameAddress]);
  const [isIndividual, setIsIndividual] = useState("1");
  const [followUpBusinessId, setFollowUpBusinessId] = useState("");
  const [busnessList, setBusinessList] = useState<any[]>([]);
  const [noteData, setNoteData] = useState<string>("");
  const [totalChar, setTotalChar] = useState<number>(512);
  const [mobilesLastLabel, setMobilesLastLabel] = useState<any>([]);
  const [emailLastLabel, setEmailLastLabel] = useState<any>([]);
  const [addressLastLabel, setAddressLastLabel] = useState<any>([]);
  const [websiteLastLabel, setWebsiteLastLabel] = useState<any>([]);
  const [currentPhoneIndex, setCurrentPhoneIndex] = useState<any>();
  const [currentEmailIndex, setCurrentEmailIndex] = useState<any>();
  const [currentAddressIndex, setCurrentAddressIndex] = useState<any>();
  const [currentWebsiteIndex, setCurrentWebsiteIndex] = useState<any>();

  const initialValuesBusinessRegister: IBusiness = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    title: "",
    organization: "",
    websites: [],
    mobileNumbers: [],
    emails: [],
    note: "",
    qrCodeText: "",
  };
  const initialValues: ILabel = {
    labelType: "",
    labelText: "",
  };
  let navigate = useNavigate();
  let { businessRef } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [message, setMessage] = useState<string>("");
  const [successful, setSuccessful] = useState<boolean>(false);
  const [emailmessage, setEmailMessage] = useState("");
  const [mobileMessage, setMobileMessage] = useState("");
  const [websiteMessage, setWebsiteMessage] = useState("");
  const [labelMessage, setLabelMessage] = useState("");
  const [nextId, setNextId] = useState(0);
  const [nextIdEmail, setNextIdEmail] = useState(0);
  const [nextIdAddress, setNextIdAddress] = useState(0);
  const [nextIdWebsite, setNextIdWebsite] = useState(0);
  const [brandPic, setBrandPic] = useState<any>();
  const [couter, setCounter] = useState<boolean>(false);

  const [bussinessData, setBussinessData] = useState(
    initialValuesBusinessRegister
  );
  const [emailLabel, setEmailLabel] = useState<any[]>([]);
  const [mobileLabel, setMobileLabel] = useState<any[]>([]);
  const [addressLabel, setAddressLabel] = useState<any[]>([]);
  const [websiteLabel, setWebsiteLabel] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectDropdown, setSelectDropDown] = useState("");
  const [editedImg, setEditedImg] = useState();


  const validationSchemaBusinessRegister = Yup.object().shape({
    firstName: Yup.string().nullable(true),
    lastName: Yup.string().nullable(true),
    title: Yup.string().nullable(true),
    organization: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    note: Yup.string().nullable(true),
    qrCodeText: Yup.string().nullable(true),
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    lastName: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    title: Yup.string()
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    organization: Yup.string()
      .required("This field is required!")
      .strict(true)
      .matches(/[a-zA-Z]/, "Special characters and Numbers are not allowed"),
    note: Yup.string().nullable(true),
    qrCodeText: Yup.string().nullable(true),
  });
  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = async () => {
    window.scrollTo(0,0)
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handleBusinessUpdate = async (formValue: IBusiness) => {
    let { firstName, lastName, title, organization } = formValue;
    firstName = firstName.trim();
    lastName = lastName.trim();
    title = title.trim();
    // converting base64 image to binary //
    let finalImg
    let base64Response;
    let imageFile;
    let isImageChanged = 0;
    if (editedImg) {
      isImageChanged = 1;
      finalImg = editedImg;
    } else if (brandPic) {
      finalImg = brandPic;
    } else {
      isImageChanged = 1;
      finalImg = undefined;
    }
    if (finalImg != undefined && finalImg != "" && 1 == isImageChanged) {
      base64Response = await fetch(finalImg);
      imageFile = await base64Response.blob();
    }
    organization = organization.trim();
    if (elemNamesMobileNumbers[0].userGivenMobileNumber === undefined) {
      toast.error("Please add at least one mobile number", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (elemNamesEmails[0].userGivenEmailAddress === "") {
      if (elemNamesEmails[0].userGivenEmailAddress === "") {
        toast.error("Please add at least one email", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }
    if (
      !!elemNamesMobileNumbers[0].userGivenMobileNumber &&
      elemNamesMobileNumbers[0].userGivenMobileNumber !== "" &&
      !!elemNamesEmails[0].userGivenEmailAddress &&
      elemNamesEmails[0].userGivenEmailAddress !== ""
    ) {
      businessUpdate(
        { ...formValue, firstName, lastName, title, organization },
        imageFile,
        isImageChanged,
        elemNamesMobileNumbers,
        elemNamesEmails,
        elemNamesWebsites,
        elemNamesAddress,
        isIndividual,
        followUpBusinessId,
        noteData
      ).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
          toast.success("Business details are updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          navigate("/dashboard");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.error &&
              error.response.data.error.email) ||
            error.response.data.error.mobileNumber ||
            error.message ||
            error.toString();

          setSuccessful(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.email
          ) {
            setEmailMessage(resMessage);
          }
          if (
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.mobileNumber
          ) {
            setMobileMessage(resMessage);
          }
          if (
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.errors[0] &&
            error.response.data.error.errors[0].messages[0] // messages
          ) {
            let field = error.response.data.error.errors[0].field[0]; //field
            let originPlace = error.response.data.error.errors[0].field[1];
            let place = originPlace + 1;
            let errorMsg = error.response.data.error.errors[0].messages[0];
            let errorMessage = errorMsg.replace('"' + originPlace + '"', place);
            setWebsiteMessage(
              field.charAt(0).toUpperCase() +
              field.slice(1) +
              " " +
              errorMessage
            );
          }
        }
      );
    }
  };
  const handleaddmobilenumberlabel = (formValue: ILabel) => {
    addTypeLabel("mobile", formValue.labelText.trim()).then(
      (response) => {
        const res = [...mobileLabel, response.data.data.newLabel];
        setMobileLabel(res);
        var myModal = bootstrap.Modal.getOrCreateInstance(
          document.getElementById("custom-field-mobile") as HTMLElement
        );
        myModal.hide();
        formValue.labelText = "";
        toast.success("Label added sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLabelMessage("");
        setTimeout(function () {
          getData();
        }, 500);
        mobilesLastLabel[currentPhoneIndex] =
          response.data.data.newLabel.labelText;
        setMobilesLastLabel(mobilesLastLabel);
        setCounter(true);
        let data = [...elemNamesMobileNumbers];
        data[currentPhoneIndex]["mobileNumberLabel"] =
          response.data.data.newLabel.labelText;
        setElemNamesMobileNumbers(data);
      },
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        if (error.response.data.code === 400) {
          setLabelMessage("This is required field!");
        } else {
          setLabelMessage(error.response.data.errorMessage);
        }
        formValue.labelText = "";
      }
    );
  };

  const handladdemaillabel = (formValue: ILabel) => {
    addTypeLabel("email", formValue.labelText.trim()).then(
      (response) => {
        const res = [...emailLabel, response.data.data.newLabel];
        setEmailLabel(res);
        var myModal = bootstrap.Modal.getOrCreateInstance(
          document.getElementById("custom-field-email") as HTMLElement
        );
        myModal.hide();
        formValue.labelText = "";
        toast.success("Label added sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLabelMessage("");
        setTimeout(function () {
          getData();
        }, 500);
        emailLastLabel[currentEmailIndex] =
          response.data.data.newLabel.labelText;
        setEmailLastLabel(emailLastLabel);
        setCounter(true);
        let data = [...elemNamesEmails];
        data[currentEmailIndex]["emailAddressLabel"] =
          response.data.data.newLabel.labelText;
        setElemNamesEmails(data);
      },
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        if (error.response.data.code === 400) {
          setLabelMessage("This is required field!");
        } else {
          setLabelMessage(error.response.data.errorMessage);
        }
        formValue.labelText = "";
      }
    );
  };
  const handleaddAddresslabel = (formValue: ILabel) => {
    addTypeLabel("address", formValue.labelText.trim()).then(
      (response) => {
        const res = [...addressLabel, response.data.data.newLabel];
        setAddressLabel(res);
        var myModal = bootstrap.Modal.getOrCreateInstance(
          document.getElementById("custom-field-address") as HTMLElement
        );
        myModal.hide();
        formValue.labelText = "";
        toast.success("Label added sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setTimeout(function () {
          getData();
        }, 500);
        addressLastLabel[currentAddressIndex] =
          response.data.data.newLabel.labelText;
        setAddressLastLabel(addressLastLabel);
        setCounter(true);
        let data = [...elemNamesAddress];
        data[currentAddressIndex]["label"] =
          response.data.data.newLabel.labelText;
        setElemNamesAddress(data);
      },
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        if (error.response.data.code === 400) {
          setLabelMessage("This is required field!");
        } else {
          setLabelMessage(error.response.data.errorMessage);
        }
        formValue.labelText = "";
      }
    );
  };
  const handleaddWebsitelabel = (formValue: ILabel) => {
    addTypeLabel("website", formValue.labelText.trim()).then(
      (response) => {
        const res = [...websiteLabel, response.data.data.newLabel];
        setWebsiteLabel(res);
        var myModal = bootstrap.Modal.getOrCreateInstance(
          document.getElementById("custom-field-website") as HTMLElement
        );
        myModal.hide();
        formValue.labelText = "";
        toast.success("Label added sucessfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setTimeout(function () {
          getData();
        }, 500);
        websiteLastLabel[currentWebsiteIndex] =
          response.data.data.newLabel.labelText;
        setAddressLastLabel(websiteLastLabel);
        setCounter(true);
        let data = [...elemNamesWebsites];
        data[currentWebsiteIndex]["websiteLabel"] =
          response.data.data.newLabel.labelText;
        setElemNamesWebsites(data);
      },
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();
        if (error.response.data.code === 400) {
          setLabelMessage("This is required field!");
        } else {
          setLabelMessage(error.response.data.errorMessage);
        }
        formValue.labelText = "";
      }
    );
  };
  const getData = async () => {
    const labelEmailData = await getEmailLabelData();
    const labelMobileData = await getMobileLabelData();
    const labeladdressData = await getAddressLabelData();
    const labelwebsiteData = await getWebsiteLabelData();
    const businessData = await businessList();

    setEmailLabel(labelEmailData.data.data.labelsData);
    setMobileLabel(labelMobileData.data.data.labelsData);
    setAddressLabel(labeladdressData.data.data.labelsData);
    setWebsiteLabel(labelwebsiteData.data.data.labelsData);
    setBusinessList(businessData.data.data.businesses.rows);
  };
  const getBusinessDetailBybusinessRef = async (businessRef: string) => {
    if (businessRef !== "0") {
      let businessDetails = await businessGetBybusinessRef(businessRef);

      getData();
      if (businessDetails.status === 200) {
        setBussinessData(businessDetails.data.data.business);
        setIsIndividual(businessDetails.data.data.business.isIndividual);
        setFollowUpBusinessId(
          businessDetails.data.data.business.followUpBusinessId
        );
        setBrandPic(businessDetails.data.data.business.brandPic);
        setNoteData(businessDetails.data.data.business.note);
        if (
          businessDetails.data.data.business.BusinessMobileNumbers.length > 0
        ) {
          setElemNamesMobileNumbers(
            businessDetails.data.data.business.BusinessMobileNumbers
          );
          let mobileLabels =
            businessDetails.data.data.business.BusinessMobileNumbers;
          mobileLabels.map((label: any, index: any) => {
            mobilesLastLabel[index] = label.mobileNumberLabel;
            setMobilesLastLabel(mobilesLastLabel);
          });
        }
        if (
          businessDetails.data.data.business.BusinessEmailAddresses.length > 0
        ) {
          setElemNamesEmails(
            businessDetails.data.data.business.BusinessEmailAddresses
          );
          let emailLabels =
            businessDetails.data.data.business.BusinessEmailAddresses;
          emailLabels.map((labelemail: any, index: any) => {
            emailLastLabel[index] = labelemail.emailAddressLabel;
            setEmailLastLabel(emailLastLabel);
          });
        }
        if (businessDetails.data.data.business.BusinessWebsites.length > 0) {
          setElemNamesWebsites(
            businessDetails.data.data.business.BusinessWebsites
          );
          let websiteLabels =
            businessDetails.data.data.business.BusinessWebsites;
          websiteLabels.map((labelWebsite: any, index: any) => {
            websiteLastLabel[index] = labelWebsite.websiteLabel;
            setWebsiteLastLabel(websiteLastLabel);
          });
        }
        if (businessDetails.data.data.business.BusinessAddresses.length > 0) {
          setElemNamesAddress(
            businessDetails.data.data.business.BusinessAddresses
          );
          let addressLabels =
            businessDetails.data.data.business.BusinessAddresses;
          addressLabels.map((addresslabel: any, index: any) => {
            addressLastLabel[index] = addresslabel.label;
            setAddressLastLabel(addressLastLabel);
          });
        }
      }
    }
  };
  const handleFormChange = (index: any, event: any) => {
    let data = [...elemNamesMobileNumbers];
    if (event !== undefined) {
      data[index]["userGivenMobileNumber"] = event;
      setElemNamesMobileNumbers(data);
      setMobileMessage("");
      setWebsiteMessage("");
    } else {
      data[index]["userGivenMobileNumber"] = event;
      setElemNamesMobileNumbers(data);
    }
  };
  const handleElemRemove = (elemName: any) => {
    const remaningItems = [...elemNamesMobileNumbers].filter(
      (_elemName) => _elemName !== elemName
    );
    remaningItems.map((remainingMobile: any, index: any) => {
      mobilesLastLabel[index] = remainingMobile.numberTitle;
      setMobilesLastLabel(mobilesLastLabel)
    })
    setElemNamesMobileNumbers(remaningItems);
  };
  const handleElemAdd = (e: any) => {
    setElemNamesMobileNumbers([
      ...elemNamesMobileNumbers,
      itemNameMobileNumber,
    ]);
    setNextId(nextId + 1);
    e.preventDefault();
  };

  const handleFormChangeEmail = (index: any, event: any) => {
    let data = [...elemNamesEmails];

    if (event != undefined) {
      data[index]["userGivenEmailAddress"] = event.target.value.trim();
      setElemNamesEmails(data);
      setEmailMessage("");
      setWebsiteMessage("");
    } else {
      data[index]["userGivenEmailAddress"] = event;
      setElemNamesEmails(data);
    }
  };
  const handleElemRemoveEmail = (elemName: any) => {
    const remaningItemsEmail = [...elemNamesEmails].filter(
      (_elemName) => _elemName !== elemName
    );
    remaningItemsEmail.map((remainingEmail: any, index: any) => {
      emailLastLabel[index] = remainingEmail.emailLabel;
      setEmailLastLabel(emailLastLabel)
    })
    setElemNamesEmails(remaningItemsEmail);
  };
  const handleElemAddEmail = (e: any) => {
    setElemNamesEmails([...elemNamesEmails, itemNameEmail]);
    setNextIdEmail(nextIdEmail + 1);
    e.preventDefault();
  };

  const handleFormChangeCountry = (index: any, event: any) => {
    let data = [...elemNamesAddress];
    let regix = /[a-zA-Z]/;
    if (event.target.value != "") {
      if (regix.test(event.target.value)) {
        data[index]["countryRegion"] = event.target.value;
        setElemNamesAddress(data);
        setWebsiteMessage("");
        return;
      }
    } else {
      data[index]["countryRegion"] = "";
      setElemNamesAddress(data);
      setWebsiteMessage("");
      return;
    }
    setWebsiteMessage(
      "Country can not accept only space and special characters"
    );
  };
  const handleFormChangeStreet = (index: any, event: any) => {
    let data = [...elemNamesAddress];
    let regix = /[a-zA-Z0-9]/;
    if (event.target.value != "") {
      if (regix.test(event.target.value)) {
        data[index]["street"] = event.target.value;
        setElemNamesAddress(data);
        setWebsiteMessage("");
        return;
      }
    } else {
      data[index]["street"] = "";
      setElemNamesAddress(data);
      setWebsiteMessage("");
      return;
    }
    setWebsiteMessage(
      "Street can not accept only space and special characters"
    );
  };
  const handleFormChangeCity = (index: any, event: any) => {
    let data = [...elemNamesAddress];
    let regix = /[a-zA-Z]/;
    if (event.target.value != "") {
      if (regix.test(event.target.value)) {
        data[index]["city"] = event.target.value;
        setElemNamesAddress(data);
        setWebsiteMessage("");
        return;
      }
    } else {
      data[index]["city"] = "";
      setElemNamesAddress(data);
      setWebsiteMessage("");
      return;
    }
    setWebsiteMessage("City can not accept only space and special characters");
  };
  const handleFormChangeStsteProvince = (index: any, event: any) => {
    let data = [...elemNamesAddress];
    let regix = /[a-zA-Z]/;
    if (event.target.value != "") {
      if (regix.test(event.target.value)) {
        data[index]["stateProvince"] = event.target.value;
        setElemNamesAddress(data);
        setWebsiteMessage("");
        return;
      }
    } else {
      data[index]["stateProvince"] = "";
      setElemNamesAddress(data);
      setWebsiteMessage("");
      return;
    }
    setWebsiteMessage("State can not accept only space and special characters");
  };
  const handleFormChangePostalCode = (index: any, event: any) => {
    let data = [...elemNamesAddress];
    if (event.target.value != " " && event.target.value != "") {
      if (/^[a-zA-Z0-9 ]+$/i.test(event.target.value)) {
        data[index]["postalCode"] = event.target.value;
        setElemNamesAddress(data);
        setWebsiteMessage("");
        return;
      }
    } else {
      if (data[index]["postalCode"].length > 0) {
        data[index]["postalCode"] = "";
        setElemNamesAddress(data);
        setWebsiteMessage("");
        return;
      } else {
        setWebsiteMessage("Postal code not accepted only spaces");
        return
      }
    }
    setWebsiteMessage("Postal code not accepted special characters");
  };
  const handleElemAddAddress = (e: any) => {
    setElemNamesAddress([...elemNamesAddress, itemNameAddress]);
    setNextIdAddress(nextIdAddress + 1);
    e.preventDefault();
  };
  const handleElemRemoveAddress = (elemName: any) => {
    const remaningItemsAddress = [...elemNamesAddress].filter(
      (_elemName) => _elemName !== elemName
    );
    remaningItemsAddress.map((remainingAddress: any, index: any) => {
      addressLastLabel[index] = remainingAddress.addressLabel;
      setAddressLastLabel(addressLastLabel)
    })
    setElemNamesAddress(remaningItemsAddress);
  };
  const handleFormChangeWebsites = (index: any, event: any) => {
    let data = [...elemNamesWebsites];
    if (event != undefined) {
      data[index]["websiteAddress"] = event.target.value;
      setElemNamesWebsites(data);
      setWebsiteMessage("");
    } else {
      data[index]["websiteAddress"] = event;
      setElemNamesWebsites(data);
    }
  };
  const handleElemRemoveWebsites = (elemName: any) => {
    const remaningItemsWebsite = [...elemNamesWebsites].filter(
      (_elemName) => _elemName !== elemName
    );
    remaningItemsWebsite.map((remainingWebsite: any, index: any) => {
      websiteLastLabel[index] = remainingWebsite.websiteLabel;
      setWebsiteLastLabel(websiteLastLabel)
    })
    setElemNamesWebsites(remaningItemsWebsite);
  };

  const handleElemAddWebsites = (e: any) => {
    setElemNamesWebsites([...elemNamesWebsites, itemNameWebsite]);
    setNextIdWebsite(nextIdWebsite + 1);
    e.preventDefault();
  };
  const handleClick = (index: any, e: any) => {
    setCurrentPhoneIndex(index);
    let data = [...elemNamesMobileNumbers];
    if (e.target.value === "Add custom Label") {
      new bootstrap.Modal(
        document.getElementById("custom-field-mobile") as HTMLElement
      ).show();
    } else {
      data[index]["mobileNumberLabel"] = e.target.value;
      setElemNamesMobileNumbers(data);
      setWebsiteMessage("");
      mobilesLastLabel[index] = e.target.value;
      setMobilesLastLabel(mobilesLastLabel);
    }
  };
  const handleAddressClick = (index: any, e: any) => {
    setCurrentAddressIndex(index);
    let data = [...elemNamesAddress];
    if (e.target.value === "Add custom Label") {
      new bootstrap.Modal(
        document.getElementById("custom-field-address") as HTMLElement
      ).show();
      let address: any = document.getElementById("addressname");
      address.value = "";
    } else {
      data[index]["label"] = e.target.value;
      setElemNamesAddress(data);
      addressLastLabel[index] = e.target.value;
      setAddressLastLabel(addressLastLabel);
      setWebsiteMessage("");
    }
  };

  const handleemailclick = (index: any, e: any) => {
    setCurrentEmailIndex(index);
    let data = [...elemNamesEmails];
    if (e.target.value === "Add custom Label") {
      new bootstrap.Modal(
        document.getElementById("custom-field-email") as HTMLElement
      ).show();
    } else {
      data[index]["emailAddressLabel"] = e.target.value;
      setElemNamesEmails(data);
      emailLastLabel[index] = e.target.value;
      setEmailLastLabel(emailLastLabel);
      setWebsiteMessage("");
    }
  };
  const handleWebsiteClick = (index: any, e: any) => {
    setCurrentWebsiteIndex(index);
    let data = [...elemNamesWebsites];
    if (e.target.value === "Add custom Label") {
      new bootstrap.Modal(
        document.getElementById("custom-field-website") as HTMLElement
      ).show();
    } else {
      data[index]["websiteLabel"] = e.target.value;
      setElemNamesWebsites(data);
      websiteLastLabel[index] = e.target.value;
      setWebsiteLastLabel(websiteLastLabel);
      setWebsiteMessage("");
    }
  };
  const handleInvidualClick = (e: any) => {
    if (e.target.value !== "Select One") {
      let data = e.target.value;
      setIsIndividual(data);
      setSelectDropDown(data);
    }
  };
  const handleFollowUpBusinessDataList = (e: any) => {
    if (e.target.value !== "No Follow-Up Dealer Card") {
      let data = e.target.value;
      setFollowUpBusinessId(data);
    }
  };

  const handleClose = () => {
    setLabelMessage("");
  };

  function noteDataHandler(e: any) {
    setNoteData(e.target.value);
  }

  useEffect(() => {
    setTotalChar(512 - noteData.length);
  }, [noteData]);

  useEffect(() => {
    if (!!businessRef) {
      getBusinessDetailBybusinessRef(businessRef);
    }
  }, [businessRef]);

  const [countryCode, setCountryCode] = useState<any>("");

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryCode(data.country_code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function getImageHandler(data: any) {
    if (data?.status != "removed") {
      setEditedImg(data?.thumbUrl);
    } else {
      let obj = {
        businessRfid: businessRef,
      };
      const response = await deleteImage(obj);
      setEditedImg(undefined);
      setBrandPic(undefined);
    }
  }
  return (
    <>
      <main>
        <div className="container-fluid px-lg-4">
          <h3 className="mt-3 mb-4">
            {t("businessUpdatePage.lblBusinessUpate")}
          </h3>
          <div className="card mb-4 card-border">
            <div className="container px-lg-4 py-4">
              <Formik
                initialValues={bussinessData}
                validationSchema={
                  isIndividual == "0"
                    ? validationSchemaBusinessRegister
                    : validationSchema
                }
                ref={formRef}
                onSubmit={handleBusinessUpdate}
                enableReinitialize={true}
              >
                <Form>
                  <>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <select
                          className="form-select py-3"
                          aria-label="Default select example"
                          name="isIndividual"
                          value={isIndividual}
                          onChange={(e: any) => {
                            handleInvidualClick(e);
                          }}
                          disabled={true}
                        >
                          <>
                            <option value="1">
                              {t("businessUpdatePage.lblIndividual")}
                            </option>
                            <option value="0">
                              {t("businessUpdatePage.lblBusiness")}
                            </option>
                          </>
                        </select>
                      </div>
                    </div>

                    {isIndividual == "0" ? (
                      <></>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <select
                              className="form-select py-3"
                              aria-label="Default select example"
                              value={followUpBusinessId}
                              onChange={(e: any) => {
                                handleFollowUpBusinessDataList(e);
                              }}
                            >
                              <option value="0" selected>
                                {t(
                                  "businessUpdatePage.lblNoFollowUpDealerCard"
                                )}
                              </option>
                              <>
                                {!!busnessList &&
                                  busnessList.length > 0 &&
                                  busnessList.map((busnessList, index) => (
                                    <>
                                      <option value={busnessList.businessRef}>
                                        {busnessList.organization}
                                      </option>
                                    </>
                                  ))}
                              </>
                            </select>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-floating">
                              <Field
                                className="form-control"
                                id="inputFirstName"
                                name="firstName"
                                type="text"
                                placeholder="Enter your first name"
                              />
                              <label htmlFor="inputFirstName">
                                {t("businessUpdatePage.lblFirstName")}
                              </label>
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-danger txt-error"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-floating">
                              <Field
                                className="form-control"
                                id="inputLastName"
                                name="lastName"
                                type="text"
                                placeholder="Enter your last name"
                              />
                              <label htmlFor="inputLastName">
                                {t("businessUpdatePage.lblLastName")}
                              </label>
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-danger txt-error"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-floating">
                              <Field
                                className="form-control"
                                id="inputTitle"
                                name="title"
                                type="text"
                                placeholder="Enter your last name"
                              />
                              <label htmlFor="inputTitle">
                                {t("businessUpdatePage.lblTitle")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-floating ">
                          <Field
                            className="form-control"
                            id="inputOrganization"
                            name="organization"
                            type="text"
                            placeholder="Enter your organization name"
                          />
                          <label htmlFor="inputOrganization">
                            {t("businessUpdatePage.lblOrganization")}
                          </label>
                          <ErrorMessage
                            name="organization"
                            component="div"
                            className="text-danger txt-error"
                          />
                        </div>
                      </div>
                    </div>

                    {!!elemNamesMobileNumbers &&
                      elemNamesMobileNumbers.length > 0 &&
                      elemNamesMobileNumbers.map(
                        (elemNamesMobileNumber, index) => (
                          <>
                            <div className="row" key={index}>
                              <div className="col-md-6 mb-2 mb-md-4">
                                <div className="lh-sm border rounded">
                                  <label
                                    className="ps-2 pt-1"
                                    htmlFor="inputMobileNumber"
                                  >
                                    {t("businessRegisterPage.lblMobileNumber")}{" "}
                                    {index + 1}
                                  </label>
                                  <PhoneInput
                                    defaultCountry={countryCode}
                                    name={`BusinessMobileNumbers${index + 1}`}
                                    value={
                                      elemNamesMobileNumber.userGivenMobileNumber
                                    }
                                    onChange={(e: any) =>
                                      handleFormChange(index, e)
                                    }
                                    className="border-0 pt-0 form-control PhoneInputInput"
                                    placeholder="Enter phone number"
                                  />
                                  {/* <input className="form-control" id="inputMobileNumber" name={`BusinessMobileNumbers${index + 1}`} value={elemNamesMobileNumber.userGivenMobileNumber} type="text" placeholder={`Mobile number ${index + 1}`} onChange={(e: any) => handleFormChange(index, e)} /> */}
                                  {/* <label htmlFor="inputMobileNumber">{t("businessUpdatePage.lblMobileNumber")} {index + 1}</label> */}
                                  <ErrorMessage
                                    name="mobileNumber"
                                    component="div"
                                    className="text-danger txt-error"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 mb-2 mt-md-0">
                                <Field
                                  className="form-control py-3"
                                  value={
                                    couter
                                      ? mobilesLastLabel[index]
                                      : elemNamesMobileNumber.mobileNumberLabel
                                  }
                                  as="select"
                                  name="color"
                                  onChange={(e: any) => {
                                    handleClick(index, e);
                                  }}
                                >
                                  <>
                                    {!!mobileLabel &&
                                      mobileLabel.length > 0 &&
                                      mobileLabel.map(
                                        (label: any, index: any) => (
                                          <>
                                            {label?.defaultLabels?.count ==
                                              0 ? (
                                              " "
                                            ) : (
                                              <optgroup label="Default labels">
                                                {label?.defaultLabels?.rows.map(
                                                  (
                                                    defaultLabel: any,
                                                    defaultIndex: any
                                                  ) => {
                                                    return (
                                                      <option
                                                        value={
                                                          defaultLabel.labelText
                                                        }
                                                        key={defaultIndex}
                                                      >
                                                        {defaultLabel.labelText}{" "}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </optgroup>
                                            )}
                                            {label?.customLabels?.count == 0 ? (
                                              " "
                                            ) : (
                                              <optgroup label="Custom labels">
                                                {label?.customLabels?.rows.map(
                                                  (
                                                    customLabel: any,
                                                    customIndex: any
                                                  ) => {
                                                    return (
                                                      <option
                                                        value={
                                                          customLabel.labelText
                                                        }
                                                        key={customIndex}
                                                      >
                                                        {customLabel.labelText}{" "}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </optgroup>
                                            )}
                                          </>
                                        )
                                      )}
                                    <option value="Add custom Label">
                                      {t("businessUpdatePage.addCustomLabel")}
                                    </option>
                                  </>
                                </Field>
                              </div>
                              <div className="col-md-3 mb-4 d-flex align-items-center gap-2">
                                {index + 1 === elemNamesMobileNumbers.length ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      type="button"
                                      disabled={
                                        elemNamesMobileNumbers.length >= 10
                                      }
                                      onClick={handleElemAdd} // remove a friend from the list
                                    >
                                      <i className="fas fa-add"></i>
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {elemNamesMobileNumbers.length >= 2 ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      type="button"
                                      disabled={
                                        elemNamesMobileNumbers.length === 1
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setTimeout(() => {
                                          handleElemRemove(
                                            elemNamesMobileNumber
                                          );
                                        }, 0);
                                      }} // remove a friend from the list
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </>
                        )
                      )}
                    {mobileMessage && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger p-2 rounded-0 border-0"
                          }
                          role="alert"
                        >
                          {mobileMessage}
                        </div>
                      </div>
                    )}
                    {!!elemNamesEmails &&
                      elemNamesEmails.length > 0 &&
                      elemNamesEmails.map((elemNamesEmail, index) => (
                        <>
                          <div className="row" key={index}>
                            <div className="col-md-6 mb-2 mb-md-4">
                              <div className="form-floating">
                                <input
                                  className="form-control"
                                  id="inputEmail"
                                  name={`email${index + 1}`}
                                  value={elemNamesEmail.userGivenEmailAddress}
                                  type="text"
                                  placeholder={`Email address ${index + 1}`}
                                  onChange={(e: any) =>
                                    handleFormChangeEmail(index, e)
                                  }
                                />
                                <label htmlFor="inputEmail">
                                  {t("businessUpdatePage.lblEmailAddress")}{" "}
                                  {index + 1}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3 mb-2 mb-md-4">
                              <Field
                                className="form-control py-3"
                                value={
                                  couter
                                    ? emailLastLabel[index]
                                    : elemNamesEmail.emailAddressLabel
                                }
                                as="select"
                                name="color"
                                onChange={(e: any) => {
                                  handleemailclick(index, e);
                                }}
                              >
                                {!!emailLabel &&
                                  emailLabel.length > 0 &&
                                  emailLabel.map((label: any, index: any) => (
                                    <>
                                      {label?.defaultLabels?.count == 0 ? (
                                        " "
                                      ) : (
                                        <optgroup label="Default labels">
                                          {label?.defaultLabels?.rows.map(
                                            (
                                              defaultLabel: any,
                                              defaultIndex: any
                                            ) => {
                                              return (
                                                <option
                                                  value={defaultLabel.labelText}
                                                  key={defaultIndex}
                                                >
                                                  {defaultLabel.labelText}{" "}
                                                </option>
                                              );
                                            }
                                          )}
                                        </optgroup>
                                      )}
                                      {label?.customLabels?.count == 0 ? (
                                        " "
                                      ) : (
                                        <optgroup label="Custom labels">
                                          {label?.customLabels?.rows.map(
                                            (
                                              customLabel: any,
                                              customIndex: any
                                            ) => {
                                              return (
                                                <option
                                                  value={customLabel.labelText}
                                                  key={customIndex}
                                                >
                                                  {customLabel.labelText}{" "}
                                                </option>
                                              );
                                            }
                                          )}
                                        </optgroup>
                                      )}
                                    </>
                                  ))}
                                <option value="Add custom Label">
                                  {t("businessUpdatePage.addCustomLabel")}
                                </option>
                              </Field>
                            </div>
                            <div className="col-md-3 mb-4 d-flex align-items-center gap-2">
                              {index + 1 === elemNamesEmails.length ? (
                                <>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    type="button"
                                    disabled={elemNamesEmails.length >= 10}
                                    onClick={handleElemAddEmail} // remove a friend from the list
                                  >
                                    <i className="fas fa-add"></i>
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}

                              {elemNamesEmails.length >= 2 ? (
                                <>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    type="button"
                                    disabled={elemNamesEmails.length === 1}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setTimeout(() => {
                                        handleElemRemoveEmail(elemNamesEmail);
                                      }, 0);
                                    }} // remove a friend from the list
                                  >
                                    <i className="fas fa-minus"></i>
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    {emailmessage && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger p-2 rounded-0 border-0"
                          }
                          role="alert"
                        >
                          {emailmessage}
                        </div>
                      </div>
                    )}

                    {!!elemNamesWebsites &&
                      elemNamesWebsites.length > 0 &&
                      elemNamesWebsites.map((elemNamesWebsite, index) => (
                        <>
                          <div className="row" key={index}>
                            <div className="col-md-6 mb-2 mb-md-4">
                              <div className="form-floating">
                                <input
                                  className="form-control"
                                  id="inputWebsite"
                                  name="website[]"
                                  value={elemNamesWebsite.websiteAddress}
                                  type="text"
                                  placeholder={`Website ${index + 1}`}
                                  onChange={(e: any) => {
                                    const value = e.target.value;
                                    if (value === '' || value.startsWith('https://')) {
                                      handleFormChangeWebsites(index, e);
                                    } else {
                                      const withoutHostValue = value.replace('https:/', '');
                                      const prefixedValue = `https://${withoutHostValue}`;
                                      handleFormChangeWebsites(index, {
                                        ...e,
                                        target: { ...e.target, value: prefixedValue },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="inputWebsite">
                                  {t("businessUpdatePage.lblWebsite")}{" "}
                                  {index + 1}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3 mb-2 mt-md-0">
                              <Field
                                className="form-control py-3"
                                value={
                                  couter
                                    ? websiteLastLabel[index]
                                    : elemNamesWebsite.websiteLabel
                                }
                                as="select"
                                name="color"
                                onChange={(e: any) => {
                                  handleWebsiteClick(index, e);
                                }}
                              >
                                <option value="">
                                  {t("businessRegisterPage.selectLabel")}
                                </option>
                                <>
                                  {!!websiteLabel &&
                                    websiteLabel.length > 0 &&
                                    websiteLabel.map(
                                      (label: any, index: any) => (
                                        <>
                                          {label?.defaultLabels?.count == 0 ? (
                                            " "
                                          ) : (
                                            <optgroup
                                              label="Default labels"
                                              key={index}
                                            >
                                              {label?.defaultLabels?.rows.map(
                                                (
                                                  defaultLabel: any,
                                                  defaultIndex: any
                                                ) => {
                                                  return (
                                                    <option
                                                      value={
                                                        defaultLabel.labelText
                                                      }
                                                      key={defaultIndex}
                                                    >
                                                      {defaultLabel.labelText}{" "}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </optgroup>
                                          )}
                                          {label?.customLabels?.count == 0 ? (
                                            " "
                                          ) : (
                                            <optgroup label="Custom labels">
                                              {label?.customLabels?.rows.map(
                                                (
                                                  customLabel: any,
                                                  customIndex: any
                                                ) => {
                                                  return (
                                                    <option
                                                      value={
                                                        customLabel.labelText
                                                      }
                                                      key={customIndex}
                                                    >
                                                      {customLabel.labelText}{" "}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </optgroup>
                                          )}
                                        </>
                                      )
                                    )}
                                  <option value="Add custom Label">
                                    {t("businessUpdatePage.addCustomLabel")}
                                  </option>
                                </>
                              </Field>
                            </div>
                            <div className="col-md-3 mb-4 d-flex align-items-center gap-2">
                              {index + 1 === elemNamesWebsites.length ? (
                                <>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    type="button"
                                    disabled={elemNamesWebsites.length >= 10}
                                    onClick={handleElemAddWebsites} // remove a friend from the list
                                  >
                                    <i className="fas fa-add"></i>
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}
                              {elemNamesWebsites.length >= 2 ? (
                                <>
                                  {" "}
                                  <button
                                    className="btn btn-sm btn-primary"
                                    type="button"
                                    disabled={elemNamesWebsites.length === 1}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setTimeout(() => {
                                        handleElemRemoveWebsites(
                                          elemNamesWebsite
                                        );
                                      }, 0);
                                    }} // remove a friend from the list
                                  >
                                    <i className="fas fa-minus"></i>
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      ))}

                    {websiteMessage && (
                      <div className="w-50">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                          style={{ padding: 10, marginRight: 10 }}
                        >
                          {websiteMessage}
                        </div>
                      </div>
                    )}
                    {!!elemNamesAddress &&
                      elemNamesAddress.length > 0 &&
                      elemNamesAddress.map(
                        (elementNameAddresses: any, index) => (
                          <>
                            <div className="row" key={index}>
                              <div className="col-md-6 mb-3">
                                <div className="border p-3 rounded position-relative pt-4">
                                  <fieldset>
                                    <legend className="custom-legend">
                                      {t("businessUpdatePage.lblAddress")}{" "}
                                      {index + 1}
                                    </legend>

                                    <div className="row ">
                                      <div className="col-12">
                                        <div className="mb-4">
                                          <div className="form-floating">
                                            <Field
                                              className="form-control"
                                              id="Street"
                                              name="Street"
                                              type="text"
                                              placeholder={`Street ${index + 1
                                                }`}
                                              value={
                                                elementNameAddresses.street
                                              }
                                              onChange={(e: any) => {
                                                handleFormChangeStreet(
                                                  index,
                                                  e
                                                );
                                              }}
                                            />
                                            <label htmlFor="inputaddress">
                                              {t(
                                                "businessUpdatePage.lblStreet"
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-4">
                                          <div className="form-floating">
                                            <Field
                                              className="form-control"
                                              id="city"
                                              name="city"
                                              type="text"
                                              placeholder={`city ${index + 1}`}
                                              value={elementNameAddresses.city}
                                              onChange={(e: any) => {
                                                handleFormChangeCity(index, e);
                                              }}
                                            />
                                            <label htmlFor="inputaddress">
                                              {t("businessUpdatePage.lblCity")}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-4">
                                          <div className="form-floating">
                                            <Field
                                              className="form-control"
                                              id="StateProvince"
                                              name="StateProvince"
                                              type="text"
                                              placeholder={`StateProvince ${index + 1
                                                }`}
                                              value={
                                                elementNameAddresses.stateProvince
                                              }
                                              onChange={(e: any) => {
                                                handleFormChangeStsteProvince(
                                                  index,
                                                  e
                                                );
                                              }}
                                            />
                                            <label htmlFor="inputaddress">
                                              {t(
                                                "businessUpdatePage.lblStateProvince"
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-4">
                                          <div className="form-floating">
                                            <Field
                                              className="form-control"
                                              id="CountryRegion"
                                              name="CountryRegion"
                                              type="text"
                                              placeholder={`countryRegion ${index + 1
                                                }`}
                                              value={
                                                elementNameAddresses.countryRegion
                                              }
                                              onChange={(e: any) => {
                                                handleFormChangeCountry(
                                                  index,
                                                  e
                                                );
                                              }}
                                            />
                                            <label htmlFor="">
                                              {t(
                                                "businessUpdatePage.lblCountryRegion"
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="">
                                          <div className="form-floating">
                                            <Field
                                              className="form-control"
                                              id="PostalCode"
                                              name="PostalCode"
                                              type="text"
                                              placeholder={`PostalCode ${index + 1
                                                }`}
                                              value={
                                                elementNameAddresses.postalCode
                                              }
                                              onChange={(e: any) => {
                                                handleFormChangePostalCode(
                                                  index,
                                                  e
                                                );
                                              }}
                                            />
                                            <label htmlFor="inputaddress">
                                              {t(
                                                "businessUpdatePage.lblPostalCode"
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                </div>
                              </div>
                              <div className="col-md-3 mb-2 mt-md-4">
                                <Field
                                  className="form-control py-3"
                                  value={
                                    couter
                                      ? addressLastLabel[index]
                                      : elementNameAddresses.label
                                  }
                                  as="select"
                                  name="color"
                                  onChange={(e: any) => {
                                    handleAddressClick(index, e);
                                  }}
                                >
                                  <option value="">
                                    {t("businessRegisterPage.selectLabel")}
                                  </option>
                                  {!!addressLabel &&
                                    addressLabel.length > 0 &&
                                    addressLabel.map(
                                      (label: any, index: any) => (
                                        <>
                                          {label?.defaultLabels?.count == 0 ? (
                                            " "
                                          ) : (
                                            <optgroup label="Default labels">
                                              {label?.defaultLabels?.rows.map(
                                                (
                                                  defaultLabel: any,
                                                  defaultIndex: any
                                                ) => {
                                                  return (
                                                    <option
                                                      value={
                                                        defaultLabel.labelText
                                                      }
                                                      key={defaultIndex}
                                                    >
                                                      {defaultLabel.labelText}{" "}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </optgroup>
                                          )}
                                          {label?.customLabels?.count == 0 ? (
                                            " "
                                          ) : (
                                            <optgroup label="Custom labels">
                                              {label?.customLabels?.rows.map(
                                                (
                                                  customLabel: any,
                                                  customIndex: any
                                                ) => {
                                                  return (
                                                    <option
                                                      value={
                                                        customLabel.labelText
                                                      }
                                                      key={customIndex}
                                                    >
                                                      {customLabel.labelText}{" "}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </optgroup>
                                          )}
                                        </>
                                      )
                                    )}
                                  <option value="Add custom Label">
                                    {t("businessRegisterPage.addCustomLabel")}
                                  </option>
                                </Field>
                              </div>
                              <div className="col-md-3 mb-4 d-flex align-items-start gap-2">
                                {index + 1 === elemNamesAddress.length ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      type="button"
                                      disabled={elemNamesAddress.length >= 3}
                                      onClick={handleElemAddAddress}
                                    >
                                      <i className="fas fa-add"></i>
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {elemNamesAddress.length >= 2 ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      type="button"
                                      disabled={elemNamesAddress.length === 1}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setTimeout(() => {
                                          handleElemRemoveAddress(
                                            elementNameAddresses
                                          );
                                        }, 0);
                                      }}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </>
                        )
                      )}
                    <div className="form-floating notefield mb-4">
                      <Field
                        className="form-control h-100"
                        maxLength={512}
                        rows={4}
                        id="inputNote"
                        as="textarea"
                        value={noteData}
                        onChange={(e: any) => noteDataHandler(e)}
                        name="note"
                        placeholder="Note"
                      />
                      <label htmlFor="inputNote">
                        {t("businessUpdatePage.lblNote")}
                      </label>
                      <p className="mt-2">
                        {" "}
                        {t("businessUpdatePage.noteText1")} {noteData.length}{" "}
                        {t("businessUpdatePage.noteText2")} {totalChar}{" "}
                        {t("businessUpdatePage.noteText3")}{" "}
                      </p>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="lh-sm">
                        <label className=" mb-4" >
                          {t("businessUpdatePage.selectFile")}
                        </label>

                        <div className="d-inline  mousePointer ">
                          <ImageEditor
                            img={brandPic}
                            getImage={getImageHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-4 d-none">
                      <Field
                        className="form-control"
                        id="inputQrCodeText"
                        type="text"
                        name="qrCodeText"
                        placeholder="Please add here some text"
                      />
                      <label htmlFor="inputQrCodeText">
                        {t("businessUpdatePage.lbllblQrCode")}
                      </label>
                      <ErrorMessage
                        name="qrCodeText"
                        component="div"
                        className="text-danger txt-error"
                      />
                    </div>

                    <div className="d-flex align-items-center justify-content-between justify-content-md-start gap-3">
                      <Link className="btn btn-light" to="/dashboard">
                        {t("businessUpdatePage.btnClickBack")}
                      </Link>
                      <button
                        className="btn btn-primary btn-block me-1"
                         onClick={submitForm}
                      >
                        {t("businessUpdatePage.btnClickSave")}
                      </button>
                    </div>
                  </>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </main>

      <div
        className="modal fade"
        id="custom-field-mobile"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="password-join-meetingLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-2 px-md-4">
              <h5 className="mb-0">{t("businessUpdatePage.addCustomLabel")}</h5>
              <button
                type="button"
                className="btn-close text-muted"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body px-2 px-md-4">
              <Formik
                initialValues={initialValues}
                onSubmit={handleaddmobilenumberlabel}
              >
                <Form>
                  <div>
                    <Field
                      className="form-control"
                      id="name"
                      name="labelText"
                      type="text"
                      placeholder="Enter custom Label"
                    />{" "}
                    <ErrorMessage
                      name="labelText"
                      component="div"
                      className="text-danger txt-error mt-2"
                    />
                    {labelMessage && (
                      <div className="form-group mt-2">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger py-2 border-0"
                          }
                          role="alert"
                        >
                          {labelMessage}
                        </div>
                      </div>
                    )}
                    <div className="text-end">
                      <button type="submit" className="btn mt-2 btn-primary">
                        {t("businessUpdatePage.btnClickAdd")}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="custom-field-email"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="password-join-meetingLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-2 px-md-4">
              <h5 className="mb-0">{t("businessUpdatePage.addCustomLabel")}</h5>
              <button
                type="button"
                className="btn-close text-muted"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body px-2 px-md-4">
              <Formik
                initialValues={initialValues}
                onSubmit={handladdemaillabel}
              >
                <Form>
                  <div>
                    <Field
                      className="form-control"
                      id="name"
                      name="labelText"
                      type="text"
                      placeholder="Enter custom Label"
                    />

                    <ErrorMessage
                      name="labelText"
                      component="div"
                      className="text-danger txt-error mt-2"
                    />
                    {labelMessage && (
                      <div className="form-group mt-2">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger py-2 border-0"
                          }
                          role="alert"
                        >
                          {labelMessage}
                        </div>
                      </div>
                    )}
                    <div className="text-end">
                      <button type="submit" className="btn mt-2 btn-primary">
                        {t("businessUpdatePage.btnClickAdd")}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="custom-field-address"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="password-join-meetingLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-2 px-md-4">
              <h5 className="mb-0">
                {t("businessRegisterPage.addCustomLabel")}
              </h5>
              <button
                type="button"
                className="btn-close text-muted f-14"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body px-2 px-md-4">
              <Formik
                initialValues={initialValues}
                onSubmit={handleaddAddresslabel}
              >
                <Form id="myform">
                  <div>
                    <Field
                      className="form-control mb-2"
                      id="addressname"
                      name="labelText"
                      type="text"
                      placeholder="Enter custom Label"
                    />
                    <ErrorMessage
                      name="labelTexts"
                      component="div"
                      className="text-danger txt-error mt-2"
                    />
                    {labelMessage && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger py-2 border-0"
                          }
                          role="alert"
                        >
                          {labelMessage}
                        </div>
                      </div>
                    )}
                    <div className="text-end">
                      <button type="submit" className="btn btn-primary">
                        {t("businessRegisterPage.btnClickAdd")}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="custom-field-website"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="password-join-meetingLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-2 px-md-4">
              <h5 className="mb-0">
                {t("businessRegisterPage.addCustomLabel")}
              </h5>
              <button
                type="button"
                className="btn-close text-muted f-14"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body px-2 px-md-4">
              <Formik
                initialValues={initialValues}
                onSubmit={handleaddWebsitelabel}
              >
                <Form id="myform">
                  <div>
                    <Field
                      className="form-control mb-2"
                      id="addressname"
                      name="labelText"
                      type="text"
                      placeholder="Enter custom Label"
                    />
                    <ErrorMessage
                      name="labelText"
                      component="div"
                      className="text-danger txt-error mt-2"
                    />
                    {labelMessage && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger py-2 border-0"
                          }
                          role="alert"
                        >
                          {labelMessage}
                        </div>
                      </div>
                    )}
                    <div className="text-end">
                      <button type="submit" className="btn btn-primary">
                        {t("businessRegisterPage.btnClickAdd")}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessUpdatePage;
