/// reducers response ///

export const PENDING = "pending";
export const FULFILLED = "fulfilled";
export const REJECTED = "rejected";

/// dispatch actions ///
export const LOGIN = "login";
export const CHANGEPASSWORD = "changePassword";
export const BUSINESSLIST = "businessList";
export const EMAIL_VERIFY = "email_verify"
