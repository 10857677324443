
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getCurrentUser, resetPassword } from "../services/auth.service";
import { IUserResetPassword } from "../types/user.type";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import YupPassword from 'yup-password';
import { response } from "express";
YupPassword(Yup);

interface ResetPasswordPageProps {
    title: string
}

const initialValues: IUserResetPassword = {
    userRef: "",
    newPassword: "",
    password: ""
}

const ResetPasswordPage: FC<ResetPasswordPageProps> = ({ title }) => {
    const { t } = useTranslation("translate");
    const [message, setMessage] = useState<string>("");
    const [successful, setSuccessful] = useState<boolean>(false);
    let navigate = useNavigate();

    const user = getCurrentUser();
    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required('This field is required!')
            .minLowercase(1, 'password must contain at least 1 lower case letter')
            .minUppercase(1, 'password must contain at least 1 upper case letter')
            .minNumbers(1, 'password must contain at least 1 number')
            .minSymbols(1, 'password must contain at least 1 special character')
            .test(
                "len",
                ('The password must be between 6 to 40 characters.'),
                (val: any) =>
                    val &&
                    val.toString().length >= 6 &&
                    val.toString().length <= 40
            ),
        password: Yup.string()
            .required('This field is required!')
            .when("newPassword", {
                is: (val: string | any[]) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("newPassword")],
                    "Both password need to be the same"
                )
            })
    });

    var path = window.location.pathname;
    let parts = path.split("/");
    const decodeTime: any = jwt_decode(parts[2])
    const expireTime = decodeTime.iat
    const userId = decodeTime.user.userId


    const handleResetPassword = (formValue: { userRef: any, password: string }) => {
        let { password } = formValue;

        resetPassword(userId, password, expireTime).then(
            (response) => {
                setSuccessful(true);
                setMessage("Password Reset succesfully");
                toast.success("Password Changed Successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                setSuccessful(true)
                navigate("/login");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        (error.response.data.error.message)) ||
                    error.message ||
                    error.toString();
                setSuccessful(false);
                setMessage(resMessage)
            }
        )
    }

    useEffect(() => {
        const decoded: any = jwt_decode(parts[2]);
        const expTime = decoded.exp
        if (expTime < (Date.now() / 1000)) {
            navigate("/forgot-password")
            toast.error('Your Link has been expire please reset password again')
        }
        if (user) {
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <main>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="card shadow-lg border-0 rounded-lg mt-5">
                                <div className="card-header"><h3 className="text-center font-weight-light my-4">Reset Password </h3></div>
                                <div className="card-body">
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={handleResetPassword}
                                        validationSchema={validationSchema}
                                    >
                                        <div className="card-body  d-flex row justify-content-center">
                                            <Form>
                                                <div className='password-toggle form-floating mb-3  '>
                                                    <Field name="newPassword" type="password" className="form-control mt-3" placeholder="Please type New Password" />
                                                    <label>{t("changePasswordPage.lblNewPassword")}</label>
                                                    <ErrorMessage name="newPassword" component="div" className="text-danger txt-error" />
                                                </div>
                                                <div className='password-toggle form-floating mb-3  '>
                                                    <Field name="password" type="password" className="form-control mt-3" placeholder="Please Confirm New Password" />
                                                    <label>{t("changePasswordPage.lblConfirmNewPassword")}</label>
                                                    <ErrorMessage name="password" component="div" className="text-danger txt-error" />
                                                </div>
                                                <div>
                                                    {message && (
                                                        <div className="form-group">
                                                            <div
                                                                className={
                                                                    successful ? "alert alert-success" : "alert alert-danger p-2 border-0"
                                                                }
                                                                role="alert"
                                                            >
                                                                {message}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                    <button className="btn btn-light" type="button" onClick={() => navigate("/")}>{t("changePasswordPage.btnClickBack")}</button>
                                                    <button className="btn btn-primary" type="submit" >{t("changePasswordPage.btnClickSave")}</button>
                                                </div>
                                            </Form>
                                        </div>
                                    </Formik>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>



        </>
    )

}
export default ResetPasswordPage;
