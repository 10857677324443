import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { emailVerifyHandler, logoutHandler } from '../store/slicers/authSlicer';
import * as actions from "../store/actions/index";

const EmailVerification = () => {

    const { token } = useParams(); // Extract 'token' from URL params
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(logoutHandler());
        localStorage.removeItem("user")
        localStorage.removeItem("currentTenant")
        sessionStorage.clear()
    })

    const handleVerification = async () => {
        try {
            setIsLoading(true)
            dispatch(emailVerifyHandler(token)).then((res: any) => {
                if (res.type === `${actions.EMAIL_VERIFY}/${actions.REJECTED}`) {
                    toast.error(res?.payload?.message ? res?.payload?.message : "Network error", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    });
                    setIsLoading(false)
                    navigate('/')
                    return;
                }
                toast.success("Email is verified!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                });
                navigate('/')

            })
            setIsLoading(false)
        } catch (error: any) {
            console.error('Error verifying email:', error);
            setIsLoading(false)
            return;
        }
    };
    return (
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-lg-5">
                    <div className="card shadow border-0 mb-5">
                        <div className="card-body p-4">
                            <div className="text-center mb-4">
                                <img
                                    src="/verify.jpg" 
                                    alt="Email icon"
                                    className="img-fluid"
                                    style={{ width: '100px', height: '100px' }}
                                />
                            </div>
                            <h2 className="text-center mb-3">Verify your email</h2>
                            <p className="text-center mb-4">
                               You're almost there!
                            </p>
                            <div className='d-flex justify-content-center'>
                                <button
                                    onClick={handleVerification}
                                    className="btn btn-primary btn-block"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Verifying...' : 'Click here'}
                                </button>
                            </div>
                            <p className="text-center mt-3">
                                Just click on the above button to verify your email!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;
