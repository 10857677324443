import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

interface Props {
  currentPage: number;
  startItem: any;
  totalPage: any;
  rowsPerPageOptions: any;
  endItem: any;
  rowsPerPage: any;
  totalRecord: any;
  filteredDataLength: number;
  handleNextPage: (page: number) => void;
  handlePrevPage: (page: number) => void;
  handlePageClick: (page: number) => void;
}

const PaginationBox: React.FC<Props> = ({
  currentPage,
  totalPage,
  rowsPerPage,
  startItem,
  endItem,
  totalRecord,
  handlePrevPage,
  handleNextPage,
  handlePageClick,
}) => {
  const [loopStart, setLoopStart] = useState(0);
  const [loopEnd, setLoopEnd] = useState(2);
  const pages: any = [];
  /// next page handler function
  function nextPageHandler() {
    if (totalPage != 2) {
      handleNextPage(currentPage + 1);
      let finalPage = currentPage + 2;
      if (finalPage < totalPage) {
        setLoopStart(loopStart + 1);
        setLoopEnd(loopEnd + 1);
      }
    } else {
      handleNextPage(currentPage + 1);
      setLoopStart(0);
      setLoopEnd(1);
    }
  }
  //// previous page handler function
  function previousPageHandler() {
    if (totalPage != 2) {
      handlePrevPage(currentPage - 1);
      let page = currentPage - 2;
      if (page > 1) {
        setLoopStart(loopStart - 1);
        setLoopEnd(loopEnd - 1);
      }
    } else {
      handlePrevPage(currentPage - 1);
      setLoopStart(0);
      setLoopEnd(1);
    }
  }
  /// function is to move page to last page
  function lastPageHandler() {
    if (totalPage != 2) {
      handleNextPage(totalPage);
      setLoopEnd(totalPage - 1);
      setLoopStart(totalPage - 3);
    } else {
      handleNextPage(totalPage);
      setLoopStart(0);
      setLoopEnd(1);
    }
  }
  /// function to move page to first page
  function firstPageHandler() {
    if (totalPage != 2) {
      handlePrevPage(1);
      setLoopStart(0);
      setLoopEnd(2);
    } else {
      handlePrevPage(1);
      setLoopStart(0);
      setLoopEnd(1);
    }
  }
  function pageNumberHanler(page: any) {
    if (page > currentPage) {
      if (totalPage != 2) {
        if (page <= totalPage) {
          handlePageClick(page);
          setLoopStart(totalPage - 3);
          setLoopEnd(totalPage - 1);
          return;
        }
        handlePageClick(page);
        setLoopStart(loopStart + 1);
        setLoopEnd(loopEnd + 1);
      } else {
        setLoopStart(0);
        setLoopEnd(1);
        handlePageClick(page);
      }
    } else {
      if (totalPage != 2) {
        if (page > 2) {
          handlePageClick(page);
          setLoopStart(-1);
          setLoopEnd(-1);
        }
        handlePageClick(page);
        setLoopStart(0);
        setLoopEnd(2);
      } else {
        setLoopStart(0);
        setLoopEnd(1);
        handlePageClick(page);
      }
    }
  }
  /// to change loop start from if total pages changed

  useEffect(() => {
    if (totalPage === 1) {
      setLoopStart(0);
      setLoopEnd(0);
      return;
    } else if (totalPage === 2) {
      setLoopStart(0);
      setLoopEnd(1);
    } else if (totalPage == 0) {
      setLoopStart(0)
      setLoopEnd(0)
    } else {
      setLoopStart(0);
      setLoopEnd(2);
    }
  }, [totalPage]);

  //// pushing avlb pages to array for looping
  for (let i = loopStart; i <= loopEnd; i++) {
    pages.push(i);
  }
  return (
    <>
      <div className="d-flex gap-2">
        {pages.length > 0 && (
          <>
            {/* {t('pagination.totalRecored')} {totalRecord } */}
            <span>{"Showing"} </span>
            <span>{startItem}</span>
            <span>{"to"}</span>
            <span>{endItem}</span>
            <span>{"of"}</span>
            <span>{totalRecord}</span>
            <span>{"records"}</span>
          </>
        )}
      </div>

      <div className="custom-pagination rounded-3 overflow-hidden d-flex align-items-center">
        <button
          className="btn btn-primary"
          onClick={firstPageHandler}
          disabled={currentPage === 1}
        >
          <i className="fa-solid fa-angles-left"></i>
        </button>
        <button
          className="btn btn-primary"
          onClick={previousPageHandler}
          disabled={currentPage === 1}
        >
          <i className="fa-solid fa-angle-left"></i>
        </button>

        <ul className="d-flex  list-unstyled mb-0">
          {!!pages &&
            pages.length > 0 &&
            pages.map((page: any, index: any) => (
              <li
                className={
                  currentPage === page
                    ? "active-sibling"
                    : `${currentPage === page ? "active" : ""}`
                }
                key={index}
              >
                <button
                  disabled={currentPage === page + 1}
                  className={`rounded-0 btn btn-primary
                    ${currentPage === page + 1
                      ? "active"
                      : ""}
                  `}
                  key={index}
                  id={page}
                  onClick={() => pageNumberHanler(page + 1)}
                >
                  {page + 1}
                </button>
              </li>
            ))}
        </ul>

        <button
          className="btn btn-primary"
          onClick={nextPageHandler}
          disabled={currentPage === totalPage || totalPage === 0}
        >
          <i className="fa-solid fa-angle-right"></i>
        </button>

        <button
          className="btn btn-primary"
          onClick={lastPageHandler}
          disabled={currentPage === totalPage || totalPage === 0}
        >
          <i className="fa-solid fa-angles-right"></i>
        </button>
      </div>
    </>
  );
};

PaginationBox.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPage: PropTypes.number,
  totalRecord: PropTypes.number,
  startItem: PropTypes.number,
  endItem: PropTypes.number,
  handlePrevPage: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handlePageClick: PropTypes.func.isRequired,
};
export default PaginationBox;
