import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { commonData } from "../../common/interface";
import { ThunkDispatch as ReduxThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';


const initialState: commonData = {
   showLoader: false,
  };

  const loaderSlice = createSlice({
    name: "loaderSlice",
    initialState,
    reducers: {
        enableLoader:(state, { payload }: PayloadAction<boolean>) => {
            state.showLoader = payload;
        },
    },
  })

  export const hideLoader = () => (dispatch:any) => {
    dispatch(enableLoader(false));
  };
  
  export const { enableLoader } = loaderSlice.actions
export default loaderSlice.reducer;

export type ThunkDispatch = ReduxThunkDispatch<any, any, AnyAction>;
