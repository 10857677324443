import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { TypedUseSelectorHook } from "react-redux";
import authSliceReducer from "./slicers/authSlicer";
import businessSliceReducer from "./slicers/businessSlice";
import loaderSliceReducer from "./slicers/commonSlice";

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    business: businessSliceReducer,
    common: loaderSliceReducer,
  },
});
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
export default store;