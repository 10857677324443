import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginData } from "../../common/interface";
import { login, changePassword, verifyEmail } from "../../services/auth.service";
import * as actions from '../actions/index';

const initialState: loginData = {
  userData: {},
  loading: false,
  isAuth: false,
  changePassword: false,
  isLoading: false,
  error: null,
  message: null,
};


export const loginHandler: any = createAsyncThunk(actions.LOGIN, (data: any, { rejectWithValue }) =>
  login(data).catch((error) => error && rejectWithValue(error))
);

export const emailVerifyHandler: any = createAsyncThunk(actions.EMAIL_VERIFY, (data: any, { rejectWithValue }) =>
  verifyEmail(data).catch((error:any) => error && rejectWithValue(error.response.data))
);

export const changePasswordHandler: any = createAsyncThunk(actions.CHANGEPASSWORD, (data: any, rejectWithValue: any) =>
  changePassword(data, rejectWithValue)
);

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    logoutHandler: (state) => {
      state.isAuth = false
      state.userData = {}
    },
    refreshHandler: (state, { payload }: PayloadAction<any>) => {
      state.userData = payload
      state.isAuth = true
    },
  },
  extraReducers: {
    [loginHandler.pending]: (state: any) => {
      state.loading = true;
    },
    [loginHandler.fulfilled]: (state: any, action) => {
      state.userData = action.payload.userData;
      state.loading = false;
      state.isAuth = true;
    },
    [loginHandler.rejected]: (state: any, action) => {
      state.isLoading = false;
      state.error = action.payload
    },
    [changePasswordHandler.pending]: (state: any) => {
      state.loading = false;
    },
    [changePasswordHandler.fulfilled]: (state: any) => {
      state.loading = false;
      state.changePassword = true;
    },
    [changePasswordHandler.rejected]: (state: any) => {
      state.isLoading = false;
    },
    [changePasswordHandler.rejected]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [changePasswordHandler.rejected]:(state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
    },
    [changePasswordHandler.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    }
  },
});
export const { logoutHandler, refreshHandler } = authSlice.actions


export default authSlice.reducer;