import React, { FC, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import { DatatableWrapper, TableColumnType, TableBody, TableHeader, PaginationOptions, } from 'react-bs-datatable';
import { Table } from 'react-bootstrap';
import { IUserAPIKey } from "../types/user.type";
import * as Yup from "yup";
import PaginationBox from "../components/pagination";
import { createKeyName, deletClientData, getClientIdData } from "../services/register.service";
import { toast } from "react-toastify";
import DeleteDialogBox from "../common/DeleteDialogBox";
import { useTranslation } from 'react-i18next';

interface ApiGeneratorPageProps {
    title: string;
}

const ApiGenerator: FC<ApiGeneratorPageProps> = ({ title }) => {
    const { t } = useTranslation("translate");

    const initialValuesApiKeyGenerator: IUserAPIKey = {
        keyName: "",
    }
    const [apiListData, setApiListData] = useState<any[]>([]);
    const [pageSize, setpageSize] = useState(1); // Page number 
    const [perPage, setperPage] = useState(10); // per page Limit
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [totalRecord, setTotalRecord] = useState<any>(); // Total Record
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filterValue, setFilterValue] = useState('');
    const pageLimitOptions = [10, 15, 20];
    const [endItem, setendItem] = useState(0);
    const [startItem, setStartItem] = useState(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [totalPage, setTotalPage] = useState<any>();
    const [showDelete, setShowDelete] = useState(false);
    const [clientDeleteID, setClientDeleteID] = useState();
    const [isMasked, setIsMasked] = useState<boolean>(true)
    const validationSchema = Yup.object().shape({
        keyName: Yup.string()
            .required('This field is required!'),
    })

    // const[dummy,setDummy]=useState<any>()
    function maskHandler(id: any) {
        let data: any = apiListData
        let index: number = data.findIndex((item: any) => item.clientSecret === id)
        data[index].isMasked = !data[index].isMasked
        setApiListData(data)
        // setDummy(data)
        setIsMasked(!isMasked)
        // setIsMasked(true)
    }
    let body = apiListData;
    type ArrayElementType = typeof body[number] & {
        button: any;
    };
    const header: TableColumnType<ArrayElementType>[] = [
        {
            title: "Key Name", prop: "keyName",
            cell: (row) => {
                return (
                    <>
                        <div>
                            <span className='me-2'>
                                {(row.keyName)}
                            </span>

                        </div>

                    </>
                )
            }
        },
        {
            title: "Client Id", prop: "clientId",
            cell: (row) => {
                return (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="col">
                                <span className='me-2'>
                                    {(row.clientId)}
                                </span>
                            </div>

                            {/* <i className="fa-solid fa-eye"></i> */}

                            <button onClick={() => {
                                navigator.clipboard.writeText(row.clientId);
                                toast.success("Client Id Copied!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 1000,
                                });
                            }
                            }
                                className="btn btn-primary-light d-flex align-items-center justify-content-center rounded-1 size-38">
                                <i className="fa-regular fa-clone"></i>
                            </button>
                        </div>

                    </>
                )
            }
        },
        {
            title: "Client Secret", prop: "clientSecret",
            cell: (row) => {
                return (
                    <>
                        <div className="d-flex align-items-center">
                            {row.isMasked ?
                                <span className='me-2'>
                                    **********************
                                </span> :
                                <span className='me-2'>
                                    {(row.clientSecret)}
                                </span>}

                            <button
                                onClick={() => maskHandler(row.clientSecret)}
                                // onClick={() => setIsMasked(!isMasked)}
                                className="btn btn-primary-light d-flex align-items-center justify-content-center rounded-1 size-38">
                                <i className="fa-solid fa-eye"></i>
                            </button>
                            <button onClick={() => {
                                navigator.clipboard.writeText(row.clientSecret); toast.success("Client Secret Copied!",
                                    {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 1000,
                                    });
                            }}
                                className="btn btn-primary-light d-flex align-items-center justify-content-center rounded-1 size-38">
                                <i className="fa-regular fa-clone"></i>
                            </button>
                        </div>

                    </>
                )
            }
        },
        {
            title: "Created Date", prop: "createdDate",
            cell: (row) => {
                return (
                    <>
                        <div>
                            <span className='me-2'>
                                {(row.createdAt)}
                            </span>

                        </div>

                    </>
                )
            }
        },
        {
            title: 'Action', prop: '',
            cell: (row) => {
                return (
                    <>
                        <div>
                            <button onClick={() => deleteConfirmHandler(row.clientRef)}
                                className="btn btn-primary-light p-0 size-38">
                                <i className="fa-solid fa-trash text-danger"></i>
                            </button>
                        </div>
                    </>
                );
            }
        }
    ];
    const handleCreate = (formValue: IUserAPIKey) => {
        const { keyName } = formValue
        createKeyName(keyName).then(
            (response) => {
                window.location.reload();
            },
            (error) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.response.data.errors[0].constraints.isNotEmpty ||
                    error.message ||
                    error.toString();
            }
        );
    }
    const getClientList = async (pageLimit: number, perPage: number, searchVal: string) => {
        const dataList = await getClientIdData(pageLimit, perPage, searchVal)
        let data: any = dataList.data.clients.rows
        let arrayData: any = []
        data?.map((item: any) => {
            arrayData.push({
                clientId: item.clientId,
                clientRef: item.clientRef,
                clientSecret: item.clientSecret,
                createdAt: item.createdAt,
                keyName: item.keyName,
                redirectUri: item.redirectUri,
                tenantId: item.tenantId,
                isMasked: true,
            })
        })
        setApiListData(arrayData);
        // setApiListData(dataList.data.clients.rows);
        let records = dataList.data.clients.count;
        setTotalRecord(records);
        let totalPageNo = dataList.data.clients.count / perPage;
        let roundNu = Math.ceil(totalPageNo);
        setTotalPage(roundNu);
    };
    const handlePrevPage = (e: number) => {
        setpageSize((e));
        setStartItem((startItem - perPage))
        totalrecordHandle()
    };

    const handleNextPage = (e: number) => {
        setpageSize((e));
        setStartItem(endItem + 1)
        totalrecordHandle()
    };
    const handlePageClick = (e: any) => {
        setpageSize(e);
        setStartItem(((e * perPage) + 1) - perPage)
        totalrecordHandle()
    }

    const totalrecordHandle = () => {
        var totalRecordTemp = ((startItem - 1) + perPage);
        if (totalRecordTemp > totalRecord) {
            totalRecordTemp = totalRecord;
        }
        if (totalRecord === 0) {
            setStartItem(0);
            }
        setendItem(totalRecordTemp)
    }
    useEffect(() => {
        totalrecordHandle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startItem, endItem, totalRecord, perPage]);
    useEffect(() => {
        setpageSize(1);
        setStartItem(1);
        setendItem(perPage);
    }, [perPage]);

    const deleteApiData = async (clientRef: any) => {
        let deleteClient = await deletClientData(clientRef)
        if (deleteClient.status === 200) {
            getClientList(pageSize, perPage, filterValue);
            toast.success("Client deleted sucessfully!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            if(totalRecord - perPage === 1){
                setpageSize(pageSize-1);
                setStartItem(1);
                if(0 == totalRecord) {
                    setStartItem(0);
                }
            }
        }
    }
    const deleteConfirmHandler = (businessRef: any) => {
        setShowDelete(true)
        setClientDeleteID(businessRef)
    }
    useEffect(() => {
        getClientList(pageSize, perPage, filterValue);
    }, [perPage, pageSize, filterValue]);

    useEffect(() => {
        totalrecordHandle()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startItem, endItem, totalRecord]);

    return (
        <>

            <main>
                <div className="container-fluid px-lg-4">
                    <h3 className="mt-3 mb-4">{t("dashboardPage.lbloAuthAPIKeys")}</h3>
                    <div className="row">
                        <h4 className="mt-4 mb-3">
                            {t("apiKeyPage.lblGeneratenewkey")}
                        </h4>
                        <div className="col-md-6 col-xl-4 mb-3">
                            <div className="card card-border">
                                <div className="card-body">
                                    <Formik
                                        initialValues={initialValuesApiKeyGenerator}
                                        onSubmit={handleCreate}
                                        validationSchema={validationSchema}
                                    // enableReinitialize={true}
                                    >
                                        <Form>
                                            <div className="d-flex align-items-center gap-3">

                                                <div className="form-floating flex-fill">
                                                    <Field className="form-control" id="inputKeyName" name="keyName" type="text" placeholder="key Name" />
                                                    <label htmlFor="inputKeyName">{t("apiKeyPage.lblKeyname")}</label>
                                                </div>
                                                <button className="btn btn-primary" type="submit" >{t("apiKeyPage.btnCreate")}</button>

                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>

                        <h4 className="mt-4 mb-3">
                            {t("apiKeyPage.lblLists")}
                        </h4>
                        <div className="col-12 mb-4">
                            <div className="card card-border">
                                <div className="card-body">
                                    <DatatableWrapper body={body} headers={header}>
                                        <div className='mb-2 d-flex justify-content-end'>
                                            <PaginationOptions
                                                controlledProps={{
                                                    filteredDataLength: perPage,
                                                    onRowsPerPageChange: setperPage,
                                                    rowsPerPageOptions: pageLimitOptions,
                                                    rowsPerPage: perPage,
                                                }}
                                            />
                                        </div>
                                        <Table responsive borderless striped>
                                            <TableHeader />
                                            <TableBody />
                                        </Table>
                                        <div className="pt-3 border-top d-flex justify-content-between align-items-center gap-3 flex-wrap">
                                            <PaginationBox
                                                filteredDataLength={perPage}
                                                startItem={startItem}
                                                totalPage={totalPage}
                                                endItem={endItem}
                                                currentPage={pageSize}
                                                handlePrevPage={handlePrevPage}
                                                handleNextPage={handleNextPage}
                                                handlePageClick={handlePageClick}
                                                rowsPerPageOptions={pageLimitOptions}
                                                rowsPerPage={perPage}
                                                totalRecord={totalRecord}
                                            />
                                        </div>

                                    </DatatableWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <DeleteDialogBox show={showDelete} clickOk={() => { deleteApiData(clientDeleteID); setShowDelete(false) }} clickCancel={() => { setShowDelete(false) }} btncancel={'Cancel'} btnyes={'Delete'} color={"btn-danger"} question={'Are you sure you want to delete this client info?'} />
        </>
    )
}
export default ApiGenerator;