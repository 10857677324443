import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL

export const getLogLists = async (pageSize: any, perPage: any, searchVal: any, status: any) => {
    let newHeader = {
        ...authHeader(),
    }
    let config = {
        headers: newHeader,
        params: {
            page: pageSize,
            perPage: perPage,
            day: searchVal,
            status: status
        },
    }
    return axios
        .get(API_URL + "logs", config)
        .then((response) => {
            if (response.data.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data.data))
            }
            return response.data;
        })
        .catch((error) => {
            console.log(error)
        })
};