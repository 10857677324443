import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as AuthService from "../services/auth.service";
import { toast } from 'react-toastify';
import axios from 'axios';
import { enableLoader } from '../store/slicers/commonSlice';
import { useDispatch } from 'react-redux';


interface authVerifyProps {
    title: string;
}

const AuthVerify: FC<authVerifyProps> = ({ title }) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const logOut = () => {
        setTimeout(() => {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
        }, 1500);

    };

    //For GET requests
    axios.interceptors.request.use(
        (req) => {
            dispatch(enableLoader(true))
            // Add configurations here
            return req;
        },
        (err) => {
            // if(err.response.status =)
            if (err.response.status === 401) {
                logOut()
            }
            else {
                return Promise.reject(err);
            }
        }
    );

    // For POST requests
    axios.interceptors.response.use(
        (res) => {
            dispatch(enableLoader(false));
            if (res.status === 201) {
            }
            return res;
        },
        (err) => {
            dispatch(enableLoader(false));
            if (err.response.status === 401) {
                logOut()
            }
            else {
                return Promise.reject(err);
            }
        }
    );

    const user = AuthService.getCurrentUser();
    const parseJwt = (token: any) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };


    useEffect(() => {
        if (user) {
            const decodedJwt = parseJwt(user.token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                logOut()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<></>)
}
export default AuthVerify;