
import React, { FC } from 'react';

interface DeleteProps {
    show: any;
    question: string;
    btncancel: string;
    btnyes: string;
    clickOk: any;
    clickCancel: any;
    color: any;
}

const DeleteDialogBox: FC<DeleteProps> = ({ show, question, btncancel, btnyes, clickOk, clickCancel, color }) => {

    return show ? (
        <div className='delete-confirm'>
            <div className='inner'>
                <p className='delete-confirm-title'>{question}</p>
                <div className='d-flex justify-content-end gap-3'>
                    <button className='btn btn-sm btn-secondary' type='button' onClick={() => { clickCancel() }}>{btncancel}</button>
                    <button className={`btn btn-sm ${color} text-white`} type='button' onClick={() => { clickOk() }}>{btnyes}</button>
                </div>
            </div>
        </div >
    ) : null;
}

export default DeleteDialogBox;