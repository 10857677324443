import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as AuthService from "../services/auth.service";
import { Link, useNavigate } from 'react-router-dom';

interface DefaultSideNavProps {
    title: string;
}
let org = "org"
// eslint-disable-next-line no-empty-pattern
const DefaultSideNav: FC<DefaultSideNavProps> = ({ }) => {
    const { t, i18n } = useTranslation('translate');
    let navigate = useNavigate();

    const handleLogout = () => {
        AuthService.logout();
        navigate("/");
        window.location.reload();
    }
    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <Link className="nav-link" to="/dashboard">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                {t("dashboardPage.lblDashboard")}
                            </Link>
                            <Link className="nav-link" to="/api-key-page">
                                <div className="sb-nav-link-icon"><i className="fas fa-unlock-keyhole"></i></div>
                                {t("dashboardPage.lbloAuthAPIKeys")}
                            </Link>
                            <Link className="nav-link" to="/logList">
                                <div className="sb-nav-link-icon"><i className="fa-solid fa-message"></i></div>
                                {t("dashboardPage.lablDeliveryLog")}

                            </Link>
                            <Link className="nav-link" to="/setting-page">
                                <div className="sb-nav-link-icon"><i className="fa-sharp fa-solid fa-gear"></i></div>
                                {t("dashboardPage.lablSettings")}
                            </Link>
                            <Link className="nav-link" to={`/qrcustomize-page/${org}`}>
                                <div className="sb-nav-link-icon"><i className="fa fa-qrcode"></i></div>
                                {t("dashboardPage.lablQrCustomize")}
                            </Link>
                            <Link className="nav-link" to="/change-password">
                                <div className="sb-nav-link-icon"><i className="fa-solid fa-lock"></i></div>
                                {t("dashboardPage.lablChangePassword")}
                            </Link>
                            {/* <a className="nav-link" href="/logList" onClick={handleLogout}>
                                <div className="sb-nav-link-icon"><i className="fa-solid fa-right-from-bracket"></i></div>
                                {t("dashboardPage.lablLogOut")}
                            </a> */}
                        </div>
                    </div>
                    {/* <div className="sb-sidenav-footer">
                        <div className="small">Logged in as:</div>
                        Start Bootstrap
                    </div> */}
                </nav>
            </div>
        </>
    )

}

export default DefaultSideNav;