/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */

import React, { FC, useEffect, useState } from 'react';
import {
    DatatableWrapper,
    TableColumnType,
    TableBody,
    TableHeader,
    PaginationOptions
} from 'react-bs-datatable';
import { Table } from 'react-bootstrap';
import PaginationBox from '../components/pagination';
import { useLocation, useParams } from 'react-router-dom';
import { getLogLists } from '../services/logList.service';
import { useTranslation } from 'react-i18next';

interface DeliverLogPageProps {
    title: string;
}

const DeliverLogListPage: FC<DeliverLogPageProps> = ({ }) => {
    const { t } = useTranslation("translate");
    const [deliverLogList, setDeliverLogListData] = useState<any[]>([]);
    const [pageSize, setpageSize] = useState(1); // Page number 
    const [perPage, setperPage] = useState(10); // per page Limit
    const [totalRecord, setTotalRecord] = useState<any>(); // Total Record
    const [filterValue, setFilterValue] = useState<any>('');
    const [status, setStatusValue] = useState<any>('');
    const pageLimitOptions = [10, 15, 20];
    const [endItem, setendItem] = useState(0);
    const [startItem, setStartItem] = useState(1);
    const [totalPage, setTotalPage] = useState<any>();
    const location = useLocation();
    let params = useParams();

    let body = deliverLogList;
    type ArrayElementType = typeof body[number] & {
        button: any;
    };
    const header: TableColumnType<ArrayElementType>[] = [


        {
            title: "Type", prop: "type",
            cell: (row) => {
                return (
                    <>
                        <div>
                            <span className='me-2'>
                                {(row.type)}
                            </span>

                        </div>

                    </>
                )
            }
        },

        {
            title: " MobileNumber", prop: "toMobileNumber",
            cell: (row) => {
                return (
                    <>
                        <div>
                            <span className='me-2'>
                                {(row.toMobileNumber)}
                            </span>

                        </div>

                    </>
                )
            }
        },
        {
            title: "Body", prop: "body",
            cell: (row) => {
                return (
                    <>
                        <div>
                            <span className='me-2'>
                                {(row.body)}
                            </span>

                        </div>

                    </>
                )
            }
        },
        {
            title: "Status", prop: "status",
            cell: (row) => {
                return (
                    <>
                        <div>
                            <span className='me-2'>
                                {(row.status)}
                            </span>

                        </div>

                    </>
                )
            }
        },
        {
            title: "Created At", prop: "createdAt",
            cell: (row) => {
                return (
                    <>
                        <div>
                            <span className='me-2'>
                                {(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(row.createdAt)))}
                            </span>

                        </div>

                    </>
                )
            }
        }
    ];


    const getDeliverLog = async (pageLimit: number, perPage: number, searchVal: string, status: string) => {

        const deliverLogDataList = await getLogLists(pageLimit, perPage, searchVal, status)
        setDeliverLogListData(deliverLogDataList.data.logs.rows);
        let records = deliverLogDataList.data.logs.count;
        setTotalRecord(records);
        let totalPageNo = deliverLogDataList.data.logs.count / perPage;
        let roundNu = Math.ceil(totalPageNo);
        setTotalPage(roundNu);
    };

    const handlePrevPage = (e: number) => {
        setpageSize((e));
        setStartItem((startItem - perPage))
        totalrecordHandle()
    };

    const handleNextPage = (e: number) => {
        setpageSize((e));
        setStartItem(endItem + 1)
        totalrecordHandle()
    };
    const handlePageClick = (e: any) => {
        setpageSize(e);
        setStartItem(((e * perPage) + 1) - perPage)
        totalrecordHandle()
    }

    useEffect(() => {


        const searchParams = new URLSearchParams(location.search);
        const searchStatus = searchParams.get('status') ?? '';
        const searchDay = searchParams.get('day') ?? '';

        setStatusValue(searchStatus);
        setFilterValue(searchDay);
        getDeliverLog(pageSize, perPage, searchDay, searchStatus);

    }, [perPage, pageSize, filterValue, status, location.search]);

    const totalrecordHandle = () => {
        var totalRecordTemp = ((startItem - 1) + perPage);
        if (totalRecordTemp > totalRecord) {
            totalRecordTemp = totalRecord;
        }
        if (totalRecord === 0) {
            setStartItem(0);
            }
        setendItem(totalRecordTemp)
    }
    useEffect(() => {
        setpageSize(1);
        setStartItem(1);
        setendItem(perPage);
    }, [perPage]);

    useEffect(() => {
        totalrecordHandle()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startItem, endItem, totalRecord]);
    
    return (
        <>
            <main>
                <div className="container-fluid px-lg-4">
                    <h3 className="mt-3 mb-4">{t("dashboardPage.lablDeliveryLog")}</h3>
                    <h4 className="mt-5 mb-3">
                        {t("logListPage.lblLists")}
                    </h4>
                    <div className="card card-border mb-4">
                        <div className="card-body">
                            <DatatableWrapper body={body} headers={header}>
                                <div className='mb-2 d-flex justify-content-end'>
                                    <PaginationOptions
                                        controlledProps={{
                                            filteredDataLength: perPage,
                                            onRowsPerPageChange: setperPage,
                                            rowsPerPageOptions: pageLimitOptions,
                                            rowsPerPage: perPage,

                                        }}
                                    />
                                </div>
                                <Table responsive borderless striped>
                                    <TableHeader />
                                    <TableBody />
                                </Table>
                                <div className="pt-3 border-top d-flex justify-content-between align-items-center gap-3 flex-wrap">
                                    <PaginationBox
                                        filteredDataLength={perPage}
                                        startItem={startItem}
                                        totalPage={totalPage}
                                        endItem={endItem}
                                        currentPage={pageSize}
                                        handlePrevPage={handlePrevPage}
                                        handleNextPage={handleNextPage}
                                        handlePageClick={handlePageClick}
                                        rowsPerPageOptions={pageLimitOptions}
                                        rowsPerPage={perPage}
                                        totalRecord={totalRecord}
                                    />
                                </div>
                            </DatatableWrapper>

                        </div>
                    </div>
                </div>
            </main>

        </>
    )

}

export default DeliverLogListPage;