export default function authHeader() {
  const userStr = localStorage.getItem("user");
  const tenantID = localStorage.getItem("currentTenant");

  let user = null;

  if (userStr) user = JSON.parse(userStr);
  if (user && user.token) {
    

    // return {
    //   'Authorization': 'Bearer ' + user.token, 'Accept-Language': "en"
    // }; // for Spring Boot back-end
    return {
      "x-token": user.token,
      "tenant-id": tenantID,
    }; // for Node.js Express back-end
  } else {
    // return { Authorization: '', 'Accept-Language': "en" }; // for Spring Boot back-end
    return { "x-token": null, "tenant-id": 0 }; // for Node Express back-end
  }
}
    